import { errorToast, infoToast } from '@/helpers';
import { _isEmpty, _isNil, _isNotEmpty, _notNil } from '@/littledash';
import { ID } from '@/model/Common.model';
import {
  DeviceId,
  DeviceWorkerMessage,
  MappedDevice,
  PresetDeviceMapping,
  RegisteredDevice,
  TargetField,
} from '@/model/Device.model';
import InVivoError from '@/model/InVivoError.ts';
import type { Preset } from '@/model/Preset.model';
import { Study } from '@/model/Study.model';
import { selectTeam } from '@/reducers/team';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { notAborted } from '@/support/Hooks/fetch/useAbortController';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import { createContext, FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { stripReading } from './DevicesProvider.utils.ts';
import DeviceWorker from './DeviceWorker.ts?worker';
import { isAllowedOrigin } from '@/constants/utils.ts';

export interface DevicesProviderContextResponse {
  disabled: boolean;
  deviceTypes: RegisteredDevice[];
  mappedDevices: MappedDevice[];
  unmappedDevices: number;
  readings: Record<string, string>;
  activePreset: Preset | undefined;
  readyTargets: Record<string, string[]>;
  isTargetReady: (target: string | undefined, identifier: string) => boolean;
  registerTarget: (target: string, identifier: string) => void;
  deregisterTarget: (target: string, identifier: string) => void;
  onChangeDeviceTarget: (targetDevice: MappedDevice | undefined, targetFields: TargetField[]) => void;
  onChangeDeviceType: (mappedDevice: MappedDevice, newDeviceTypeId: string) => Promise<void>;
  nextReading: (targetId: string, complete: boolean) => void;
  getDevices: () => void;
  refreshDevices: (hard: boolean) => void;
  clearReading: (targetId: string) => void;
  setOnDeviceError?: (targetId: string, callback: () => void) => void;
}

export const DeviceContext = createContext<DevicesProviderContextResponse>({
  disabled: true,
  deviceTypes: [],
  mappedDevices: [],
  unmappedDevices: 0,
  readings: {},
  readyTargets: {},
  activePreset: undefined,
  isTargetReady: () => false,
  clearReading: () => {},
  getDevices: () => {},
  registerTarget: () => {},
  nextReading: () => {},
  deregisterTarget: () => {},
  onChangeDeviceTarget: () => {},
  onChangeDeviceType: () => Promise.resolve(),
  refreshDevices: () => {},
  setOnDeviceError: () => {},
});

const broadcastChannel = new BroadcastChannel('devices');

export const DevicesProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [readings, setReadings] = useState<Record<string, string>>({});
  const [mappedDevices, setMappedDevices] = useState<MappedDevice[]>([]);
  const [unmappedDevices, setUnmappedDevices] = useState<number>(0);
  const [deviceTypes, setDeviceTypes] = useState<RegisteredDevice[]>([]);
  const [disabled, setDisabled] = useState(true);
  const [setupComplete, setSetupComplete] = useState(false);
  const [readyTargets, setReadyTargets] = useState<Record<string, string[]>>({});
  const [workerDeviceTypes, setWorkerDeviceTypes] = useState<RegisteredDevice[]>([]);

  const { pathname } = useLocation();
  const path = matchPath(pathname, { path: '/studies/:studyId/' });
  const params = path?.params as { studyId: string };
  const studyIdParam = params?.studyId;
  const isLoggedIn = _notNil(window.localStorage.getItem('token'));
  const isValidStudyId = _notNil(studyIdParam) && studyIdParam !== 'new' && !studyIdParam.startsWith('sdy_');
  const team = useSelector(selectTeam);

  const activePresetRef = useRef<Preset>();
  const portsRef = useRef<SerialPort[]>([]);
  const onDeviceErrorRef = useRef(new Map<string, () => void>());
  const workerLookupRef = useRef(new Map<DeviceId, Worker>());

  const getPorts = (): SerialPort[] => {
    return portsRef.current;
  };

  const setPorts = (ports: SerialPort[]): void => {
    portsRef.current = ports;
  };

  const getOnDeviceError = (targetId: string): (() => void) => {
    if (!onDeviceErrorRef.current.has(targetId)) {
      onDeviceErrorRef.current.set(targetId, () => {});
    }

    return onDeviceErrorRef.current.get(targetId)!;
  };

  const setOnDeviceError = (targetId: string, callback: () => void) => {
    onDeviceErrorRef.current.set(targetId, callback);
  };

  const displayReading = (type: RegisteredDevice, value: string) => {
    infoToast(
      <div>
        <p className="normal">New reading from device</p>
        <p className="fw9">{type.title}</p>
        <br />
        <p className="normal">Reading</p>
        <p className="fw9">{value}</p>
      </div>
    );
  };

  const displayErrorReading = (targetId: string, type: RegisteredDevice) => {
    errorToast(
      <div>
        <p className="normal">Error reading from device</p>
        <p className="fw9">{type.title}</p>
        <br />
        <p className="normal">Please try again</p>
      </div>
    );

    getOnDeviceError(targetId)();
  };

  useEffect(() => {
    const updated = _isNil(navigator.serial) || !isLoggedIn || _isNil(team);

    if (updated !== disabled) {
      setDisabled(updated);
    }
  }, [isLoggedIn, team]);

  useEffect(() => {
    broadcastChannel.onmessage = (event) => {
      const { type, targetId, value } = event.data;
      if (!document.hidden) {
        addReading(type, targetId, value);
      }
    };
  }, []);

  useEffect(() => {
    if (_isNotEmpty(mappedDevices)) {
      const unmappedDevices = mappedDevices.filter((mappedDevice) => _isNil(mappedDevice.device_type_id)).length;
      setUnmappedDevices(unmappedDevices);

      // We are only interested in the device types that have been mapped to a port.
      const typeMappingDeviceTypes: RegisteredDevice[] = mappedDevices.reduce(
        (acc: RegisteredDevice[], mappedDevice: MappedDevice) => {
          const found = deviceTypes?.find(
            ({ id, usb_vendor_id, usb_product_id }) =>
              usb_product_id === mappedDevice.usb_product_id &&
              mappedDevice.usb_vendor_id === usb_vendor_id &&
              id === mappedDevice.device_type_id
          );

          if (_notNil(found)) {
            acc.push(found);
          }
          return acc;
        },
        []
      );

      setWorkerDeviceTypes(typeMappingDeviceTypes);
    }
  }, [mappedDevices]);

  useEffect(() => {
    if (!isValidStudyId && !disabled) {
      activePresetRef.current = undefined;
    }

    // Prevent study create page triggering calls
    if (isValidStudyId && !disabled) {
      loadStudy(studyIdParam)
        .then((study) => {
          if (_notNil(study)) {
            activePresetRef.current = study.settings;
          }

          return loadMappedDevices(getPorts(), deviceTypes, activePresetRef.current);
        })
        .catch((cause) => {
          ExceptionHandler.captureException(
            new InVivoError('Could not load mapped devices', {
              cause,
              slug: 'devices-provider',
              level: 'warning',
              context: { studyId: studyIdParam, ports: JSON.stringify(getPorts().map((p) => p.getInfo())) },
            })
          );
        });
    }
  }, [studyIdParam, disabled]);

  const { invoke } = useApiHook({
    endpoint: 'GET /api/v1/devices',
    invokeOnInit: false,
  });

  const initialise = async () => {
    try {
      if (isValidStudyId) {
        const study = await loadStudy(studyIdParam);
        if (_notNil(study)) {
          activePresetRef.current = study.settings;
        }
      }

      const response = await invoke({ query: { perPage: 1000 } });
      const deviceTypes = response?.body?.data as RegisteredDevice[];

      const ports = await navigator.serial.getPorts();

      setPorts(ports);
      setDeviceTypes(deviceTypes);
      setSetupComplete(true);

      await loadMappedDevices(ports, deviceTypes, activePresetRef.current);
    } catch (error) {
      if (notAborted(error)) {
        ExceptionHandler.captureException(
          new InVivoError('Could not load study', {
            cause: error,
            slug: 'device-provider',
          })
        );
      }
    }
  };

  useEffect(() => {
    if (!disabled) {
      initialise();
    }
  }, [disabled]);

  useEffect(() => {
    if (setupComplete) {
      workerDeviceTypes.forEach((type) => {
        addDeviceWorker(type);
      });
    }
  }, [workerDeviceTypes, setupComplete]);

  useEffect(() => {
    return () => {
      workerDeviceTypes.forEach((type) => {
        // Send message to worker to disconnect then terminate.
        if (workerLookupRef.current.has(type.id)) {
          const worker = workerLookupRef.current.get(type.id);
          worker!.postMessage({ action: 'terminate', type, origin: window.location.origin });
          workerLookupRef.current.delete(type.id);
        }
      });
    };
  }, []);

  const addDeviceWorker = (type: RegisteredDevice) => {
    if (!workerLookupRef.current.has(type.id)) {
      const worker = new DeviceWorker();
      worker.onmessage = (event: MessageEvent<DeviceWorkerMessage>) => {
        const { action, type, newReadingValue = null, origin } = event.data;
        if (_isNil(origin) || !isAllowedOrigin(origin)) {
          console.error('Untrusted origin from device worker:', origin); // eslint-disable-line no-console
          return;
        }

        const targetId = getTargetId(type.id, type.usb_vendor_id, type.usb_product_id);

        if (action === 'reader-error' && _notNil(targetId)) {
          displayErrorReading(targetId, type);
        } else if (action === 'new-reading' && _notNil(targetId) && _notNil(newReadingValue)) {
          addReading(type, targetId, newReadingValue);
        } else if (action === 'reader-error-retry') {
          worker.terminate();
          workerLookupRef.current.delete(type.id);

          // We want to remove this device from mapped devices as it is no longer connected, however we
          // want to keep it in local storage that it was previously mapped.
          setMappedDevices((mappedDevices) =>
            mappedDevices.filter(
              (device) =>
                !(
                  device.usb_product_id === type.usb_product_id &&
                  device.usb_vendor_id === type.usb_vendor_id &&
                  device.device_type_id === type.id
                )
            )
          );

          initialise();
        } else if (action === 'terminated-new-type' && _notNil(type)) {
          addDeviceWorker(type);

          saveMappedDevicesWithNewType(type.usb_vendor_id, type.usb_product_id, type);
        }
      };

      workerLookupRef.current.set(type.id, worker);

      const message: DeviceWorkerMessage = { action: 'connect', type, origin: window.location.origin };
      worker.postMessage(message);
    }
  };

  const addReading = (type: RegisteredDevice, targetId: string, value: string) => {
    value = stripReading(value, type.reading_type);
    // This could be empty if we strip reading to an empty string, no point in updating the state.
    if (_isNotEmpty(value)) {
      setReadings((readings) => {
        const updatedReadings = { ...readings };
        updatedReadings[targetId] = value;
        return updatedReadings;
      });

      if (document.hidden) {
        broadcastChannel.postMessage({ type, targetId, value });
      } else {
        displayReading(type, value);
      }
    }
  };

  const clearReading = (targetId: string) => {
    setReadings((readings) => {
      const updatedReadings = { ...readings };
      delete updatedReadings[targetId];
      return updatedReadings;
    });
  };

  const getDevices = () => {
    navigator.serial
      .requestPort()
      .then((port) => {
        setPorts([...getPorts(), port]);
        initialise();
      })
      .catch((errors) => {
        // eslint-disable-next-line no-console
        console.log('getDevices error:', errors);
      });
  };

  const getTargetId = (deviceTypeId: DeviceId, usbVendorId: number, usbProductId: number): string | undefined => {
    // This function takes the values from outside state, this is to ensure the latest value for target and preset are used.
    // The read data function is called asynchronously, the state inside of it could be out of date
    // e.g. if the user changes the target or study after the read call has been made
    const deviceMappingFromStorage = localStorage.getItem('deviceMapping');
    const updatedLocalStorage = deviceMappingFromStorage ? JSON.parse(deviceMappingFromStorage) : [];
    const latestMapping: PresetDeviceMapping = updatedLocalStorage.find(
      ({ presetId }: PresetDeviceMapping) => presetId === activePresetRef?.current?.id
    );

    const targetConfig = latestMapping?.mappedDevices.find(
      ({ device_type_id, usb_product_id, usb_vendor_id }) =>
        usbProductId === usb_product_id && usbVendorId === usb_vendor_id && deviceTypeId === device_type_id
    );

    return targetConfig?.activeTarget?.value;
  };

  const refreshDevices = async (hard = true) => {
    if (hard) {
      setPorts([]);
      setMappedDevices([]);
    }

    return navigator.serial.getPorts().then((ports: any) => {
      setPorts(ports);
      initialise();
    });
  };

  const loadStudy = async (studyId: ID): Promise<Study> => {
    const response = await Http.get(apiRoute('studies.show.p', { id: studyId }));

    return response?.data?.data;
  };

  const mapDevices = async (
    ports: SerialPort[],
    deviceTypes: RegisteredDevice[],
    activePreset?: Preset,
    existingMappedDevices?: PresetDeviceMapping[]
  ) => {
    const existingMappingForPreset = existingMappedDevices?.find(({ presetId }) => {
      return activePreset?.id === presetId;
    });

    const updatedMappedDevices: MappedDevice[] = ports.reduce((acc, port: SerialPort) => {
      const portInfo = port.getInfo();
      if (_isNil(portInfo.usbProductId) || _isNil(portInfo.usbVendorId)) {
        // Skipping the port we rely on this being returned in order to map to a device type.
        return acc;
      }
      const { usbProductId, usbVendorId } = portInfo;

      const types = deviceTypes.filter(
        ({ usb_product_id, usb_vendor_id }) => usb_product_id === usbProductId && usb_vendor_id === usbVendorId
      );

      const existingMappingForDevice = existingMappingForPreset?.mappedDevices?.find(
        ({ usb_vendor_id, usb_product_id }) => usb_product_id === usbProductId && usb_vendor_id === usbVendorId
      );

      if (
        types.length === 1 &&
        (_isNil(existingMappingForDevice?.device_type_id) || types[0].id !== existingMappingForDevice?.device_type_id)
      ) {
        // We only want to auto map if there is only one device type for the usb product/vendor id and no device type has been remembered or the old device has been removed from registered devices.
        acc.push({
          device_type_id: types[0].id,
          name: types[0].title,
          target: [],
          usb_product_id: usbProductId,
          usb_vendor_id: usbVendorId,
        });
      } else {
        acc.push({
          device_type_id: existingMappingForDevice?.device_type_id,
          name: existingMappingForDevice?.name,
          target: existingMappingForDevice?.target ?? [],
          activeTarget: existingMappingForDevice?.activeTarget,
          usb_product_id: usbProductId,
          usb_vendor_id: usbVendorId,
        });
      }

      return acc;
    }, [] as MappedDevice[]);

    setMappedDevices(updatedMappedDevices);
    updateLocalStorage(updatedMappedDevices);

    return updatedMappedDevices;
  };

  const loadMappedDevices = async (ports: SerialPort[], deviceTypes: RegisteredDevice[], activePreset?: Preset) => {
    if (_isNotEmpty(ports) && _notNil(activePreset) && _isNotEmpty(deviceTypes)) {
      const deviceMappingFromStorage = localStorage.getItem('deviceMapping');
      return await mapDevices(
        ports,
        deviceTypes,
        activePreset,
        deviceMappingFromStorage ? JSON.parse(deviceMappingFromStorage) : undefined
      );
    }
  };

  const registerTarget = (targetId: string, identifier: string) => {
    const mappingExists = mappedDevices.find(({ target }) => target.find(({ value }) => value === targetId));
    if (_notNil(mappingExists)) {
      setReadyTargets((previousReadyTargets) => {
        const updatedReadyTargets = mappedDevices.reduce((acc: Record<string, string[]>, device) => {
          const { target } = device;
          const found = target?.find(({ value }) => value === targetId);
          if (_notNil(found) && _notNil(targetId)) {
            const existingTargets = previousReadyTargets[targetId] ?? [];
            acc[targetId] = [...existingTargets, identifier];
          }
          return acc;
        }, {});
        return { ...previousReadyTargets, ...updatedReadyTargets };
      });
    }
  };

  const deregisterTarget = (targetId: string, identifier: string) => {
    setReadyTargets((previousReadyTargets) => {
      const existingTargets = previousReadyTargets[targetId] ?? [];
      const updatedTargets = existingTargets?.filter((value) => value !== identifier);
      previousReadyTargets[targetId] = updatedTargets;
      return previousReadyTargets;
    });
  };

  const isTargetReady = (targetId: string | undefined, identifier: string) => {
    if (_notNil(targetId)) {
      const readyTarget = readyTargets[targetId];
      if (_isEmpty(readyTarget)) {
        return false;
      } else if (readyTarget?.length === 1) {
        return true;
      } else {
        const latestTarget = readyTarget[readyTarget.length - 1];
        if (latestTarget === identifier) {
          return true;
        }
      }
    }
    return false;
  };

  const nextReading = (targetId: string, complete: boolean) => {
    setMappedDevices((mappedDevices) => {
      const targetDevice = mappedDevices.find(({ activeTarget }) => activeTarget?.value === targetId);
      if (_notNil(targetDevice)) {
        const { activeTarget, target, usb_product_id, usb_vendor_id } = targetDevice;
        const targetLength = target.length;
        const currentTargetIndex = target?.findIndex(({ value }) => value === activeTarget?.value);
        const nextTargetIndex = (currentTargetIndex + 1) % targetLength;
        const nextTarget = target[nextTargetIndex];

        const updatedMappedDevices = mappedDevices.reduce((acc, device, idx) => {
          // Only trigger an update when there is actually a change otherwise leave the instance id of mapped devices the same
          if (
            (complete || device.activeTarget?.value !== activeTarget?.value) &&
            usb_product_id === device.usb_product_id &&
            usb_vendor_id === device.usb_vendor_id
          ) {
            const result = [...acc];
            result[idx] = { ...device, activeTarget: complete ? nextTarget : activeTarget };
            return result;
          }
          return acc;
        }, mappedDevices);
        // Update local storage on change
        if (mappedDevices !== updatedMappedDevices) {
          updateLocalStorage(updatedMappedDevices);
        }
        return updatedMappedDevices;
      }
      return mappedDevices;
    });
  };

  function saveMappedDevicesWithNewType(
    usb_vendor_id: number,
    usb_product_id: number,
    type: RegisteredDevice | undefined
  ) {
    let deviceFound = false;
    const updatedMappedDevices = mappedDevices.map((device) => {
      if (device.usb_vendor_id === usb_vendor_id && device.usb_product_id === usb_product_id) {
        deviceFound = true;
        return {
          ...device,
          device_type_id: type?.id,
          name: type?.title,
          target: [],
          activeTarget: undefined,
        };
      }
      return device;
    });

    // If no device was found, add a new one
    if (!deviceFound) {
      updatedMappedDevices.push({
        usb_vendor_id,
        usb_product_id,
        device_type_id: type?.id,
        name: type?.title,
        target: [],
        activeTarget: undefined,
      });
    }

    setMappedDevices(updatedMappedDevices);
    updateLocalStorage(updatedMappedDevices);
  }

  const onChangeDeviceType = async (mappedDevice: MappedDevice, newDeviceTypeId: string) => {
    let deviceWorkerExists = false;
    const currentType = deviceTypes?.find(({ id }) => id === mappedDevice.device_type_id);
    const newType = deviceTypes?.find(({ id }) => id === newDeviceTypeId);
    if (_notNil(currentType)) {
      if (workerLookupRef.current.has(currentType.id)) {
        deviceWorkerExists = true;
        const worker = workerLookupRef.current.get(currentType.id);
        worker?.postMessage({ action: 'terminate', type: currentType, newType, origin: window.location.origin });
        workerLookupRef.current.delete(currentType.id);
      }
    }

    // If a device worker exists we need to allow it to close the port and send a message to the main thread to set up the new device type.
    saveMappedDevicesWithNewType(
      mappedDevice.usb_vendor_id,
      mappedDevice.usb_product_id,
      !deviceWorkerExists ? newType : undefined
    );
  };

  const onChangeDeviceTarget = (targetDevice: MappedDevice | undefined, newValue: TargetField[]) => {
    let updatedMappedDevices = [...mappedDevices];
    const deviceExists =
      _notNil(targetDevice) &&
      updatedMappedDevices?.find(
        ({ usb_product_id, usb_vendor_id, device_type_id }) =>
          usb_product_id === targetDevice.usb_product_id &&
          usb_vendor_id === targetDevice.usb_vendor_id &&
          device_type_id === targetDevice.device_type_id
      );

    // if the device already exists in the mapped devices then update it
    if (deviceExists) {
      updatedMappedDevices = updatedMappedDevices.map((device) => {
        const { usb_product_id, usb_vendor_id, device_type_id } = device;

        if (
          usb_product_id === targetDevice.usb_product_id &&
          usb_vendor_id === targetDevice.usb_vendor_id &&
          device_type_id === targetDevice.device_type_id
        ) {
          device.target = newValue;
          device.activeTarget = device.target[0];
        }
        return device;
      });
    } else if (_notNil(targetDevice)) {
      updatedMappedDevices = [
        ...updatedMappedDevices,
        { ...targetDevice, target: newValue, activeTarget: newValue[0] },
      ];
    }

    setMappedDevices(updatedMappedDevices);
    updateLocalStorage(updatedMappedDevices);
  };

  const updateLocalStorage = (updatedMappedDevices: MappedDevice[]) => {
    if (_notNil(activePresetRef.current)) {
      const stringFromLocalStorage = localStorage.getItem('deviceMapping');
      const updatedLocalStorage: PresetDeviceMapping[] = stringFromLocalStorage
        ? JSON.parse(stringFromLocalStorage)
        : [];

      const targetPresetMappingIndex = updatedLocalStorage.findIndex(
        ({ presetId }) => presetId === activePresetRef?.current?.id
      );

      if (targetPresetMappingIndex >= 0) {
        updatedLocalStorage[targetPresetMappingIndex].mappedDevices = updatedMappedDevices.map(
          ({ target, activeTarget, usb_product_id, usb_vendor_id, name, device_type_id }) => ({
            device_type_id,
            name,
            target,
            usb_product_id,
            usb_vendor_id,
            activeTarget,
          })
        );
      } else {
        updatedLocalStorage.push({
          presetId: activePresetRef?.current?.id,
          mappedDevices: updatedMappedDevices.map(
            ({ device_type_id, name, target, activeTarget, usb_product_id, usb_vendor_id }) => ({
              device_type_id,
              name,
              target,
              usb_product_id,
              usb_vendor_id,
              activeTarget,
            })
          ),
        });
      }

      localStorage.setItem('deviceMapping', JSON.stringify(updatedLocalStorage));
    }
  };

  return (
    <DeviceContext.Provider
      value={{
        disabled,
        deviceTypes,
        mappedDevices,
        unmappedDevices,
        activePreset: activePresetRef.current,
        readings,
        readyTargets,
        isTargetReady,
        onChangeDeviceTarget,
        onChangeDeviceType,
        registerTarget,
        deregisterTarget,
        getDevices,
        nextReading,
        clearReading,
        refreshDevices,
        setOnDeviceError,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
};
