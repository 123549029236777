import type {
  DataTableColumn,
  DataTableColumnAddRequest,
  DataTableColumnApiId,
} from '@/components/UI/DataTable/DataTable.model';
import { uniqueNameValidatorFactory } from '@/components/UI/DataTable/toolbar/Menus/AddColumn/AddColumn.util';
import { Select } from '@/components/UI/FormFields';
import { NumberField } from '@/components/UI/FormFields/Number';
import { _isEmpty, _isNil, _notNil } from '@/littledash';
import { DateUtils } from '@/utils/Date.utils';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { decimalPlacesValidator } from '@/validators';
import { ErrorMessage } from '@hookform/error-message';
import type { FC } from 'react';
import { useMemo } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { FormProvider, useForm } from 'react-hook-form';
import styles from './EditDataTableTimestampBaselineRelativeColumn.module.scss';

interface EditDataTableTimestamoBaselineRelativeColumnForm {
  name: string;
  timestamp_baseline_id: DataTableColumnApiId;
  interval: number;
  interval_time_unit: 'MINUTES' | 'HOURS' | 'DAYS';
}

interface EditDataTableTimestamoBaselineColumnProps {
  formData: EditDataTableTimestamoBaselineRelativeColumnForm;
  columnId?: DataTableColumnApiId;
  columns: Array<DataTableColumn>;
  onSubmit: (data: DataTableColumnAddRequest) => Promise<void>;
  closeModal: () => void;
}

export const timeUnitOptions = [
  {
    label: 'Minutes',
    value: 'MINUTES',
  },
  {
    label: 'Hours',
    value: 'HOURS',
  },
  {
    label: 'Days',
    value: 'DAYS',
  },
];

export const EditDataTableTimestampBaselineRelativeColumn: FC<EditDataTableTimestamoBaselineColumnProps> = ({
  formData,
  columnId,
  columns,
  onSubmit,
  closeModal,
}) => {
  const uniqueColumnName = useMemo(
    () => uniqueNameValidatorFactory(new Set(columns.filter((c) => c.id !== columnId).map(({ name }) => name.trim()))),
    [columnId, columns]
  );
  const { interval, interval_time_unit, timestamp_baseline_id } = formData;
  const timestampBaselineColumns = useMemo(
    () =>
      columns
        .filter(({ type }) => type === 'timestampBaseline')
        .map(({ id: value, name: label }) => ({
          value,
          label,
        })),
    [columns]
  );
  const methods = useForm<EditDataTableTimestamoBaselineRelativeColumnForm>({
    mode: 'onChange',
    defaultValues: {
      ...formData,
      interval_time_unit: interval_time_unit ?? 'HOURS',
      interval: DateUtils.convertSecondsToUnit(interval, interval_time_unit),
      timestamp_baseline_id: _notNil(timestamp_baseline_id)
        ? timestamp_baseline_id
        : timestampBaselineColumns?.[0]?.value,
    },
  });
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting, isDirty },
  } = methods;

  const mapFormData = async (data: EditDataTableTimestamoBaselineRelativeColumnForm) => {
    const { interval, interval_time_unit, timestamp_baseline_id } = data;
    await onSubmit({
      type: 'timestampBaselineRelative',
      name: data.name,
      pk: {
        interval: DateUtils.convertUnitToSeconds(interval, interval_time_unit),
        interval_time_unit,
        timestamp_baseline_id,
      },
    });
    closeModal();
  };

  return (
    <ModalContainer size="narrow">
      <ModalHeader
        title={`${_isNil(columnId) ? 'Add' : 'Update'} Timestamp: PK Bleed`}
        closeModal={closeModal}
        className="pa3 bb b--moon-gray bg-white"
      />
      <FormProvider {...methods}>
        <form>
          <div className="pa3 w-100 bl b--moon-gray h-100">
            <div className={styles['data-table-formula-column-container']}>
              <div>
                <label htmlFor="name">Column name</label>
                <input
                  type="text"
                  name="name"
                  ref={register({ required: 'This field is required', maxLength: 255, validate: { uniqueColumnName } })}
                  placeholder="Name"
                  className={errors?.name ? 'input__error' : ''}
                  style={{ marginBottom: 0 }}
                  autoFocus
                />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ message }) => <small className="red db pt2">{message}</small>}
                />
              </div>
              <div className="mt3">
                <div>
                  <Select
                    required
                    name="timestamp_baseline_id"
                    label="PK Dose column"
                    options={timestampBaselineColumns}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="name"
                    render={({ message }) => <small className="red db pt2">{message}</small>}
                  />
                </div>
              </div>
              <div className="mt3">
                <div className="flex justify-apart">
                  <NumberField
                    name="interval"
                    label="Time from dose"
                    required
                    min={0.1}
                    max={1490000}
                    autoFocus={true}
                    customRules={{ decimalPlacesValidator: decimalPlacesValidator('interval') }}
                  />
                  <div className="pl2">
                    <Select required name="interval_time_unit" label="Unit" options={timeUnitOptions} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalActions
            className="pa3 bt b--moon-gray"
            onSubmit={handleSubmit(mapFormData)}
            onCancel={closeModal}
            submitBtnText={_isNil(columnId) ? 'Add' : 'Update'}
            submitButtonProps={{ disabled: !_isEmpty(errors) || !isDirty || isSubmitting, submit: true }}
          />
        </form>
      </FormProvider>
    </ModalContainer>
  );
};
