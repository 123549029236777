import { DataTableCellAlertService } from '@/components/UI/DataTable/service/DataTableCellAlertService';
import { _isEmpty, _isNil, _isNotBlank, _isNotEmpty, _notNil } from '@/littledash';
import type { AnimalAlertV1 } from '@/model/Alert.model';
import type { ID, ISODateTime, Nullable } from '@/model/Common.model';
import InVivoError from '@/model/InVivoError.ts';
import type { StudyApiId } from '@/model/Study.model';
import { AlertService, type AlertUpdateEvent } from '@/utils/alerts/useAlert';
import { structuredCloneUtil } from '@/utils/browser.utils';
import { DateInputUtils, DateUtils } from '@/utils/Date.utils';
import { ExceptionHandler } from '@/utils/ExceptionHandler.ts';
import {
  animationFrameScheduler,
  BehaviorSubject,
  buffer,
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  EMPTY,
  EmptyError,
  filter,
  firstValueFrom,
  forkJoin,
  from,
  fromEvent,
  map,
  merge,
  mergeMap,
  Observable,
  observeOn,
  of,
  shareReplay,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import {
  DataTable,
  DataTableApiLoadCellResponse,
  DataTableCellAlertCache,
  DataTableCellAlertUpdateEventDetail,
  DataTableCellData,
  DataTableCellDataCache,
  DataTableCellIdCoordinate,
  DataTableCellIndexCoordinate,
  DataTableCellReference,
  DataTableCellSelectionChangeEvent,
  DataTableCellSelectionChangeEventDetail,
  DataTableCellSelectionOptions,
  DataTableCellState,
  DataTableCellStatus,
  DataTableCellStatusType,
  DataTableCellsUpdateOptions,
  DataTableCellUpdateEventDetail,
  DataTableCellUpsert,
  DataTableCellUpsertError,
  DataTableCellUpsertResponse,
  DataTableColumn,
  DataTableColumnAddRequest,
  DataTableColumnApiId,
  DataTableColumnIndex,
  DataTableColumnMoveRequest,
  DataTableColumnRemoveResponse,
  DataTableColumnType,
  DataTableColumnUpdateEventDetail,
  DataTableColumnUpdateRequest,
  DataTableDimensions,
  DataTableDirection,
  DataTableEvent,
  DataTableFeatureFlags,
  DataTableHighVolumeUpsertDetail,
  DataTableApiId,
  DataTableListener,
  DataTableMoveColumnResponse,
  DataTableMoveSelectionOptions,
  DataTablePasteLimitExceededEvent,
  DataTableRow,
  DataTableRowAddRequest,
  DataTableRowHeader,
  DataTableRowHeaderId,
  DataTableRowHeaderStateChangeEventDetail,
  DataTableRowApiId,
  DataTableRowIndex,
  DataTableSelection as IDataTableSelection,
  DataTableService as IDataTableService,
  DataTableServiceProps,
  DataTableSettingsUpdate,
  DataTableStateChangeEventDetail,
  DataTableStateChangeType,
  DataTableType,
  DataTableUpdateDisplayedRowHeadersOptions,
  DataTableUpsertDetail,
  DataTableValidateResponse,
  DataTableWindowClearRequest,
  DataTableWindowClearResponse,
} from '../DataTable.model';
import { chunk, fromCellRef, toCellRef } from '../DataTable.util';
import { DataTableCellStateService } from './DataTableCellStateService';
import { DataTableRect, DataTableRectForceLoad, DataTableSelection } from './DataTableSelection';
import { DataTableStateService } from './DataTableStateService';
import { DataTableValidator } from './DataTableValidator';
import { DataTableWorkflowService } from './DataTableWorkflowService';

/* eslint "no-unused-private-class-members": "warn" */

interface InternalColumnData {
  idToIndex: Map<DataTableColumnApiId, number>;
  indexToId: Map<number, DataTableColumnApiId>;
  columns: Map<DataTableColumnApiId, DataTableColumn>;
}

interface InternalRowData {
  idToIndex: Map<DataTableRowApiId, number>;
  indexToId: Map<number, DataTableRowApiId>;
  rows: Map<DataTableRowApiId, DataTableRow>;
}

/**
 * |-1-2-3----------------------|
 * |------|-(dueTime)-|---------|
 * |-------------------[1,2,3]--|
 * @param dueTime timeout duration in milliseconds
 */
const bufferedDebounce =
  <T>(dueTime: number) =>
  (source: Observable<T>): Observable<Array<T>> => {
    return source.pipe(buffer(source.pipe(debounceTime(dueTime))), filter(_isNotEmpty));
  };
const isColumnTypeReadonly = (type: DataTableColumnType): boolean => {
  switch (type) {
    case 'number':
    case 'timestamp':
    case 'timestampBaseline':
    case 'text':
    case 'measurement':
      return false;
    default:
      return true;
  }
};

export class DataTableService implements IDataTableService {
  static #cellLoadAreaLimit = 350 * 350;
  static #initialLoadRect = new DataTableRect({ left: 0, right: 200, top: 0, bottom: 200 });
  static #validSelection = (selection?: DataTableSelection): selection is DataTableSelection & boolean => {
    if (_notNil(selection)) {
      const columnCount = selection.bottomRight.column - selection.topLeft.column + 1;
      const rowCount = selection.bottomRight.row - selection.topLeft.row + 1;
      return columnCount * rowCount <= DataTableService.#cellLoadAreaLimit;
    }
    return false;
  };

  readonly #props: DataTableServiceProps;
  readonly #eventEmitter = new EventTarget();
  readonly #cellData = new Map<DataTableCellReference, DataTableCellDataCache>();
  readonly #cellAlertService = new DataTableCellAlertService();
  readonly #cellStateService = new DataTableCellStateService(this.#eventEmitter);
  readonly #tableStateService = new DataTableStateService(this.#eventEmitter);
  readonly #workflowService = new DataTableWorkflowService(this.#eventEmitter, this);

  #table?: DataTable;
  #columnData?: InternalColumnData;
  #rowData?: InternalRowData;
  #rowHeaders: Partial<Record<DataTableRowHeaderId, DataTableRowHeader>>;

  #selection?: IDataTableSelection;
  #undoStack: Array<string> = [];
  #redoStack: Array<string> = [];

  readonly #destroy$ = new Subject<void>();

  readonly #cellLoadRectOverride$ = new Subject<Readonly<DataTableRect>>();
  readonly #windowScrollRect$ = new BehaviorSubject<Readonly<DataTableRect> | null>(null);
  readonly #debouncedCellUpsert$ = new Subject<Array<DataTableCellUpsert>>();

  readonly #selectionRect$ = fromEvent<DataTableCellSelectionChangeEvent>(
    this.#eventEmitter,
    DataTableEvent.CellSelectionChange
  ).pipe(
    takeUntil(this.#destroy$),
    filter(
      ({
        detail: {
          source,
          selection: {
            rect: { area },
          },
        },
      }) => source === 'user' && area >= 10 && area <= DataTableService.#cellLoadAreaLimit
    ),
    map((selectionChangeEvent) => selectionChangeEvent.detail.selection.rect)
  );
  readonly #cellLoadRect$ = merge(
    this.#windowScrollRect$.pipe(filter((v) => _notNil(v))) as Observable<Readonly<DataTableRect>>,
    this.#selectionRect$,
    this.#cellLoadRectOverride$.pipe(filter((v) => _notNil(v)))
  ).pipe(
    takeUntil(this.#destroy$),
    distinctUntilChanged((previous, current: any) => {
      if (current?.force) {
        return false;
      }
      return previous.contains(current);
    }),
    filter(({ area }) => area <= DataTableService.#cellLoadAreaLimit),
    switchMap(({ top, bottom, left, right }) => {
      const from = this.fromIndexCoordinate({ column: left, row: top });
      const to = this.fromIndexCoordinate({ column: right, row: bottom });
      if (_notNil(from) && _notNil(to)) {
        return of({ from, to, include_alerts: true }).pipe(
          delay(500),
          this.props.apiService.loadCells(this),
          catchError((err) => {
            ExceptionHandler.captureException(
              new InVivoError('Could not load cell window', {
                cause: err,
                slug: 'datatable-cells-load',
              })
            );
            // TODO decide how to handle API errors
            return EMPTY;
          })
        );
      }
      return EMPTY;
    }),
    tap(() => this.validate()),
    observeOn(animationFrameScheduler),
    takeUntil(this.#destroy$),
    shareReplay(1)
  );

  // @ts-expect-error: ignore unused
  readonly #cellLoadRectSubscription = this.#cellLoadRect$.subscribe(async (cellData) => {
    const { cellUpdateEvent, cellAlertUpdateEvent } = cellData.reduce<{
      cellUpdateEvent: DataTableCellUpdateEventDetail;
      cellAlertUpdateEvent: DataTableCellAlertUpdateEventDetail;
    }>(
      (acc, { column_id, row_id, cell: { value }, alerts, updated_at }) => {
        const coordinate: DataTableCellIdCoordinate = { column: column_id, row: row_id };
        if (DataTableCellStatusType.OK !== this.cellStatus(coordinate).type) {
          return acc;
        }

        const ref = toCellRef(coordinate);
        acc.cellAlertUpdateEvent.cellAlerts[ref] = this.#cellAlertService.ingest(coordinate, alerts);
        if (this.#cellData.get(ref)?.value !== value) {
          this.#cellData.set(ref, { value, updated_at });
          acc.cellUpdateEvent[ref] = { value, updated_at };
        }
        return acc;
      },
      { cellUpdateEvent: {}, cellAlertUpdateEvent: { cellAlerts: {}, trigger: 'cell-load' } }
    );
    this.#eventEmitter.dispatchEvent(new CustomEvent(DataTableEvent.CellUpdate, { detail: cellUpdateEvent }));
    this.#eventEmitter.dispatchEvent(new CustomEvent(DataTableEvent.CellAlertUpdate, { detail: cellAlertUpdateEvent }));
  });
  // eslint-enable no-unused-private-class-members

  // @ts-expect-error: ignore unused
  #debouncedCellUpsertSubscription = this.#debouncedCellUpsert$ // eslint-disable-line no-unused-private-class-members
    .pipe(
      takeUntil(this.#destroy$),
      bufferedDebounce(500),
      mergeMap((buffer) => {
        const result = new Map(
          buffer.flat().map((cellUpsert) => [`${cellUpsert.column_id}${cellUpsert.row_id}`, cellUpsert])
        );

        return from(Promise.all<DataTableUpsertDetail>(this.handleChunkCellUpsert(2500, [...result.values()])));
      }),
      observeOn(animationFrameScheduler)
    )
    .subscribe((next) => {
      this.#handleUpdateCellsApiResponse(next);
    });

  readonly #handleKeydown = (event: KeyboardEvent): void => {
    const { target, key, location } = event;
    if ((target as HTMLElement).tagName === 'BODY') {
      switch (key) {
        case 'Enter': {
          if (this.hasSelectedCells()) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
            if (
              _notNil(this.#selection) &&
              this.#selection.rect.area === 1 &&
              this.columnByIndex(this.#selection.topLeft?.column)?.type === 'timestamp'
            ) {
              this.#activateSelection({ options: { direction: event.shiftKey ? 'UP' : 'DOWN' } });
            } else {
              this.#activateSelection();
            }
          }
          break;
        }
        case 'ArrowUp': {
          event.preventDefault();
          event.stopPropagation();
          event.stopImmediatePropagation();
          this.moveSelection('UP', { append: event.shiftKey });
          break;
        }
        case 'ArrowDown': {
          event.preventDefault();
          event.stopPropagation();
          event.stopImmediatePropagation();
          this.moveSelection('DOWN', { append: event.shiftKey });
          break;
        }
        case 'ArrowLeft': {
          event.preventDefault();
          event.stopPropagation();
          event.stopImmediatePropagation();
          this.moveSelection('LEFT', { append: event.shiftKey });
          break;
        }
        case 'ArrowRight': {
          event.preventDefault();
          event.stopPropagation();
          event.stopImmediatePropagation();
          this.moveSelection('RIGHT', { append: event.shiftKey });
          break;
        }
        case 'Tab': {
          event.preventDefault();
          event.stopPropagation();
          event.stopImmediatePropagation();
          if (
            this.workflowService.workflowActive &&
            _notNil(this.#selection) &&
            this.#selection.rect.area === 1 &&
            this.columnByIndex(this.#selection.topLeft?.column)?.type === 'timestamp'
          ) {
            this.#activateSelection({ options: { direction: event.shiftKey ? 'LEFT' : 'RIGHT' } });
          } else {
            this.moveSelection(event.shiftKey ? 'LEFT' : 'RIGHT');
          }
          break;
        }
        case 'Escape': {
          break;
        }
        case 'Clear': {
          this.clearSelection();
          break;
        }
        case 'Backspace': {
          this.clearSelection();
          break;
        }
        default: {
          if (
            (location === KeyboardEvent.DOM_KEY_LOCATION_STANDARD ||
              location === KeyboardEvent.DOM_KEY_LOCATION_NUMPAD) &&
            key.length === 1
          ) {
            if (key === 'a' && (event.metaKey || event.ctrlKey)) {
              event.preventDefault();
              event.stopPropagation();
              event.stopImmediatePropagation();
              this.#bulkSelectCells('all');
            } else if (key === ' ' && event.ctrlKey) {
              event.preventDefault();
              event.stopPropagation();
              event.stopImmediatePropagation();
              this.#bulkSelectCells('column');
            } else if (key === ' ' && event.shiftKey) {
              event.preventDefault();
              event.stopPropagation();
              event.stopImmediatePropagation();
              this.#bulkSelectCells('row');
            } else if (key === 'z' && event.metaKey) {
              event.preventDefault();
              event.stopPropagation();
              event.stopImmediatePropagation();
              if (event.shiftKey) {
                this.redo().then(() => this.validate());
              } else {
                this.undo().then(() => this.validate());
              }
            } else if (this.hasSelectedCells() && !(event.metaKey || event.ctrlKey)) {
              event.preventDefault();
              event.stopPropagation();
              event.stopImmediatePropagation();
              this.#activateSelection({ content: key });
            }
          }
        }
      }
    }
  };
  readonly #handleCopy = (event: ClipboardEvent) => {
    if ((event.target as HTMLElement).tagName !== 'INPUT') {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      this.#handleCutOrCopy('copy');
    }
  };

  readonly #handleCut = (event: ClipboardEvent) => {
    if ((event.target as HTMLElement).tagName !== 'INPUT') {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      this.#handleCutOrCopy('cut');
    }
  };
  readonly #handleAlertResolved = (event: AlertUpdateEvent) => {
    this.#eventEmitter.dispatchEvent(
      new CustomEvent(DataTableEvent.CellAlertUpdate, {
        detail: {
          trigger: 'cell-update',
          cellAlerts: this.#cellAlertService.resolve(event.detail),
        },
      })
    );
  };

  readonly #handleCutOrCopy = (type: 'cut' | 'copy') => {
    if (_notNil(this.#selection)) {
      const { topLeft, bottomRight } = this.#selection;
      const clipboardData: Array<string> = [];
      for (let row = topLeft.row; row <= bottomRight.row; row++) {
        const rowData: Array<string> = [];
        for (let column = topLeft.column; column <= bottomRight.column; column++) {
          const ref = toCellRef(this.fromIndexCoordinate({ column, row }));
          rowData.push(this.#cellData.get(ref)?.value ?? '');
        }
        clipboardData.push(rowData.join('\t'));
      }
      navigator.clipboard.writeText(clipboardData.join('\n'));
      if (type === 'cut') {
        this.clearSelection();
      }
    }
  };

  readonly #handlePaste = (event: ClipboardEvent) => {
    if (
      event.clipboardData?.types?.includes('text/plain') &&
      _notNil(this.#selection) &&
      !this.props.readonly &&
      (event.target as HTMLElement).tagName !== 'INPUT'
    ) {
      const { topLeft, bottomRight } = this.#selection;
      const selectionHeight = bottomRight.row - topLeft.row + 1;
      const selectionWidth = bottomRight.column - topLeft.column + 1;
      const rowData =
        event.clipboardData
          ?.getData('text/plain')
          ?.split(/\r?\n/)
          .map((row) => row.split('\t')) ?? [];
      const pasteDataHeight = rowData.length;
      if (pasteDataHeight === 0) {
        return;
      }
      const pasteDataWidth = rowData?.[0]?.length;

      if (pasteDataWidth * pasteDataHeight > 20000) {
        this.#eventEmitter.dispatchEvent(
          new CustomEvent<DataTablePasteLimitExceededEvent>(DataTableEvent.PasteLimitExceeded)
        );
        return;
      }

      const verticalRepeat = Math.floor(selectionHeight / pasteDataHeight);
      const verticalRepeatActual = verticalRepeat < 1 ? 1 : verticalRepeat;
      const horizontalRepeat = Math.floor(selectionWidth / pasteDataWidth);
      const horizontalRepeatActual = horizontalRepeat < 1 ? 1 : horizontalRepeat;

      const cellUpdates: Array<DataTableCellUpsert> = [];
      for (let rowRepeat = 0; rowRepeat < verticalRepeatActual; rowRepeat++) {
        const pasteDataRowOffset = pasteDataHeight * rowRepeat;
        cellUpdates.push(
          ...rowData.flatMap((row, pasteDataRowIndex) => {
            const columnPasteResult: Array<DataTableCellUpsert> = [];
            for (let columnRepeat = 0; columnRepeat < horizontalRepeatActual; columnRepeat++) {
              const pasteDataColumnOffset = pasteDataWidth * columnRepeat;
              columnPasteResult.push(
                ...row.reduce<Array<DataTableCellUpsert>>((acc, value, pasteDataColumnIndex) => {
                  const column = pasteDataColumnIndex + pasteDataColumnOffset + topLeft.column;
                  const row = pasteDataRowIndex + pasteDataRowOffset + topLeft.row;
                  const coord = this.fromIndexCoordinate({ column, row });
                  if (_notNil(coord) && _isNotBlank(value)) {
                    acc.push({ column_id: coord.column, row_id: coord.row, value });
                  }
                  return acc;
                }, [])
              );
            }
            return columnPasteResult;
          })
        );
      }
      if (cellUpdates.length > 0) {
        this.updateCells(cellUpdates).then(() => this.validate());
        this.#selection = new DataTableSelection({
          from: topLeft,
          to: {
            column: topLeft.column + horizontalRepeatActual * pasteDataWidth - 1,
            row: topLeft.row + verticalRepeatActual * pasteDataHeight - 1,
          },
        });
        this.#eventEmitter.dispatchEvent(
          new CustomEvent<DataTableCellSelectionChangeEventDetail>(DataTableEvent.CellSelectionChange, {
            detail: {
              source: 'api',
              selection: Object.freeze(this.#selection),
              scrollTo: false,
              activate: false,
            },
          })
        );
      }
    }
  };

  constructor(private readonly props: DataTableServiceProps) {
    this.#props = Object.freeze(props);
    this.#rowHeaders = structuredCloneUtil(props.rowHeaders).reduce((acc, rh) => ({ ...acc, [rh.id]: rh }), {});
    document.addEventListener('keydown', this.#handleKeydown);
    document.addEventListener('paste', this.#handlePaste);
    document.addEventListener('copy', this.#handleCopy);
    document.addEventListener('cut', this.#handleCut);
    AlertService.addEventListener('alert-resolved', this.#handleAlertResolved, { passive: true });
  }

  get name(): Readonly<string | undefined> {
    return Object.freeze(this.#table?.name);
  }

  get tableId(): Readonly<DataTableApiId | undefined> {
    return Object.freeze(this.#table?.id as DataTableApiId | undefined);
  }

  get studyApiId(): Readonly<StudyApiId> {
    return Object.freeze(this.#props.studyApiId);
  }

  get dimensions(): Readonly<DataTableDimensions> {
    return Object.freeze({ ...this.#props.dimensions });
  }

  get type(): Readonly<DataTableType | 'unknown'> {
    return Object.freeze(this.#table?.type ?? 'unknown');
  }

  get unit(): Readonly<string | null | undefined> {
    return Object.freeze(this.#table?.unit);
  }

  get readonly(): Readonly<boolean> {
    return Object.freeze(this.#props.readonly);
  }

  get columns(): Readonly<Array<DataTableColumn>> {
    return Object.freeze([...(this.#columnData?.columns?.values() ?? [])]);
  }

  get rows(): Readonly<Array<DataTableRow>> {
    return Object.freeze([...(this.#rowData?.rows?.values() ?? [])]);
  }

  get columnCount(): number {
    return this.#columnData?.columns?.size ?? 0;
  }

  get rowCount(): number {
    return this.#rowData?.rows?.size ?? 0;
  }

  get selection(): Readonly<DataTableSelection | undefined> {
    return Object.freeze(this.#selection);
  }

  get working(): Readonly<boolean> {
    return this.#tableStateService.working;
  }

  get workflowService(): Readonly<DataTableWorkflowService> {
    return this.#workflowService;
  }

  get featureFlags(): Readonly<DataTableFeatureFlags> {
    return Object.freeze({ ...(this.#props.featureFlags ?? {}) });
  }

  async initialise(): Promise<DataTableService> {
    this.#table = await this.#invokeDataTableFetchAPI();

    await this.updateDisplayedRowHeaders(
      new Set((this.#table?.settings?.display_columns ?? []) as Array<DataTableRowHeaderId>),
      { persist: false }
    );
    this.#columnData = DataTableService.#generateInternalColumnData(
      (this.#table?.columns ?? []) as Array<DataTableColumn>
    );
    this.#rowData = DataTableService.#generateInternalRowData((this.#table?.rows ?? []) as Array<DataTableRow>);

    const columnCount = this.columnCount;
    const rowCount = this.rowCount;
    const { right: initialRight, bottom: initialBottom } = DataTableService.#initialLoadRect;
    const right = (columnCount > initialRight ? initialRight : columnCount) - 1;
    const bottom = (rowCount > initialBottom ? initialBottom : rowCount) - 1;
    this.#workflowService.initialise();
    this.#cellLoadRectOverride$.next(new DataTableRect({ left: 0, right, top: 0, bottom }));

    const $cellLoadRectInitialValue = firstValueFrom(this.#cellLoadRect$.pipe(takeUntil(this.#destroy$))).catch(
      (err) => {
        if (!(err instanceof EmptyError)) {
          throw new Error('Datatable cell window load error', { cause: err });
        }
      }
    );
    // if the datatable has columns await the response
    if (this.rowCount > 0 && this.columnCount > 0) {
      await $cellLoadRectInitialValue;
    }
    return this;
  }

  async validate(): Promise<void> {
    await this.#invokeDataTableValidateApi({} as DataTableWindowClearRequest).then((validation) => {
      this.#cellStateService.clearType(DataTableCellStatusType.AsyncValidationWarning);
      this.#cellStateService.asyncValidationErrror(validation.errors, []);
    });
  }

  validateColumn(...columns: Array<DataTableColumnApiId>): void {
    const invalidCells = columns.reduce<Array<DataTableCellUpsertError>>((columnAcc, columnId) => {
      const column = this.columnById(columnId);
      if (_notNil(column)) {
        this.rows.reduce<Array<DataTableCellUpsertError>>((rowAcc, row) => {
          const cell = this.cellData({ column: column.id, row: row.id });
          const validationResult = DataTableValidator.validate(column?.type, cell?.value ?? null);
          if (!validationResult.valid) {
            rowAcc.push({
              column_id: column.id,
              row_id: row.id,
              value: cell?.value ?? null,
              error: validationResult.error,
            });
          }
          return rowAcc;
        }, columnAcc);
      }
      return columnAcc;
    }, []);
    this.#cellStateService.validationError(invalidCells);
  }

  destroy() {
    this.#destroy$.next();
    this.#destroy$.complete();
    this.#cellStateService.destroy();
    document.removeEventListener('keydown', this.#handleKeydown);
    document.removeEventListener('paste', this.#handlePaste);
    document.removeEventListener('copy', this.#handleCopy);
    AlertService.removeEventListener('alert-resolved', this.#handleAlertResolved);

    // TODO probably not needed
    this.#cellData.clear();
    this.#columnData?.columns?.clear();
    this.#columnData?.idToIndex?.clear();
    this.#columnData?.indexToId?.clear();
    this.#rowData?.rows?.clear();
    this.#rowData?.idToIndex?.clear();
    this.#rowData?.indexToId?.clear();
    this.#selection = undefined;
    this.#undoStack = [];
    this.#redoStack = [];
  }

  hasColumn(columnId: DataTableColumnApiId): boolean {
    return this.#columnData?.columns?.has(columnId) ?? false;
  }

  cellData(cellCoordinate: DataTableCellIdCoordinate | undefined): DataTableCellDataCache | undefined {
    const indexCoordinate = this.toIndexCoordinate(cellCoordinate);
    if (_notNil(cellCoordinate) && _notNil(indexCoordinate)) {
      return this.#cellData.get(toCellRef(cellCoordinate));
    }
  }

  cellAlerts(cellCoordinate: DataTableCellIdCoordinate | undefined): DataTableCellAlertCache | undefined {
    const indexCoordinate = this.toIndexCoordinate(cellCoordinate);
    if (_notNil(cellCoordinate) && _notNil(indexCoordinate)) {
      return this.#cellAlertService.get(cellCoordinate);
    }
  }

  windowScrollChange(rect: DataTableRect): void {
    this.#windowScrollRect$.next(Object.freeze(rect));
  }

  async #invokeDataTableFetchAPI(): Promise<DataTable> {
    return firstValueFrom(of(null).pipe(takeUntil(this.#destroy$), this.#props.apiService.loadTable(this)));
  }

  async #invokeCellUpdateApi(updates: Array<DataTableCellUpsert>): Promise<DataTableCellUpsertResponse> {
    if (_isEmpty(updates)) {
      return Promise.resolve({ success: true, effects: [] });
    }
    return firstValueFrom(of(updates).pipe(takeUntil(this.#destroy$), this.#props.apiService.updateCells(this)));
  }

  async #invokeDataTableValidateApi(
    windowClearRequest: DataTableWindowClearRequest
  ): Promise<DataTableValidateResponse> {
    return firstValueFrom(
      of(windowClearRequest).pipe(takeUntil(this.#destroy$), this.#props.apiService.validate(this))
    );
  }

  async #invokeWindowClearApi(windowClearRequest: DataTableWindowClearRequest): Promise<DataTableWindowClearResponse> {
    return firstValueFrom(
      of(windowClearRequest).pipe(takeUntil(this.#destroy$), this.#props.apiService.clearWindow(this))
    );
  }

  async #invokeDataTableSettingsUpdateApi(settingsUpdate: DataTableSettingsUpdate): Promise<void> {
    return firstValueFrom(
      of(settingsUpdate).pipe(takeUntil(this.#destroy$), this.#props.apiService.updateTableSettings(this))
    );
  }

  async #invokeDataTableColumMoveApi(
    columnMoveRequest: DataTableColumnMoveRequest
  ): Promise<DataTableMoveColumnResponse> {
    return firstValueFrom(
      of(columnMoveRequest).pipe(takeUntil(this.#destroy$), this.#props.apiService.moveColumn(this))
    );
  }

  async #invokeDataTableColumAddApi(columnAddRequest: DataTableColumnAddRequest): Promise<Array<DataTableColumn>> {
    return firstValueFrom(of(columnAddRequest).pipe(takeUntil(this.#destroy$), this.#props.apiService.addColumn(this)));
  }

  async #invokeDataTableColumnUpdateApi(columnUpdateRequest: DataTableColumnUpdateRequest): Promise<DataTableColumn> {
    return firstValueFrom(
      of(columnUpdateRequest).pipe(takeUntil(this.#destroy$), this.#props.apiService.updateColumn(this))
    );
  }

  async #invokeDataTableRowAddApi(rowAddRequest: DataTableRowAddRequest): Promise<Array<DataTableRow>> {
    return firstValueFrom(of(rowAddRequest).pipe(takeUntil(this.#destroy$), this.#props.apiService.addRow(this)));
  }

  async #invokeDataTableColumRemoveApi(columnId: DataTableColumnApiId): Promise<DataTableColumnRemoveResponse> {
    return firstValueFrom(of(columnId).pipe(takeUntil(this.#destroy$), this.#props.apiService.removeColumn(this)));
  }

  async #invokeDataTableRowRemoveApi(rowId: DataTableRowApiId): Promise<void> {
    return firstValueFrom(of(rowId).pipe(takeUntil(this.#destroy$), this.#props.apiService.removeRow(this)));
  }

  handleChunkCellUpsert(
    chunkSize = 2500 as number,
    validCells: Array<DataTableCellUpsert>
  ): Array<Promise<DataTableUpsertDetail>> {
    const validCellChunks = chunk(validCells, { chunkSize });
    let eventDetail: DataTableHighVolumeUpsertDetail = {
      totalChunkedRequests: validCellChunks.length,
      pending: [...validCellChunks],
      resolved: [],
      rejected: [],
    };
    if (validCellChunks.length > 1) {
      this.#eventEmitter.dispatchEvent(
        new CustomEvent(DataTableEvent.HighVolumeUpsert, {
          detail: eventDetail,
        })
      );
    }
    return validCellChunks.map((chunk, chunkIndex) =>
      this.#tableStateService.wrapOperation(
        this.#invokeCellUpdateApi(chunk)
          .then((response) => {
            if (validCellChunks.length > 1) {
              eventDetail = {
                ...eventDetail,
                totalChunkedRequests: eventDetail.totalChunkedRequests--,
                pending: eventDetail.pending.splice(chunkIndex, 0),
                resolved: [...eventDetail.resolved, chunk],
              };
              this.#eventEmitter.dispatchEvent(
                new CustomEvent(DataTableEvent.HighVolumeUpsert, {
                  detail: eventDetail,
                })
              );
            }
            return {
              cells: chunk,
              invalidCells: response.success ? [] : [...response.errors],
              networkErrors: [],
              effects: response.effects ?? [],
              alerts: response.data_table_alerts ?? [],
            };
          })
          .catch(() => {
            if (validCellChunks.length > 1) {
              eventDetail = {
                ...eventDetail,
                pending: eventDetail.pending.splice(chunkIndex, 0),
                rejected: [...eventDetail.rejected, chunk],
              };
              this.#eventEmitter.dispatchEvent(
                new CustomEvent(DataTableEvent.HighVolumeUpsert, {
                  detail: eventDetail,
                })
              );
            }
            return { cells: chunk, invalidCells: [], networkErrors: chunk, effects: [], alerts: [] };
          })
      )
    );
  }

  #handleUndoRedoStackUpdate(
    snapshotType: Required<DataTableCellsUpdateOptions>['snapshot'],
    operations: Array<[DataTableCellReference, DataTableCellDataCache]>
  ): void {
    let emit = false;
    switch (snapshotType) {
      case 'normal':
        emit = this.#undoStack.length === 0 || this.redoEnabled();
        this.#undoStack.push(JSON.stringify(operations));
        this.#redoStack = [];
        break;
      case 'redo':
        emit = !this.undoEnabled() || !this.redoEnabled();
        this.#undoStack.push(JSON.stringify(operations));
        break;
      case 'undo':
        emit = !this.redoEnabled() || !this.undoEnabled();
        this.#redoStack.push(JSON.stringify(operations));
        break;
    }
    if (emit) {
      this.#eventEmitter.dispatchEvent(
        new CustomEvent(DataTableEvent.UndoRedoStateChange, {
          detail: { redoEnabled: this.redoEnabled(), undoEnabled: this.undoEnabled() },
        })
      );
    }
  }

  #handleUpdateCellsApiResponse(
    result: Array<DataTableUpsertDetail>,
    options: { invalidCells?: Array<DataTableCellUpsertError>; updated_at?: ISODateTime } = {}
  ): void {
    const { cells, invalidCells, networkErrors, effects, alerts } = result.reduce<DataTableUpsertDetail>(
      (acc, resultChunk) => {
        acc.cells.push(...resultChunk.cells);
        acc.invalidCells.push(...resultChunk.invalidCells);
        acc.networkErrors.push(...resultChunk.networkErrors);
        acc.effects.push(...resultChunk.effects);
        acc.alerts.push(...resultChunk.alerts);
        return acc;
      },
      { cells: [], invalidCells: options?.invalidCells ?? [], networkErrors: [], effects: [], alerts: [] }
    );
    this.#handleSideEffects(
      effects,
      cells.map((c) => c.row_id)
    );
    this.#cellStateService.validationError(invalidCells, cells);
    this.#cellStateService.networkError(networkErrors);
    this.#eventEmitter.dispatchEvent(
      new CustomEvent(DataTableEvent.CellAlertUpdate, {
        detail: alerts.reduce<DataTableCellAlertUpdateEventDetail>(
          (acc, cellAlert) => {
            const ref = toCellRef({ column: cellAlert.column_id, row: cellAlert.row_id });
            acc.cellAlerts[ref] = this.#cellAlertService.ingest(
              {
                column: cellAlert.column_id,
                row: cellAlert.row_id,
              },
              cellAlert.alerts
            );
            return acc;
          },
          { cellAlerts: {}, trigger: 'cell-update' }
        ),
      })
    );
  }

  async updateCells(
    cellUpdates: Array<DataTableCellUpsert>,
    options?: DataTableCellsUpdateOptions
  ): Promise<DataTableCellUpdateEventDetail> {
    if (this.#props.readonly) {
      return Promise.reject(new Error('DataTable readonly'));
    }
    const snapshotType = options?.snapshot ?? 'normal';
    const snapshotEnabled = snapshotType !== 'none';
    const updated_at = DateUtils.dateTimeNow();
    const {
      validCells,
      validCells$,
      invalidCells: invalidCellsBeforePersist,
      updateEvent,
      cellAlertUpdateEvent,
      undoOrRedoOperations,
    } = cellUpdates.reduce<{
      validCells: Array<DataTableCellUpsert>;
      validCells$: Array<DataTableCellUpsert>;
      invalidCells: Array<DataTableCellUpsertError>;
      undoOrRedoOperations: Array<[DataTableCellReference, DataTableCellDataCache]>;
      updateEvent: DataTableCellUpdateEventDetail;
      cellAlertUpdateEvent: DataTableCellAlertUpdateEventDetail;
    }>(
      (acc, cellUpdate) => {
        const column = this.columnById(cellUpdate.column_id);
        const ref = toCellRef({ column: cellUpdate.column_id, row: cellUpdate.row_id });
        const previous = this.#cellData.get(ref);
        const validationResult = DataTableValidator.validate(column?.type, cellUpdate.value);
        if (!this.#isColumnReadonly(column)) {
          acc.updateEvent[ref] = { value: cellUpdate.value, updated_at };
          this.#cellData.set(ref, { value: cellUpdate.value, updated_at });
          if (snapshotEnabled && previous?.value !== cellUpdate.value) {
            acc.undoOrRedoOperations.push([
              ref,
              {
                value: previous?.value ?? null,
                updated_at: previous?.updated_at ?? '0',
              },
            ]);
          }
          if (validationResult.valid) {
            if (previous?.value !== cellUpdate.value) {
              acc.cellAlertUpdateEvent.cellAlerts[ref] = {
                column: cellUpdate.column_id,
                row: cellUpdate.row_id,
                alerts: new Map<ID, AnimalAlertV1>(),
              };
              this.#cellAlertService.delete({ column: cellUpdate.column_id, row: cellUpdate.row_id });
              if (column?.type === 'measurement' && _isNil(options?.windowClear)) {
                acc.validCells$.push(cellUpdate);
              } else {
                acc.validCells.push(cellUpdate);
              }
            }
          } else {
            acc.invalidCells.push({ ...cellUpdate, error: validationResult.error });
          }
        }
        return acc;
      },
      {
        validCells: [],
        validCells$: [],
        invalidCells: [],
        undoOrRedoOperations: [],
        updateEvent: {},
        cellAlertUpdateEvent: { cellAlerts: {}, trigger: 'cell-load' },
      }
    );
    this.#eventEmitter.dispatchEvent(new CustomEvent(DataTableEvent.CellUpdate, { detail: updateEvent }));
    this.#eventEmitter.dispatchEvent(new CustomEvent(DataTableEvent.CellAlertUpdate, { detail: cellAlertUpdateEvent }));
    if (snapshotEnabled && _isNotEmpty(undoOrRedoOperations)) {
      this.#handleUndoRedoStackUpdate(snapshotType, undoOrRedoOperations);
    }
    if (_isNotEmpty(validCells$)) {
      this.#debouncedCellUpsert$.next(validCells$);
    }

    return Promise.all<DataTableUpsertDetail>(
      _isNil(options?.windowClear)
        ? this.handleChunkCellUpsert(2500, validCells)
        : new Array(validCells).map((chunk) =>
            this.#tableStateService.wrapOperation(
              this.#invokeWindowClearApi({ ...(options?.windowClear as DataTableWindowClearRequest) })
                .then((response) => ({
                  cells: chunk,
                  invalidCells: [],
                  networkErrors: [],
                  effects: response.effects ?? [],
                  alerts: [],
                }))
                .catch(() => ({ cells: chunk, invalidCells: [], networkErrors: chunk, effects: [], alerts: [] }))
            )
          )
    ).then((result) => {
      this.#handleUpdateCellsApiResponse(result, { invalidCells: invalidCellsBeforePersist, updated_at });
      return updateEvent;
    });
  }

  cellSelected(cellCoordinate: DataTableCellIndexCoordinate): boolean {
    return this.#selection?.contains(cellCoordinate) ?? false;
  }

  columnSelected(column: number): boolean {
    return this.#selection?.containsColumn(column) ?? false;
  }

  rowSelected(row: number): boolean {
    return this.#selection?.containsRow(row) ?? false;
  }

  cellState(cellCoordinate: DataTableCellIdCoordinate | undefined): DataTableCellState {
    if (_notNil(cellCoordinate)) {
      return this.#cellStateService.cellState(cellCoordinate, this.#cellData.has(toCellRef(cellCoordinate)));
    }
    return 'unknown';
  }

  cellStatus(cellCoordinate: DataTableCellIdCoordinate | undefined): DataTableCellStatus {
    if (_notNil(cellCoordinate)) {
      return this.#cellStateService.cellStatus(cellCoordinate);
    }
    return { type: DataTableCellStatusType.OK };
  }

  rowHasError(row: DataTableRowApiId | undefined): boolean {
    return this.#cellStateService.rowInvalid(row);
  }

  columnByIndex(index: number | undefined): DataTableColumn | undefined {
    if (_notNil(index) && Number.isFinite(index)) {
      return this.#columnData?.columns?.get(this.#columnData?.indexToId?.get(index) ?? 'dtc_empty');
    }
  }

  columnById(columnId: DataTableColumnApiId | undefined): DataTableColumn | undefined {
    if (_notNil(columnId)) {
      return this.#columnData?.columns?.get(columnId);
    }
  }

  columnsByIds(columnIds: Array<DataTableColumnApiId> | undefined): Array<DataTableColumn> {
    if (_notNil(columnIds)) {
      return columnIds.reduce((acc, colId) => {
        const col = this.columnById(colId);
        if (_notNil(col)) {
          acc.push(col);
        }
        return acc;
      }, [] as Array<DataTableColumn>);
    }
    return [];
  }

  rowByIndex(index: number | undefined): DataTableRow | undefined {
    if (_notNil(index) && Number.isFinite(index)) {
      return this.#rowData?.rows?.get(this.#rowData?.indexToId?.get(index) ?? 'dtr_empty');
    }
  }

  fromIndexCoordinate(cellCoordinate?: DataTableCellIndexCoordinate): DataTableCellIdCoordinate | undefined {
    if (_notNil(cellCoordinate)) {
      const column = this.#columnData?.indexToId?.get(cellCoordinate.column);
      const row = this.#rowData?.indexToId?.get(cellCoordinate.row);
      if (_notNil(column) && _notNil(row)) {
        return { column, row };
      }
    }
  }

  moveSelection(direction: DataTableDirection, options?: DataTableMoveSelectionOptions): void {
    if (_notNil(this.#selection)) {
      const append = options?.append ?? false;
      let { column: columnUpdate, row: rowUpdate } = append ? this.#selection.to : this.#selection.from;

      switch (direction) {
        case 'UP': {
          rowUpdate--;
          break;
        }
        case 'DOWN': {
          rowUpdate++;
          break;
        }
        case 'LEFT': {
          columnUpdate--;
          break;
        }
        case 'RIGHT': {
          columnUpdate++;
        }
      }
      const maxColumnIndex = this.columnCount - 1;
      const maxRowIndex = this.rowCount - 1;
      const column = columnUpdate < 0 ? 0 : columnUpdate > maxColumnIndex ? maxColumnIndex : columnUpdate;
      const row = rowUpdate < 0 ? 0 : rowUpdate > maxRowIndex ? maxRowIndex : rowUpdate;
      let updatedSelection;
      if (append) {
        updatedSelection = new DataTableSelection({ from: this.#selection.from, to: { column, row } });
      } else {
        updatedSelection = new DataTableSelection({ from: { column, row }, to: { column, row } });
      }
      if (DataTableService.#validSelection(updatedSelection)) {
        this.#selection = updatedSelection;
        this.#eventEmitter.dispatchEvent(
          new CustomEvent<DataTableCellSelectionChangeEventDetail>(DataTableEvent.CellSelectionChange, {
            detail: {
              source: 'user',
              selection: Object.freeze(this.#selection),
              scrollTo: false,
              activate: false,
            },
          })
        );
      }
    } else {
      this.selectCell({ column: 0, row: 0 }, { scrollTo: false });
    }
  }

  selectRow(rowIndex: number): void {
    const updatedSelection = new DataTableSelection({
      from: { column: 0, row: rowIndex },
      to: { column: this.columnCount - 1, row: rowIndex },
    });
    if (DataTableService.#validSelection(updatedSelection)) {
      this.#selection = updatedSelection;
      this.#eventEmitter.dispatchEvent(
        new CustomEvent<DataTableCellSelectionChangeEventDetail>(DataTableEvent.CellSelectionChange, {
          detail: {
            source: 'user',
            selection: Object.freeze(this.#selection),
            scrollTo: false,
            activate: false,
          },
        })
      );
    }
  }

  selectColumn(columnIndex: number): void {
    const updatedSelection = new DataTableSelection({
      from: { column: columnIndex, row: 0 },
      to: { column: columnIndex, row: this.rowCount - 1 },
    });
    if (DataTableService.#validSelection(updatedSelection)) {
      this.#selection = updatedSelection;
      this.#eventEmitter.dispatchEvent(
        new CustomEvent<DataTableCellSelectionChangeEventDetail>(DataTableEvent.CellSelectionChange, {
          detail: {
            source: 'user',
            selection: Object.freeze(this.#selection),
            scrollTo: false,
            activate: false,
          },
        })
      );
    }
  }

  #bulkSelectCells(operation: 'all' | 'column' | 'row'): void {
    let updatedSelection;
    switch (operation) {
      case 'all': {
        updatedSelection = new DataTableSelection({
          from: { column: 0, row: 0 },
          to: { column: this.columnCount - 1, row: this.rowCount - 1 },
        });
        break;
      }
      case 'column': {
        if (_notNil(this.#selection)) {
          updatedSelection = new DataTableSelection({
            from: { column: this.#selection.topLeft.column, row: 0 },
            to: { column: this.#selection.bottomRight.column, row: this.rowCount - 1 },
          });
        }
        break;
      }
      case 'row': {
        if (_notNil(this.#selection)) {
          updatedSelection = new DataTableSelection({
            from: { column: 0, row: this.#selection.topLeft.row },
            to: { column: this.columnCount - 1, row: this.#selection.bottomRight.row },
          });
        }
        break;
      }
    }
    if (DataTableService.#validSelection(updatedSelection)) {
      this.#selection = updatedSelection;
      this.#eventEmitter.dispatchEvent(
        new CustomEvent<DataTableCellSelectionChangeEventDetail>(DataTableEvent.CellSelectionChange, {
          detail: {
            source: 'user',
            selection: Object.freeze(this.#selection),
            scrollTo: false,
            activate: false,
          },
        })
      );
    }
  }

  selectCell(cellCoordinate: DataTableCellIndexCoordinate, options: DataTableCellSelectionOptions): void {
    let updatedSelection;
    if (options?.append ?? false) {
      updatedSelection = new DataTableSelection({
        from: this.#selection?.from ?? { column: 0, row: 0 },
        to: cellCoordinate,
      });
    } else {
      updatedSelection = new DataTableSelection({ from: cellCoordinate, to: cellCoordinate });
    }

    if (DataTableService.#validSelection(updatedSelection)) {
      this.#selection = updatedSelection;
      this.#eventEmitter.dispatchEvent(
        new CustomEvent<DataTableCellSelectionChangeEventDetail>(DataTableEvent.CellSelectionChange, {
          detail: {
            source: options?.source ?? 'user',
            selection: Object.freeze(this.#selection),
            scrollTo: options?.scrollTo ?? false,
            activate: options?.activate ?? false,
          },
        })
      );
    }
  }

  hasSelectedCells(): boolean {
    return _notNil(this.#selection);
  }

  subscribe<Event extends DataTableEvent>(
    event: Event,
    listener: DataTableListener[Event],
    signal?: AbortSignal
  ): void {
    this.#eventEmitter.addEventListener(event, listener as EventListener, { passive: true, signal });
  }

  unsubscribe<Event extends DataTableEvent>(event: Event, listener: DataTableListener[Event]): void {
    this.#eventEmitter.removeEventListener(event, listener as EventListener);
  }

  toIndexCoordinate(cellCoordinate?: DataTableCellIdCoordinate): DataTableCellIndexCoordinate | undefined {
    if (_notNil(cellCoordinate)) {
      const column = this.#columnData?.idToIndex?.get(cellCoordinate.column);
      const row = this.#rowData?.idToIndex?.get(cellCoordinate.row);
      if (_notNil(column) && _notNil(row)) {
        return { column, row };
      }
    }
  }

  toIndexColumn(columnId?: DataTableColumnApiId): DataTableColumnIndex | undefined {
    if (_notNil(columnId)) {
      return this.#columnData?.idToIndex.get(columnId);
    }
  }

  toIndexRow(rowId?: DataTableRowApiId): DataTableRowIndex | undefined {
    if (_notNil(rowId)) {
      return this.#rowData?.idToIndex.get(rowId);
    }
  }

  async undo(): Promise<DataTableCellUpdateEventDetail> {
    if (this.#undoStack.length > 0) {
      const updates = (
        JSON.parse(this.#undoStack.pop() ?? '[]') as Array<[DataTableCellReference, DataTableCellData]>
      ).reduce<Array<DataTableCellUpsert>>((acc, [cellRef, { value }]) => {
        const coord = fromCellRef(cellRef);
        if (_notNil(coord)) {
          acc.push({ column_id: coord.column, row_id: coord.row, value });
        }
        return acc;
      }, []);

      const updateCellsPromise = this.updateCells(updates, { snapshot: 'undo' });
      await updateCellsPromise;
      this.validate();
      return updateCellsPromise;
    }
    return Promise.reject(new Error('Nothing to undo'));
  }

  rowHeader(id: DataTableRowApiId): DataTableRow | undefined {
    return this.#rowData?.rows?.get(id);
  }

  async redo(): Promise<DataTableCellUpdateEventDetail> {
    if (this.#redoStack.length > 0) {
      const updates = (
        JSON.parse(this.#redoStack.pop() ?? '[]') as Array<[DataTableCellReference, DataTableCellData]>
      ).reduce<Array<DataTableCellUpsert>>((acc, [cellRef, { value }]) => {
        const coord = fromCellRef(cellRef);
        if (_notNil(coord)) {
          acc.push({ column_id: coord.column, row_id: coord.row, value });
        }
        return acc;
      }, []);

      const updateCellsPromise = this.updateCells(updates, { snapshot: 'redo' });
      await updateCellsPromise;
      this.validate();

      return updateCellsPromise;
    }
    return Promise.reject(new Error('Nothing to redo'));
  }

  async clearSelection(): Promise<void> {
    if (_notNil(this.#selection) && !this.#props.readonly) {
      const cellUpdates: Array<DataTableCellUpsert & { snapshot: boolean }> = [];
      for (let column = this.#selection.topLeft.column; column <= this.#selection.bottomRight.column; column++) {
        for (let row = this.#selection.topLeft.row; row <= this.#selection.bottomRight.row; row++) {
          const idCoordinate = this.fromIndexCoordinate({ column, row });
          if (_notNil(idCoordinate)) {
            cellUpdates.push({
              column_id: idCoordinate.column,
              row_id: idCoordinate.row,
              snapshot: true,
              value: null,
            });
          }
        }
      }

      const fromCoordinate = this.fromIndexCoordinate({
        column: this.#selection.topLeft.column,
        row: this.#selection.topLeft.row,
      });
      const toCoordinate = this.fromIndexCoordinate({
        column: this.#selection.bottomRight.column,
        row: this.#selection.bottomRight.row,
      });

      if (_notNil(fromCoordinate) && _notNil(toCoordinate)) {
        await this.updateCells(cellUpdates, {
          windowClear: {
            startColumnId: fromCoordinate.column,
            startRowId: fromCoordinate.row,
            endColumnId: toCoordinate.column,
            endRowId: toCoordinate.row,
          },
        });
      } else {
        await this.updateCells(cellUpdates);
      }
      this.validate();
    }
  }

  tableValid(): boolean {
    return !this.#cellStateService.hasErrors();
  }

  cellStatusOverview(): Record<DataTableCellStatusType, number> {
    return this.#cellStateService.cellStatusOverview();
  }

  redoEnabled(): boolean {
    return !this.#props.readonly && this.#redoStack.length > 0;
  }

  undoEnabled(): boolean {
    return !this.#props.readonly && this.#undoStack.length > 0;
  }

  rowHeaders(): Array<Omit<DataTableRowHeader, 'displayed'>> {
    return Object.values(this.#rowHeaders).map(({ displayed, ...rest }) => rest);
  }

  rowHeaderWidth(): number {
    return this.displayedRowHeaderIds().length * this.#props.dimensions.cellWidth;
  }

  updateDisplayedRowHeaders(
    displayedRowHeaderIds: Set<DataTableRowHeaderId>,
    options?: DataTableUpdateDisplayedRowHeadersOptions
  ): Promise<void> {
    this.#rowHeaders = Object.values(this.#rowHeaders).reduce(
      (acc, { id, title }) => ({
        ...acc,
        [id]: { id, title, displayed: displayedRowHeaderIds.has(id) },
      }),
      {}
    );
    this.#eventEmitter.dispatchEvent(
      new CustomEvent<DataTableRowHeaderStateChangeEventDetail>(DataTableEvent.RowHeaderStateChange, {
        detail: { displayedRowHeaderIds, rowHeaderWidth: this.rowHeaderWidth() },
      })
    );
    return (options?.persist ?? true)
      ? this.#invokeDataTableSettingsUpdateApi({ display_columns: [...displayedRowHeaderIds] })
      : Promise.resolve();
  }

  displayedRowHeaderTitles(): Array<string> {
    return Object.values(this.#rowHeaders).reduce<Array<string>>(
      (acc, header) => (header.displayed ? [...acc, header.title] : acc),
      []
    );
  }

  displayedRowHeaderIds(): Array<DataTableRowHeaderId> {
    return Object.values(this.#rowHeaders).reduce<Array<DataTableRowHeaderId>>(
      (acc, header) => (header.displayed ? [...acc, header.id] : acc),
      []
    );
  }

  #activateSelection(options?: Pick<DataTableCellSelectionChangeEventDetail, 'content' | 'options'>): void {
    if (!this.#props.readonly && _notNil(this.#selection)) {
      this.#eventEmitter.dispatchEvent(
        new CustomEvent<DataTableCellSelectionChangeEventDetail>(DataTableEvent.CellSelectionChange, {
          detail: {
            source: 'user',
            selection: Object.freeze(this.#selection),
            scrollTo: false,
            activate: true,
            content: options?.content,
            options: options?.options,
          },
        })
      );
    }
  }

  async moveColumn(columnMoveRequest: DataTableColumnMoveRequest): Promise<DataTableMoveColumnResponse> {
    if (this.#props.readonly) {
      return Promise.reject(new Error('Table is readonly'));
    }
    const result = await this.#invokeDataTableColumMoveApi(columnMoveRequest);
    this.#cellStateService.clearRow(result.rows[0].id);

    const updatedColumns = result.columns;
    const updatedRows = result.rows;

    if (_notNil(this.#table)) {
      this.#table.columns = updatedColumns as DataTable['columns'];
      this.#table.rows = updatedRows as DataTable['rows'];
    }

    this.#columnData = DataTableService.#generateInternalColumnData(updatedColumns);
    this.#rowData = DataTableService.#generateInternalRowData(updatedRows);

    this.#eventEmitter.dispatchEvent(
      new CustomEvent<DataTableStateChangeEventDetail>(DataTableEvent.TableStateChange, {
        detail: {
          type: Object.freeze(new Set<DataTableStateChangeType>(['columns'])),
          ids: Object.freeze(new Set(updatedColumns.map(({ id }) => id))),
        },
      })
    );
    this.#eventEmitter.dispatchEvent(new CustomEvent<DataTableStateChangeEventDetail>(DataTableEvent.MoveColumn));

    return result;
  }

  async addColumn(columnAddRequest: DataTableColumnAddRequest): Promise<Array<DataTableColumn>> {
    if (this.#props.readonly) {
      return Promise.reject(new Error('Table is readonly'));
    }
    if (columnAddRequest?.pk?.from) {
      columnAddRequest.pk.from = DateInputUtils.localDateTimeToISODateTime(columnAddRequest.pk.from);
    }
    const result = await this.#invokeDataTableColumAddApi(columnAddRequest);
    const updatedColumns = [...this.columns, ...result];
    if (_notNil(this.#table)) {
      this.#table.columns = updatedColumns as DataTable['columns'];
    }
    this.#columnData = DataTableService.#generateInternalColumnData(updatedColumns);
    this.#eventEmitter.dispatchEvent(
      new CustomEvent<DataTableStateChangeEventDetail>(DataTableEvent.TableStateChange, {
        detail: {
          type: Object.freeze(new Set<DataTableStateChangeType>(['columns'])),
          ids: Object.freeze(new Set(result.map(({ id }) => id))),
        },
      })
    );
    (
      this.#windowScrollRect$.pipe(
        take(1),
        filter((v) => _notNil(v))
      ) as Observable<Readonly<DataTableRectForceLoad>>
    ).subscribe(({ top, bottom }) => {
      const columnIndex = updatedColumns.length - 1;
      this.#cellLoadRectOverride$.next(
        new DataTableRectForceLoad({ top, bottom, left: columnIndex, right: columnIndex, force: true })
      );
    });
    return result;
  }

  async updateColumn(columnUpdateRequest: DataTableColumnUpdateRequest): Promise<DataTableColumn> {
    if (this.#props.readonly) {
      return Promise.reject(new Error('Table is readonly'));
    }
    const result = await this.#invokeDataTableColumnUpdateApi(columnUpdateRequest);
    if (
      ['formula', 'observation', 'measurement', 'timestampBaselineRelative'].includes(result?.type) ||
      _notNil(columnUpdateRequest.locked)
    ) {
      this.#handleSideEffects(
        [columnUpdateRequest.id],
        this.rows.map((r) => r.id)
      );
      this.validate();
    }
    const updatedColumns = [...(this.#columnData?.columns.values() ?? [])].map((existingColumn) =>
      existingColumn.id === result.id ? result : existingColumn
    );
    if (_notNil(this.#table)) {
      this.#table.columns = updatedColumns as DataTable['columns'];
    }
    this.#columnData = DataTableService.#generateInternalColumnData(updatedColumns);
    this.#eventEmitter.dispatchEvent(
      new CustomEvent<DataTableStateChangeEventDetail>(DataTableEvent.TableStateChange, {
        detail: {
          type: Object.freeze(new Set<DataTableStateChangeType>(['columns'])),
          ids: Object.freeze(new Set([result.id])),
        },
      })
    );
    this.#eventEmitter.dispatchEvent(
      new CustomEvent<DataTableColumnUpdateEventDetail>(DataTableEvent.ColumnUpdate, { detail: result })
    );
    return result;
  }

  async addRow(rowAddRequest: DataTableRowAddRequest): Promise<Array<DataTableRow>> {
    if (this.#props.readonly) {
      return Promise.reject(new Error('Table is readonly'));
    }
    const result = await this.#invokeDataTableRowAddApi(rowAddRequest);
    const updatedRows = [...this.rows, ...result];
    if (_notNil(this.#table)) {
      this.#table.rows = updatedRows as DataTable['rows'];
    }
    this.#rowData = DataTableService.#generateInternalRowData(updatedRows);
    this.#eventEmitter.dispatchEvent(
      new CustomEvent<DataTableStateChangeEventDetail>(DataTableEvent.TableStateChange, {
        detail: {
          type: Object.freeze(new Set<DataTableStateChangeType>(['rows'])),
          ids: Object.freeze(new Set(result.map((r) => r.id))),
        },
      })
    );
    (
      this.#windowScrollRect$.pipe(
        take(1),
        filter((v) => _notNil(v))
      ) as Observable<Readonly<DataTableRect>>
    ).subscribe(({ left, right }) => {
      const bottom = updatedRows.length - 1;
      const top = bottom - (rowAddRequest.animals.length - 1);
      this.#cellLoadRectOverride$.next(new DataTableRect({ top, bottom, left, right }));
    });
    return result;
  }

  async removeColumn(columnId: DataTableColumnApiId): Promise<void> {
    if (this.#props.readonly) {
      return Promise.reject(new Error('Table is readonly'));
    }
    const { effects, dependencies } = await this.#invokeDataTableColumRemoveApi(columnId);

    const column: DataTableColumn | undefined = this.columnById(columnId);
    const removableIds: Set<DataTableColumnApiId> | undefined =
      column?.type === 'measurement' ? new Set(column?.relations) : new Set();

    removableIds.add(columnId);
    this.#handleSideEffects(effects, []);

    this.#cellStateService.clearColumn(...removableIds);
    let updatedColumns = this.columns.filter(({ id }) => !removableIds.has(id));

    dependencies.forEach((dependantColumnId) => {
      this.#cellStateService.clearColumn(dependantColumnId);
      updatedColumns = updatedColumns.filter(({ id }) => id !== dependantColumnId);
    });
    if (_notNil(this.#table)) {
      this.#table.columns = updatedColumns as DataTable['columns'];
    }

    this.#columnData = DataTableService.#generateInternalColumnData(updatedColumns);
    const emitUndoStateChange = this.undoEnabled() || this.redoEnabled();
    this.#undoStack = [];
    this.#redoStack = [];
    if (emitUndoStateChange) {
      this.#eventEmitter.dispatchEvent(
        new CustomEvent(DataTableEvent.UndoRedoStateChange, {
          detail: { redoEnabled: false, undoEnabled: false },
        })
      );
    }
    this.#eventEmitter.dispatchEvent(
      new CustomEvent<DataTableStateChangeEventDetail>(DataTableEvent.TableStateChange, {
        detail: {
          type: Object.freeze(new Set<DataTableStateChangeType>(['columns'])),
          ids: Object.freeze(new Set([columnId])),
        },
      })
    );
  }

  async removeRow(rowId: DataTableRowApiId): Promise<void> {
    if (this.#props.readonly) {
      return Promise.reject(new Error('Table is readonly'));
    }
    await this.#invokeDataTableRowRemoveApi(rowId);
    this.#cellStateService.clearRow(rowId);
    const updatedRows = this.rows.filter(({ id }) => id !== rowId);
    if (_notNil(this.#table)) {
      this.#table.rows = updatedRows as DataTable['rows'];
    }
    this.#rowData = DataTableService.#generateInternalRowData(updatedRows);
    const emitUndoStateChange = this.undoEnabled() || this.redoEnabled();
    this.#undoStack = [];
    this.#redoStack = [];
    if (emitUndoStateChange) {
      this.#eventEmitter.dispatchEvent(
        new CustomEvent(DataTableEvent.UndoRedoStateChange, {
          detail: { redoEnabled: false, undoEnabled: false },
        })
      );
    }
    this.#eventEmitter.dispatchEvent(
      new CustomEvent<DataTableStateChangeEventDetail>(DataTableEvent.TableStateChange, {
        detail: {
          type: Object.freeze(new Set<DataTableStateChangeType>(['rows'])),
          ids: Object.freeze(new Set([rowId])),
        },
      })
    );
    this.validate();
  }

  static #generateInternalColumnData(columns: Array<DataTableColumn>): InternalColumnData {
    return columns.reduce<InternalColumnData>(
      (acc, column, columnIndex) => {
        acc.idToIndex.set(column.id, columnIndex);
        acc.indexToId.set(columnIndex, column.id);
        acc.columns.set(column.id, column);
        return acc;
      },
      {
        idToIndex: new Map(),
        indexToId: new Map(),
        columns: new Map(),
      }
    );
  }

  static #generateInternalRowData(rows: Array<DataTableRow>): InternalRowData {
    return rows.reduce<InternalRowData>(
      (acc, row, rowIndex) => {
        acc.idToIndex.set(row.id, rowIndex);
        acc.indexToId.set(rowIndex, row.id);
        acc.rows.set(row.id, row);
        return acc;
      },
      { idToIndex: new Map(), indexToId: new Map(), rows: new Map() }
    );
  }

  #handleSideEffects(columnIds: Array<DataTableColumnApiId>, rowIds: Array<DataTableRowApiId>) {
    of({ columnIds: new Set(columnIds), rowIds: new Set(rowIds) })
      .pipe(
        takeUntil(this.#destroy$),
        mergeMap(({ columnIds, rowIds }) => {
          const [fromRowIndex, toRowIndex] = Array.from(rowIds).reduce(
            (acc, rowId) => {
              const rowIndex = this.#rowData?.idToIndex.get(rowId);
              if (acc[0] > (rowIndex ?? Infinity)) {
                acc[0] = rowIndex ?? Infinity;
              }
              if (acc[1] < (rowIndex ?? 0)) {
                acc[1] = rowIndex ?? 0;
              }
              return acc;
            },
            [this.#rowData?.rows.size ?? Infinity, 0]
          );
          const includeAlerts = Array.from(columnIds).some(
            (columnId) => this.columnById(columnId)?.type === 'measurement'
          );
          if (includeAlerts) {
            columnIds.forEach((columnId) => {
              if (this.columnById(columnId)?.type === 'measurement') {
                (rowIds.size > 0 ? rowIds : [...(this.#rowData?.idToIndex.keys() ?? [])]).forEach((rowId) => {
                  this.#cellAlertService.delete({ column: columnId, row: rowId });
                });
              }
            });
          }

          const fromRowId = this.#rowData?.indexToId.get(fromRowIndex) ?? this.#rowData?.indexToId.get(0);
          const toRowId =
            this.#rowData?.indexToId.get(toRowIndex) ?? this.#rowData?.indexToId.get(this.#rowData?.rows.size ?? 0);
          if (_notNil(fromRowId) && _notNil(toRowId)) {
            return forkJoin(
              [...columnIds].reduce<Array<Observable<Array<DataTableApiLoadCellResponse>>>>((acc, columnId) => {
                acc.push(
                  of({
                    from: { column: columnId, row: fromRowId },
                    to: { column: columnId, row: toRowId },
                    include_alerts: includeAlerts,
                  }).pipe(
                    this.#props.apiService.loadCells(this),
                    catchError((err) => {
                      ExceptionHandler.captureException(
                        new InVivoError('Could not load cells (side effect)', {
                          cause: err,
                          slug: 'datatable-cells-side-effect-load',
                        })
                      );
                      return EMPTY;
                    })
                  )
                );
                return acc;
              }, [])
            );
          }
          return EMPTY;
        })
      )
      .subscribe((cellData) => {
        const { cellUpdateEvent, cellAlertUpdateEvent } = cellData.flat(1).reduce<{
          cellUpdateEvent: DataTableCellUpdateEventDetail;
          cellAlertUpdateEvent: DataTableCellAlertUpdateEventDetail;
        }>(
          (acc, { column_id, row_id, cell: { value }, updated_at, alerts }) => {
            const ref = toCellRef({ column: column_id, row: row_id });
            acc.cellAlertUpdateEvent.cellAlerts[ref] = this.#cellAlertService.ingest(
              {
                column: column_id,
                row: row_id,
              },
              alerts
            );
            if (this.#cellData.get(ref)?.value !== value) {
              this.#cellData.set(ref, { value, updated_at });
              acc.cellUpdateEvent[ref] = { value, updated_at };
            }
            return acc;
          },
          { cellUpdateEvent: {}, cellAlertUpdateEvent: { cellAlerts: {}, trigger: 'cell-load' } }
        );
        this.#eventEmitter.dispatchEvent(new CustomEvent(DataTableEvent.CellUpdate, { detail: cellUpdateEvent }));
        this.#eventEmitter.dispatchEvent(
          new CustomEvent(DataTableEvent.CellAlertUpdate, { detail: cellAlertUpdateEvent })
        );
      });
  }

  #isColumnReadonly(column: Nullable<DataTableColumn>): boolean {
    if (_isNil(column) || column?.read_only === true) {
      return true;
    }
    if (column.type === 'measurement' && (column.measurement.is_calculated ?? false)) {
      return true;
    }
    return isColumnTypeReadonly(column.type);
  }

  featureFlagEnabled(featureFlag: keyof DataTableFeatureFlags): boolean {
    return this.#props.featureFlags?.[featureFlag] ?? false;
  }
}
