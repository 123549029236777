// @ts-nocheck: converted from JS

import { AnimalFiltersEntity, validateManageAnimalsSelection } from '@/components/Colony/Animals/Animals.utils';
import GroupLabel from '@/components/UI/GroupLabel';
import Link from '@/components/UI/Link';
import NumericDelta from '@/components/UI/NumericDelta/NumericDelta';
import type { Column, FilterOption } from '@/components/UI/Table/TableComponent.model';
import { FilterTypes as filterType } from '@/constants/FilterTypes';
import { isDead } from '@/constants/utils';
import { calculateAge, formatNumber, renderMetadataValue, successToast } from '@/helpers';
import { _isEmpty, _isNil, _isNotEmpty, _notNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { CageApiId } from '@/model/Cage.model';
import type { ID } from '@/model/Common.model';
import type { MetadataFieldTypes } from '@/model/Metadata.model';
import type { PresetCalculation } from '@/model/PresetCalculation.model';
import type { BulkSamplesForm } from '@/model/Sample.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import Http from '@/support/http';
import { api as apiRoute, web as webRoute } from '@/support/route';
import { DateUtils } from '@/utils/Date.utils';
import { modalAction } from '@/utils/modal';
import AnimalAlerts from './AnimalAlerts';

export const animalTableColumns = (
  studyId,
  calculations,
  columns,
  changePercentage,
  metadataOptions,
  features
): Array<Column> => {
  const calculationColumns = studyCalculationColumns(calculations, columns, changePercentage);
  const metadataColumns = animalMetadataColumns(metadataOptions, columns);

  return [
    {
      id: 'number',
      Header: 'No.',
      width: 75,
      accessor: 'number',
      isVisible: columns['number'],
    },
    {
      id: 'catalog',
      Header: 'Animal No.',
      accessor: 'catalog',
      isVisible: columns['catalog'],
    },
    {
      id: 'name',
      Header: 'Name',
      accessor: 'name',
      isVisible: columns['id'],
      Cell: ({
        apiTable: { fetchTableData },
        row: {
          original: { id: animalId, name, alerts, cage },
        },
      }) => (
        <div className="flex justify-between items-center w-100">
          <Link
            to={webRoute('animals.show', {
              id: studyId,
              subject_id: animalId,
            })}
            className="link blue"
          >
            {name}
          </Link>
          {!_isEmpty(alerts) && (
            <AnimalAlerts
              animalName={name}
              cageName={cage?.name}
              alerts={alerts}
              reloadAlerts={() => fetchTableData()}
            />
          )}
        </div>
      ),
    },
    {
      id: 'tail',
      Header: 'Tail ID',
      accessor: 'alt_ids.tail',
      isVisible: columns['tail'],
    },
    {
      id: 'donor',
      Header: 'Donor ID',
      accessor: 'alt_ids.donor',
      isVisible: columns['donor'],
    },
    {
      id: 'ear',
      Header: 'Ear ID',
      accessor: 'alt_ids.ear',
      isVisible: columns['ear'],
    },
    {
      id: 'tag',
      Header: 'Tag ID',
      accessor: 'alt_ids.tag',
      isVisible: columns['tag'],
    },
    {
      id: 'cage',
      Header: 'Cage',
      accessor: 'cage.name',
      isVisible: columns['cage'],
      Cell: ({ row: { original } }) => isDead(original) || '—',
    },
    ...calculationColumns,
    {
      id: 'study_group',
      Header: 'Study group',
      accessor: 'study_group.no',
      isVisible: columns['study_group'],
      width: 200,
      Cell: ({
        row: {
          original: { study_group: studyGroup },
        },
      }) => (studyGroup ? <GroupLabel group={studyGroup} /> : '—'),
    },
    {
      id: 'tracking_started_at',
      Header: 'Tracking date',
      accessor: 'tracking_started_at',
      isVisible: columns['tracking_started_at'],
      Cell: ({
        row: {
          original: { tracking_started_at: trackingStartedAt },
        },
      }) => <>{DateUtils.renderDate(trackingStartedAt, { defaultResponse: '' })}</>,
    },
    {
      id: 'disease_inducted_at',
      Header: 'Disease Induction Date',
      accessor: 'disease_inducted_at',
      isVisible: columns['disease_inducted_at'],
      Cell: ({ row: { original } }) => (
        <>{DateUtils.renderDate(original.disease_inducted_at, { defaultResponse: '' })}</>
      ),
    },
    {
      id: 'sex',
      Header: 'Sex',
      accessor: 'sex',
      sortBy: 'sex',
      isVisible: columns['sex'],
      width: 75,
      Cell: ({
        row: {
          original: { sex },
        },
      }) => sex?.toUpperCase?.() ?? '—',
    },
    {
      id: 'dob',
      Header: 'DOB',
      accessor: 'dob',
      isVisible: columns['dob'],
      Cell: ({
        row: {
          original: { dob },
        },
      }) => <>{DateUtils.renderDate(dob, { defaultResponse: '-' })}</>,
    },
    {
      id: 'age',
      Header: 'Age',
      accessor: 'ageValue',
      isVisible: columns['age'],
      sortDisabled: true,
      Cell: ({
        row: {
          original: { terminated_at: terminatedAt, dob },
        },
      }) => (_notNil(dob) ? calculateAge(dob, terminatedAt) : '—'),
    },
    {
      id: 'species',
      Header: 'Species',
      accessor: 'species_name',
      isVisible: columns.species ?? false,
      sortDisabled: true,
    },
    {
      id: 'strain',
      Header: 'Strain',
      accessor: 'strain_name',
      isVisible: columns.strain ?? false,
      sortDisabled: true,
    },
    ...metadataColumns,
  ];
};

export const studyCalculationColumns = (calculations, columns, changePercentage) =>
  calculations.map(({ id, name, unit }) => ({
    id,
    accessor: `latestMeasurement.${id}.value`,
    Header: `${name} ${unit !== null && unit ? ` (${unit})` : ''}`,
    isVisible: columns[id],
    width: 250,
    Cell: ({
      row: {
        original: { latestMeasurement },
      },
    }) => {
      const measurement = latestMeasurement?.[id];
      if (_notNil(measurement)) {
        const { value, value_change: valueChange, value_change_from_first: valueChangeFromFirst } = measurement;

        const valueChangeToUse = changePercentage === 'value_change_from_first' ? valueChangeFromFirst : valueChange;

        const hidePercentageChange = (valueChangeToUse === 0 && _isNil(valueChange)) || _isNil(valueChange);

        return (
          <>
            {formatNumber(value)}
            <NumericDelta
              value={value}
              valueChange={valueChangeToUse}
              className="f7"
              percentageOnly={true}
              hidePercentageChange={hidePercentageChange}
            />
          </>
        );
      }
      return '—';
    },
  }));

export const animalMetadataColumns = (metadataOptions, columns) =>
  metadataOptions?.map(({ id, name }) => ({
    id,
    Header: name,
    isVisible: columns[id],
    sortDisabled: true,
    Cell: ({
      row: {
        original: { metadata },
      },
    }) => {
      const meta = metadata.find(({ glossary_id }) => glossary_id === id);
      return _notNil(meta) ? renderMetadataValue(meta) : '';
    },
  })) ?? [];

export const animalBulkActions = (
  selectedFlatRows: { original: Animal }[],
  resetTableState,
  study,
  dispatch,
  history,
  features
) => {
  const { study_groups: groups, id: studyId } = study;
  const atunesIntegrationEnabled = features.atunes_colony_management_integration === true;

  const { openModal, closeModal } = modalAction(dispatch);

  const handleCallback = (text) => {
    closeModal();
    resetTableState();
    if (text) {
      successToast(text);
    }
  };

  const animals = selectedFlatRows.map((row) => row.original);

  const sectionOne = [];
  const sectionTwo = [];

  const terminatedAnimals = animals.some((subject) => subject.terminated_at || _isNil(subject.cage));
  sectionOne.push({
    name: 'Assign identifiers',
    tooltip: 'All selected animals must be alive and in a cage to assign identifiers.',
    disabled: terminatedAnimals,
    action: () => {
      return openModal('ASSIGN_IDENTIFIERS', {
        animals: animals.filter((animal) => !animal.terminated_at),
        handleCallback,
      });
    },
  });

  if (study.settings?.id !== null) {
    sectionOne.push({
      name: 'Randomize to groups',
      action: () => {
        dispatch({ type: 'SET_SELECTED_SUBJECTS', selectedSubjects: animals });
        history.push(webRoute('studies.randomization', { id: study.id }));
      },
    });
  }

  const canAddToGroup = (selectedSubjects, groups) =>
    !(selectedSubjects.some((subject) => subject.study_group || subject.terminated_at) || _isEmpty(groups));
  sectionOne.push({
    name: 'Add to group (manual)',
    disabled: !canAddToGroup(animals, groups),
    tooltip: _isEmpty(groups)
      ? 'Study groups have not yet been created.'
      : 'One or more of the selected animals is already in a group or deceased.',
    action: () => {
      openModal('ASSIGN_SUBJECT_TO_GROUP', {
        subject: animals,
        popMax: study.max_subjects,
        closeModal,
        handleCallback,
      });
    },
  });

  if (animals.some((subject) => subject?.study_group)) {
    sectionTwo.push({
      name: 'Remove from group',
      action: () =>
        openModal('REMOVE_SUBJECT_FROM_GROUP', {
          subject: animals,
          closeModal,
          handleCallback,
        }),
    });
  }

  sectionOne.push({
    name: 'Set tracking date',
    action: () =>
      openModal('SET_TRACKING_DATE', {
        subject: animals,
        closeModal,
        handleCallback,
      }),
  });

  sectionOne.push({
    name: 'Set disease induction date',
    action: () => {
      openModal('UPDATE_DISEASE_INDUCTION_DATE', {
        studyApiId: study.api_id,
        animalApiIds: animals.map((a) => a.api_id),
        handleCallback,
      });
    },
  });

  sectionOne.push(
    {
      name: 'Update sex',
      action: () =>
        openModal('UPDATE_SEX', {
          subject: animals,
          closeModal,
          handleCallback,
        }),
    },
    {
      name: 'Update DOB',
      action: () =>
        openModal('UPDATE_DOB', {
          subject: animals,
          closeModal,
          handleCallback,
        }),
    }
  );

  const canRecage = (selectedSubjects) => !selectedSubjects.some((subject) => subject.terminated_at);
  sectionOne.push({
    name: 'Recage',
    disabled: !canRecage(animals),
    tooltip: 'All selected animals must be alive in order to perform recaging.',
    action: () => {
      dispatch({ type: 'SET_SELECTED_SUBJECTS', selectedSubjects: animals });
      history.push(webRoute('studies.recaging', { studyId: study.id }));
    },
  });

  const canMoveToStudy = (selectedSubjects) => selectedSubjects.some((subject) => _isNotEmpty(subject.cage_id));

  sectionOne.push({
    name: 'Move to another study',
    disabled: !canMoveToStudy(animals),
    tooltip: 'Selected animals must be alive to move to another study.',
    action: () => {
      openMoveToStudy();
    },
  });

  const openMoveToStudy = () =>
    openModal('MOVE_TO_STUDY', {
      subjects: animals,
      handleCallback: openMoveToStudySelect,
    });

  const openMoveToStudySelect = (cageApiIds: Array<CageApiId>, currentStudy: Study, animalCount: number) =>
    openModal('MOVE_TO_STUDY_SELECT_STUDY', {
      cageApiIds,
      currentStudy,
      animalCount,
      animals,
      handleCallback: openMoveToStudyConfirmation,
    });

  const openMoveToStudyConfirmation = (
    cageApiIds: Array<CageApiId>,
    currentStudy: Study,
    selectedStudy: Study,
    animalCount: number
  ) => {
    openModal('MOVE_TO_STUDY_CONFIRMATION', {
      cageApiIds,
      currentStudy,
      selectedStudy,
      animalCount,
      handleCallback: openMoveToStudySuccess,
    });
  };

  const openMoveToStudySuccess = (currentStudy: Study, newStudy: Study, animalCount: number) =>
    openModal('MOVE_TO_STUDY_SUCCESS', {
      currentStudy,
      newStudy,
      animalCount,
      handleCallback,
    });

  const openCollectSamples = () =>
    openModal('COLLECT_SAMPLES', {
      handleCallback: viewSampleDetails,
    });

  const viewSampleDetails = (sampleDetails: BulkSamplesForm, sampleMetadata: Array<MetadataItem>) => {
    openModal('VIEW_SAMPLE_DETAILS', {
      studyId,
      subjects: animals,
      sampleDetails,
      metadata: sampleMetadata,
      handleCallback: (finished: boolean) => {
        closeModal();
        if (finished) {
          history.push(webRoute('studies.samples', { id: study.id }));
        } else {
          openCollectSamples();
        }
      },
    });
  };

  sectionOne.push({
    name: 'Collect samples',
    action: () => {
      openCollectSamples();
    },
  });

  const defaultSelectionProps = {
    hasWritePermission: false,
    isNotDeceased: true,
    hasActiveStudy: false,
    hasIdenticalStudy: false,
  };

  sectionOne.push({
    name: 'Edit Animal details',
    key: 'edit_details',
    ...validateManageAnimalsSelection(defaultSelectionProps, animals, 1000),
    action: () => {
      // Sets the selected animals to session, in order to prevent losing context on refresh
      sessionStorage.setItem('selectedColonyAnimals', JSON.stringify(animals));
      history.push(webRoute('studies.animals.edit', { id: studyId }));
    },
  });

  if (!atunesIntegrationEnabled) {
    if (animals.every((subject) => subject?.terminated_at != null)) {
      sectionTwo.push({
        name: 'Unmark as deceased',
        action: () =>
          openModal('UNDO_EUTHANISE_SUBJECT', {
            subjects: animals,
            closeModal,
            handleCallback,
          }),
      });
    } else {
      sectionTwo.push({
        name: 'Mark as deceased',
        ...validateManageAnimalsSelection(defaultSelectionProps, animals),
        action: () =>
          openModal('BULK_EUTHANISE_SUBJECTS', {
            subjects: animals,
            closeModal,
            handleCallback,
          }),
      });
    }
  }
  if (!atunesIntegrationEnabled) {
    const handleDelete = async (reasonForDelete?: string) => {
      try {
        await Http.delete(apiRoute('animals.destroyMany', { study: study.id }), {
          data: {
            animal_ids: animals.map((s) => s.id),
            reason_for_change: reasonForDelete,
          },
        });
        return handleCallback('Successfully deleted!');
      } catch (error) {
        successToast('There was a problem deleting animals, please try again later.');
      }
    };

    sectionTwo.push({
      name: 'Delete',
      action: () =>
        openModal('CONFIRM_DELETE_SUBJECTS', {
          subject: animals,
          closeModal,
          onClick: handleDelete,
        }),
      className: 'red',
    });
  }

  return [sectionOne, sectionTwo];
};

export const animalFilterOptions = (
  calculations: Array<PresetCalculation>,
  studyGroups: Array<TreatmentGroup> | undefined,
  studyId: ID,
  observationOptions: Array<string> = [],
  animalFilters: AnimalMetadataFilter
) => {
  const filters: Array<FilterOption> = [
    ...calculations.map(({ id, name }) => ({
      name,
      value: id,
      type: 'measurements',
      operations: [
        {
          name: 'greater than',
          value: filterType.gt,
        },
        {
          name: 'less than',
          value: filterType.lt,
        },
      ],
    })),
    {
      value: 'cage',
      name: 'Cage name',
      operations: [
        {
          name: 'is equal to',
          value: filterType.eq,
          options: [],
        },
        {
          name: 'is not equal to',
          value: filterType.ne,
          options: [],
        },
        {
          name: 'contains',
          value: filterType.contains,
        },
        {
          name: 'does not contain',
          value: filterType.not_contains,
        },
      ],
      fetchFromApi: {
        placeholder: 'Search cages',
        url: 'studies.cages.list',
        params: {
          studyId,
        },
        queryParams: {
          sort: 'catalog',
          order: 'asc',
          perPage: -1,
        },
      },
    },
    {
      value: 'deceased',
      name: 'Living status',
      operations: [
        {
          name: 'is equal to',
          value: filterType.eq,
          options: [{ name: 'Alive', value: 'null' }],
        },
        {
          name: 'is not equal to',
          value: filterType.ne,
          options: [{ name: 'Alive', value: 'null' }],
        },
      ],
    },
    {
      value: 'sex',
      name: 'Sex',
      operations: [
        {
          name: 'is equal to',
          value: filterType.eq,
          options: [
            { value: 'm', name: 'Male' },
            { value: 'f', name: 'Female' },
          ],
        },
      ],
    },
    {
      value: 'alerts',
      name: 'Alert',
      operations: [
        {
          name: 'is',
          value: filterType.eq,
          options: [
            { value: 'alert_unresolved', name: 'Unresolved' },
            { value: 'alert_resolved', name: 'Resolved' },
          ],
        },
      ],
    },
  ];

  if (_isNotEmpty(observationOptions)) {
    filters.push({
      value: 'observation',
      name: 'Observation',
      operations: [
        {
          name: 'is equal to',
          value: filterType.eq,
          options: observationOptions.map((value) => ({ value, name: value })),
        },
        {
          name: 'is not equal to',
          value: filterType.ne,
          options: observationOptions.map((value) => ({ value, name: value })),
        },
        {
          name: filterType.IS_NULL,
          value: filterType.IS_NULL,
        },
        {
          name: filterType.IS_NOT_NULL,
          value: filterType.IS_NOT_NULL,
        },
      ],
    });
  }

  if (_isNotEmpty(studyGroups)) {
    filters.push({
      value: 'study_group',
      name: 'Study Group',
      operations: [
        {
          name: 'is equal to',
          value: filterType.eq,
          options: [],
        },
        {
          name: 'is not equal to',
          value: filterType.ne,
          options: [],
        },
        {
          name: filterType.IS_NULL,
          value: filterType.IS_NULL,
        },
        {
          name: filterType.IS_NOT_NULL,
          value: filterType.IS_NOT_NULL,
        },
      ],
      fetchFromApi: {
        placeholder: 'Search groups',
        url: 'studies.groups.list',
        params: {
          id: studyId,
        },
        queryParams: {
          perPage: -1,
        },
      },
    });
  }

  if (_isNotEmpty(animalFilters)) {
    filters.push(...addAnimalFilters(animalFilters));
  }

  return filters;
};

export const addAnimalFilters = (filters: AnimalFiltersEntity) => {
  const metadata =
    filters?.metadata?.map((item: { id: string; name: string; field_type: MetadataFieldTypes }) => {
      switch (item.field_type) {
        case 'date':
          return {
            value: item.id,
            name: item.name,
            operations: [
              {
                name: filterType.contains,
                value: filterType.eq_date,
              },
              {
                name: filterType.not_contains,
                value: filterType.not_eq_date,
              },
            ],
          };

        default:
          return {
            value: item.id,
            name: item.name,
            operations: [
              {
                name: filterType.contains,
                value: filterType.contains,
              },
              {
                name: filterType.not_contains,
                value: filterType.not_contains,
              },
            ],
          };
      }
    }) ?? [];

  const strainsAndSpecies =
    filters?.fields?.reduce((acc, field: AnimalFilter) => {
      if (field.name === 'Strain' || field.name === 'Species') {
        acc.push({
          value: field.value,
          name: field.name,
          operations: [
            {
              name: filterType.eq,
              value: filterType.eq,
              options: field.options,
            },
            {
              name: filterType.ne,
              value: filterType.ne,
              options: field.options,
            },
          ],
        });
      }
      return acc;
    }, []) ?? [];

  return [...metadata, ...strainsAndSpecies];
};
