import { PickRequired } from '@/model/Common.model.ts';
import { ApiSuccessResponse } from '@/support/ApiService.ts';
import { _notNil } from '@/littledash.ts';

export const isTaskExecutionEnabled = (
  task: PickRequired<ApiSuccessResponse<'GET /api/v1/tasks/{taskId}'>['body'], 'animals'> | null,
  isWriteUser: boolean
): boolean => {
  return (
    _notNil(task?.type) &&
    _notNil(task?.status) &&
    isWriteUser &&
    task.type !== 'other' &&
    task.status !== 'done' &&
    (task.study?.active ?? false)
  );
};
