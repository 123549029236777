import Loading from '@/components/Loading';
import type { RequiredMetadataFieldWithValue } from '@/components/Metadata/Fields';
import Banner from '@/components/UI/Banner';
import Button from '@/components/UI/Button';
import { formatNumberComingIn, successToast } from '@/helpers';
import { _isEmpty, _isNotEmpty, _notNil, _toRecord } from '@/littledash';
import type { MetadataField, MetadataFieldWithValue } from '@/model/Metadata.model';
import { useFetchCollection } from '@/support/Hooks/fetch';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { type FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import type { Dispatch } from 'redux';
import { UseFormProvider } from '../../Settings.utils';
import RenderMetadata from './RenderMetadata';
import { StudyFormMetaDetails, StudyFormSection } from '@/model/StudyForm.model';
import { addMetadataFields } from './Metadata.utils';
import { validMultiSelectValue } from '@/components/Studies/Create/Show.utils';
import { BENCHLING_INTEGRATION_META_SLUG } from '@/helpers.constants.tsx';

export interface MetadataProps {
  state: { study: { metadata: Array<RequiredMetadataFieldWithValue>; studyForm: StudyFormMetaDetails } };
  dispatch: Dispatch;
  reloadStudy: () => void;
}

const Metadata: FC<MetadataProps> = ({ state, dispatch, reloadStudy }) => {
  const [newMetadataFields, setNewMetadataFields] = useState<Array<RequiredMetadataFieldWithValue>>([]);
  const [metadataSections, setMetadataSections] = useState<Array<StudyFormSection>>([]);
  const modalDispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const { disable_add_study_metadata } = useSelector(
    ({ team }: { team: { features: { disable_add_study_metadata: boolean } } }) => ({
      disable_add_study_metadata: team?.features?.disable_add_study_metadata ?? false,
    })
  );

  const {
    collection: metadataGlossary,
    collectionLoading: metadataGlossaryLoading,
    fetchCollection: fetchMetadataGlossary,
  } = useFetchCollection<MetadataField>({
    collectionType: 'metadataGlossary',
    queryParams: { filter_type: 'study_meta' },
  });

  const metadataGlossaryWithoutBenchlingFolder = (metadataGlossary ?? []).filter(
    (meta) => meta.slug !== BENCHLING_INTEGRATION_META_SLUG
  );

  const onSubmit = (data: { id: string; metadata: Array<MetadataFieldWithValue> }) => {
    const { metadata } = data;
    const mappedMetadata = Object.values(metadata ?? []).reduce<Array<MetadataFieldWithValue>>((acc, meta) => {
      if (meta) {
        if (
          (meta.field_type === 'multi_select' || meta.field_type === 'lookup_multi_select') &&
          !validMultiSelectValue(meta.value)
        ) {
          return acc;
        }
        if (meta.field_type === 'numeric') {
          meta.value = formatNumberComingIn(meta.value);
        }
        acc.push(meta);
      }
      return acc;
    }, []);

    const payload = {
      metadata: mappedMetadata,
    };

    // Axios returns in combination with our response
    // layering can be a bunch of nonsense
    Http.patch<{ data: { metas: { data: Array<MetadataFieldWithValue> } } }>(apiRoute('studies.update', id), payload)
      .then(() => {
        successToast('Successfully updated study.');
        fetchMetadataGlossary();
        reloadStudy();
      })
      .catch((error) => {
        dispatch({ type: 'SET_API_ERROR', data: error });
      });
  };

  useEffect(() => {
    const metadataSections =
      state.study?.studyForm?.sections?.reduce<Array<StudyFormSection>>((acc, section, i) => {
        // This isn't great to read but this is to remove an empty section in Study Settings
        // in the event the section only contains the Benchling Integration Folder ID, which now has its own section
        const isSingularBenchlingIntegrationSection =
          section.metadata?.some(({ info }) => info?.slug === BENCHLING_INTEGRATION_META_SLUG) &&
          section.metadata?.length === 1;
        if (section.type === 'custom' && !isSingularBenchlingIntegrationSection) {
          acc.push({ ...section, default_section_number: i, metadataFields: [] });
        }
        return acc;
      }, []) ?? [];
    if (!metadataSections?.find((section) => section.name === 'Metadata')) {
      metadataSections.push({
        name: 'Metadata',
        metadata: [],
        metadataFields: [],
        active: true,
        default_section_number: metadataSections.length,
        type: 'custom',
        id: 0,
        created_at: '',
      });
    }
    state.study?.metadata?.forEach((meta) => {
      const section = metadataSections?.find((section) =>
        section.metadata?.find((m) => m.info.id === meta.glossary_id)
      );
      if (_notNil(section?.metadataFields)) {
        section.metadataFields.push(meta);
      } else {
        metadataSections[metadataSections.length - 1]?.metadataFields?.push(meta);
      }
    });
    setMetadataSections(metadataSections);
  }, [state.study?.studyForm, state.study?.metadata]);

  const openModal = () => {
    modalDispatch({
      type: 'OPEN_MODAL',
      modal: 'ADD_STUDY_METADATA',
      props: {
        currentMetadata: _toRecord(state.study.metadata, 'glossary_id'),
        selectedMetadata: newMetadataFields,
        metadataGlossary: metadataGlossaryWithoutBenchlingFolder,
        onSubmit: (newFields: Array<RequiredMetadataFieldWithValue>) =>
          addMetadataFields(newFields, metadataSections, setMetadataSections, setNewMetadataFields, dispatch),
      },
    });
  };

  const metadataSectionsEmpty = useMemo(() => {
    const metaSectionsEmpty = metadataSections.every(({ metadataFields }) => _isEmpty(metadataFields));
    const sectionWithSlug = metadataSections.find((section) =>
      section?.metadata?.find((meta) => meta.info.slug === BENCHLING_INTEGRATION_META_SLUG)
    );
    return metaSectionsEmpty || (metaSectionsEmpty && sectionWithSlug?.metadataFields?.length === 1);
  }, [metadataSections, newMetadataFields]);

  return metadataGlossaryLoading ? (
    <Loading />
  ) : (
    <UseFormProvider state={state} dispatch={dispatch} overrideOnSubmit={onSubmit} unregister={true}>
      <div data-testid="metadata-settings__container">
        <div className="mb4 mw7 flex justify-between">
          <h3 className="fw5 f5 mt3 pb2" data-testid="metadata-settings__title">
            Metadata
          </h3>
          {!disable_add_study_metadata ? (
            <Button
              pill
              paleBlue
              icon="add_new"
              className="mr3"
              onClick={openModal}
              testId="metadata-settings__add-metadata--button"
            >
              Add Metadata
            </Button>
          ) : null}
        </div>
        <div data-testid="metadata-settings__form-container">
          {metadataSectionsEmpty ? (
            <div className="ui-card mw7 pa4">
              <Banner info dismiss={false} className="mw6 mb3">
                <h3 className="normal lh-title f5 pb2" data-testid="metadata-settings__empty-banner--title">
                  No metadata is available for this study
                </h3>
                <p className="f6 lh-copy">
                  No metadata rules for this study have been setup, this is set in the Team Glossary section. Contact a
                  team admin to resolve this.
                </p>
              </Banner>
            </div>
          ) : (
            metadataSections.map((section) =>
              _isNotEmpty(section.metadataFields) ? (
                <div key={section.name} className="ui-card mw7 mb3">
                  <div className="bb b--moon-gray">
                    <p className="pa3 black f5 fw5">{section.name}</p>
                  </div>
                  <div className="ph4 pt3 pb4">
                    <RenderMetadata metadata={section.metadataFields ?? []} />
                  </div>
                </div>
              ) : null
            )
          )}
        </div>
      </div>
    </UseFormProvider>
  );
};

export default Metadata;
