import { toCellRef } from '@/components/UI/DataTable/DataTable.util';
import type { components } from '@/generated/internal-api/openapi-types';
import type { AnimalAlertV1 } from '@/model/Alert.model';
import type { DataTableV1 } from '@/model/DataTable.model';
import { RouteService } from '@/support/RouteService';
import { mergeMap, Observable, switchMap } from 'rxjs';
import type {
  DataTableApiLoadCellResponse,
  DataTableApiService as IDataTableApiService,
  DataTableApiServiceOperation,
  DataTableApiServiceProps,
  DataTableCellReference,
  DataTableCellSelection,
  DataTableCellUpsert,
  DataTableCellUpsertResponse,
  DataTableColumn,
  DataTableColumnAddRequest,
  DataTableColumnApiId,
  DataTableColumnMoveRequest,
  DataTableColumnRemoveResponse,
  DataTableColumnUpdateRequest,
  DataTableMoveColumnResponse,
  DataTableRow,
  DataTableRowAddRequest,
  DataTableRowApiId,
  DataTableService,
  DataTableSettingsUpdate,
  DataTableValidateResponse,
  DataTableWindowClearRequest,
  DataTableWindowClearResponse,
} from '../DataTable.model';

export class DataTableApiService implements IDataTableApiService {
  constructor(private readonly props: DataTableApiServiceProps) {}

  loadTable(context: DataTableService): (source: Observable<any>) => Observable<DataTableV1> {
    return (source) =>
      source.pipe(
        switchMap(
          () =>
            new Observable<DataTableV1>((subscriber) => {
              const { axios, dataTableId, studyId } = this.props;
              const { method, url } = RouteService.api({
                endpoint: 'GET /api/v1/studies/{studyApiId}/datatables/{dataTableApiId}',
                path: { studyApiId: studyId, dataTableApiId: dataTableId },
              });

              const abortController = new AbortController();
              axios
                .request<DataTableV1>({
                  method,
                  baseURL: url.href,
                  signal: abortController.signal,
                })
                .then((result) => subscriber.next(result.data))
                .catch((err) => {
                  subscriber.error(err);
                })
                .finally(() => subscriber.complete());
              return () => {
                abortController.abort();
              };
            })
        )
      );
  }

  loadCells(
    context: DataTableService
  ): DataTableApiServiceOperation<
    DataTableCellSelection<DataTableColumnApiId, DataTableRowApiId> & { include_alerts?: boolean },
    Array<DataTableApiLoadCellResponse>
  > {
    return (source) =>
      source.pipe(
        mergeMap(
          ({ from, to, include_alerts = false }) =>
            new Observable<Array<DataTableApiLoadCellResponse>>((subscriber) => {
              const { axios, dataTableId, studyId } = this.props;
              const { method, url } = RouteService.api({
                endpoint: 'GET /api/v1/studies/{studyApiId}/datatables/{dataTableApiId}/window',
                path: { dataTableApiId: dataTableId, studyApiId: studyId },
                query: {
                  startColumnId: from.column,
                  endColumnId: to.column,
                  startRowId: from.row,
                  endRowId: to.row,
                  include_alerts,
                },
              });
              const abortController = new AbortController();

              axios
                .request<components['schemas']['DataTableWindowV1.schema']>({
                  method,
                  baseURL: url.href,
                  signal: abortController.signal,
                })
                .then((response) => {
                  const data: components['schemas']['DataTableWindowV1.schema'] = response.data;
                  const cells = data.cells ?? [];
                  const cellAlertsMap = new Map<DataTableCellReference, Array<AnimalAlertV1>>(
                    data.data_table_alerts?.map(
                      (cellAlert: components['schemas']['DataTableWindowAlertsV1.schema']) => [
                        toCellRef({
                          column: cellAlert.column_id as DataTableColumnApiId,
                          row: cellAlert.row_id as DataTableRowApiId,
                        }),
                        (cellAlert?.alerts ?? []) as Array<AnimalAlertV1>,
                      ]
                    ) ?? []
                  );
                  subscriber.next(
                    cells.map<DataTableApiLoadCellResponse>((cell) => ({
                      column_id: cell.column_id as DataTableColumnApiId,
                      row_id: cell.row_id as DataTableRowApiId,
                      cell: { value: cell?.value ?? null },
                      alerts: include_alerts
                        ? (cellAlertsMap.get(
                            toCellRef({
                              column: cell.column_id as DataTableColumnApiId,
                              row: cell.row_id as DataTableRowApiId,
                            })
                          ) ?? [])
                        : [],
                      created_at: cell.created_at ?? '',
                      updated_at: cell.updated_at ?? '',
                    }))
                  );
                })
                .catch((error) => {
                  subscriber.error(error);
                  subscriber.complete();
                })
                .finally(() => subscriber.complete());
              return () => {
                abortController.abort();
              };
            })
        )
      );
  }
  validate(
    context: DataTableService
  ): DataTableApiServiceOperation<DataTableWindowClearRequest, DataTableValidateResponse> {
    return (source) =>
      source.pipe(
        mergeMap(
          (request) =>
            new Observable<DataTableValidateResponse>((subscriber) => {
              const { axios, dataTableId, studyId } = this.props;
              const { method, url } = RouteService.api({
                endpoint: 'POST /api/v1/studies/{studyApiId}/datatables/{dataTableApiId}/validate',
                path: { studyApiId: studyId, dataTableApiId: dataTableId },
              });
              const abortController = new AbortController();
              axios
                .request<DataTableValidateResponse>({
                  method,
                  baseURL: url.href,
                  signal: abortController.signal,
                  data: { ...request },
                })
                .then((response) => {
                  subscriber.next(response.data);
                })
                .catch((error) => {
                  subscriber.error(error);
                })
                .finally(() => subscriber.complete());
              return () => {
                abortController.abort();
              };
            })
        )
      );
  }

  updateCells(
    context: DataTableService
  ): DataTableApiServiceOperation<Array<DataTableCellUpsert>, DataTableCellUpsertResponse> {
    return (source) =>
      source.pipe(
        mergeMap(
          (updates) =>
            new Observable<DataTableCellUpsertResponse>((subscriber) => {
              const { axios, dataTableId, studyId } = this.props;
              const { method, url } = RouteService.api({
                endpoint: 'POST /api/v1/studies/{studyApiId}/datatables/{dataTableApiId}/cells',
                path: { studyApiId: studyId, dataTableApiId: dataTableId },
              });
              const abortController = new AbortController();
              axios
                .request<DataTableCellUpsertResponse>({
                  method,
                  baseURL: url.href,
                  signal: abortController.signal,
                  data: { cells: updates },
                })
                .then((response) => {
                  subscriber.next(response.data);
                })
                .catch((error) => {
                  subscriber.error(error);
                })
                .finally(() => subscriber.complete());
              return () => {
                abortController.abort();
              };
            })
        )
      );
  }

  clearWindow(
    context: DataTableService
  ): DataTableApiServiceOperation<DataTableWindowClearRequest, DataTableWindowClearResponse> {
    return (source) =>
      source.pipe(
        mergeMap(
          (request) =>
            new Observable<DataTableWindowClearResponse>((subscriber) => {
              const { axios, dataTableId, studyId } = this.props;
              const { method, url } = RouteService.api({
                endpoint: 'POST /api/v1/studies/{studyApiId}/datatables/{dataTableApiId}/window-clear',
                path: { studyApiId: studyId, dataTableApiId: dataTableId },
              });
              const abortController = new AbortController();
              axios
                .request<DataTableWindowClearResponse>({
                  method,
                  baseURL: url.href,
                  signal: abortController.signal,
                  data: { ...request },
                })
                .then((response) => {
                  subscriber.next(response.data);
                })
                .catch((error) => {
                  subscriber.error(error);
                })
                .finally(() => subscriber.complete());
              return () => {
                abortController.abort();
              };
            })
        )
      );
  }

  updateTableSettings(context: DataTableService): DataTableApiServiceOperation<DataTableSettingsUpdate, void> {
    return (source) =>
      source.pipe(
        switchMap(
          (data) =>
            new Observable<void>((subscriber) => {
              const { axios, dataTableId, studyId } = this.props;
              const { method, url } = RouteService.api({
                endpoint: 'PATCH /api/v1/studies/{studyApiId}/datatables/{dataTableApiId}/settings',
                path: { studyApiId: studyId, dataTableApiId: dataTableId },
              });
              const abortController = new AbortController();
              axios
                .request<void>({
                  method,
                  baseURL: url.href,
                  signal: abortController.signal,
                  data,
                })
                .then(() => subscriber.next())
                .catch((error) => {
                  subscriber.error(error);
                })
                .finally(() => subscriber.complete());
              return () => {
                abortController.abort();
              };
            })
        )
      );
  }

  moveColumn(
    context: DataTableService
  ): DataTableApiServiceOperation<DataTableColumnMoveRequest, DataTableMoveColumnResponse> {
    return (source) =>
      source.pipe(
        mergeMap(
          (data) =>
            new Observable<DataTableMoveColumnResponse>((subscriber) => {
              const { axios, dataTableId, studyId } = this.props;
              const { column_id, target_column_id, position } = data;
              const { method, url } = RouteService.api({
                endpoint: 'POST /api/v1/studies/{studyApiId}/datatables/{dataTableApiId}/move-column',
                path: { studyApiId: studyId, dataTableApiId: dataTableId },
                query: {
                  column_id,
                  target_column_id,
                  position: position || '',
                },
              });
              const abortController = new AbortController();
              axios
                .request<DataTableMoveColumnResponse>({
                  method,
                  baseURL: url.href,
                  signal: abortController.signal,
                  data,
                })
                .then((response) => subscriber.next(response.data))
                .catch((error) => {
                  subscriber.error(error);
                })
                .finally(() => subscriber.complete());
              return () => {
                abortController.abort();
              };
            })
        )
      );
  }

  addColumn(
    context: DataTableService
  ): DataTableApiServiceOperation<DataTableColumnAddRequest, Array<DataTableColumn>> {
    return (source) =>
      source.pipe(
        mergeMap(
          (data) =>
            new Observable<Array<DataTableColumn>>((subscriber) => {
              const { axios, dataTableId, studyId } = this.props;
              const { method, url } = RouteService.api({
                endpoint: 'POST /api/v1/studies/{studyApiId}/datatables/{dataTableApiId}/columns',
                path: { studyApiId: studyId, dataTableApiId: dataTableId },
              });
              const abortController = new AbortController();
              axios
                .request<{ data: Array<DataTableColumn> }>({
                  method,
                  baseURL: url.href,
                  signal: abortController.signal,
                  data,
                })
                .then((response) => subscriber.next(response.data.data))
                .catch((error) => {
                  subscriber.error(error);
                })
                .finally(() => subscriber.complete());
              return () => {
                abortController.abort();
              };
            })
        )
      );
  }

  removeColumn(
    context: DataTableService
  ): DataTableApiServiceOperation<DataTableColumnApiId, DataTableColumnRemoveResponse> {
    return (source) =>
      source.pipe(
        mergeMap(
          (dataTableColumnId) =>
            new Observable<DataTableColumnRemoveResponse>((subscriber) => {
              const { axios, dataTableId, studyId } = this.props;
              const { method, url } = RouteService.api({
                endpoint: 'DELETE /api/v1/studies/{studyApiId}/datatables/{dataTableApiId}/columns/{dataTableColumnId}',
                path: { studyApiId: studyId, dataTableApiId: dataTableId, dataTableColumnId },
              });
              const abortController = new AbortController();
              axios
                .request<DataTableColumnRemoveResponse>({
                  method,
                  baseURL: url.href,
                  signal: abortController.signal,
                })
                .then((response) => subscriber.next(response.data))
                .catch((error) => {
                  subscriber.error(error);
                })
                .finally(() => subscriber.complete());
              return () => {
                abortController.abort();
              };
            })
        )
      );
  }

  addRow(context: DataTableService): DataTableApiServiceOperation<DataTableRowAddRequest, Array<DataTableRow>> {
    return (source) =>
      source.pipe(
        mergeMap(
          (data) =>
            new Observable<Array<DataTableRow>>((subscriber) => {
              const { axios, dataTableId, studyId } = this.props;
              const { method, url } = RouteService.api({
                endpoint: 'POST /api/v1/studies/{studyApiId}/datatables/{dataTableApiId}/rows',
                path: { studyApiId: studyId, dataTableApiId: dataTableId },
              });
              const abortController = new AbortController();
              axios
                .request<{ rows: Array<DataTableRow> }>({
                  method,
                  baseURL: url.href,
                  signal: abortController.signal,
                  data,
                })
                .then((response) => subscriber.next(response.data.rows))
                .catch((error) => {
                  subscriber.error(error);
                })
                .finally(() => subscriber.complete());
              return () => {
                abortController.abort();
              };
            })
        )
      );
  }

  removeRow(context: DataTableService): DataTableApiServiceOperation<DataTableRowApiId, void> {
    return (source) =>
      source.pipe(
        mergeMap(
          (dataTableRowId) =>
            new Observable<void>((subscriber) => {
              const { axios, dataTableId, studyId } = this.props;
              const { method, url } = RouteService.api({
                endpoint: 'DELETE /api/v1/studies/{studyApiId}/datatables/{dataTableApiId}/rows/{dataTableRowId}',
                path: { studyApiId: studyId, dataTableApiId: dataTableId, dataTableRowId },
              });
              const abortController = new AbortController();
              axios
                .request<void>({
                  method,
                  baseURL: url.href,
                  signal: abortController.signal,
                })
                .then((response) => subscriber.next())
                .catch((error) => {
                  subscriber.error(error);
                })
                .finally(() => subscriber.complete());
              return () => {
                abortController.abort();
              };
            })
        )
      );
  }

  updateColumn(context: DataTableService): DataTableApiServiceOperation<DataTableColumnUpdateRequest, DataTableColumn> {
    return (source) =>
      source.pipe(
        mergeMap(
          (columnUpdate) =>
            new Observable<DataTableColumn>((subscriber) => {
              const { axios, dataTableId, studyId } = this.props;
              const { method, url } = RouteService.api({
                endpoint: 'PATCH /api/v1/studies/{studyApiId}/datatables/{dataTableApiId}/columns/{dataTableColumnId}',
                path: { studyApiId: studyId, dataTableApiId: dataTableId, dataTableColumnId: columnUpdate.id },
              });
              const abortController = new AbortController();
              axios
                .request<DataTableColumn>({
                  method,
                  baseURL: url.href,
                  signal: abortController.signal,
                  data: columnUpdate,
                })
                .then((response) => subscriber.next(response.data))
                .catch((error) => {
                  subscriber.error(error);
                })
                .finally(() => subscriber.complete());
              return () => {
                abortController.abort();
              };
            })
        )
      );
  }
}
