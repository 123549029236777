import type { Column } from '@/components/UI/Table/TableComponent.model';
import type { Animal } from '@/model/Animal.model';
import type { ID, ISODate, ISODateTime } from '@/model/Common.model';
import type { PresetCalculation } from '@/model/PresetCalculation.model';
import type { TreatmentGroup } from '@/model/TreatmentGroup.model';
import type { OneWayAnovaResults } from './Statistics.model';
import { StepFormState } from '@/components/UI/StepForm/StepForm.model';
import { RDOptions, ValidateAnimals } from '../RandomizeByDate/RandomizeByDate.model';
import { Endpoints } from '@/generated/internal-api/Endpoints';

export enum RandomizationMethodKeys {
  cluster = 'cluster',
  block = 'block',
}

export enum RandomizationMethods {
  cluster = 'Clustered',
  block = 'Block',
}

export enum RandomizationAttributes {
  sex = 'sex',
  donor_id = 'donor_id',
  dob = 'dob',
}

export interface RandomizeState extends StepFormState<RandomizeState> {
  groups: Array<TreatmentGroup>;
  data: unknown;
  originalSelectedSubjects: Array<Animal>;
  complete: boolean;
  treatmentGroups: {
    totalSpace: number;
    remainingAnimals: number;
    groupDistribution: Record<string, number>;
    unevenTreatmentGroups?: boolean;
  };
  exclusionCriteria: ExclusionCriteria;
  randomize: RandomizeOutput;
  randomizeByDate: {
    rdSelection: RDOptions;
    randomizeDate: null | ISODate;
    validAnimals: ValidateAnimals;
    measurementsOnDate: Endpoints['POST /api/v1/studies/{studyId}/measurements-on-date']['response'] | null;
    validStudyGroups: boolean | string;
    enabledCalculations: Array<PresetCalculation>;
  };
}

export interface ExclusionCriteria {
  subjects: Array<Animal>;
  includedSubjects: number;
  averageActiveCriteria: number;
  averageWeight: number;
  autoExcluded: {
    excludedByMeasurement: number;
    excludedByGroup: number;
    excludedByStatus: number;
  };
  criteriaIndex: number;
  criteriaOptions: Array<{
    name: string;
    value: string;
    unit: string;
  }>;
  changes: boolean;
  autoMedian: boolean;
  targetMean: number;
  subjectsAfterExclusion: Array<Animal>;
  subjectsExcluded?: Array<Animal>;
}

export interface RandomizeOutput {
  method: RandomizationMethodKeys;
  results: Array<RandomizationResultFlattened>;
  trackingDatesUpdated: boolean;
  selectedMetrics: Array<RandomizeMetric>;
  selectedAttribute: Column<RandomizationTableData>;
  tableData?: ResultsTable;
}

export interface ResultsTable {
  columns: Array<Column<RandomizationTableData>>;
  oneWayAnovaResults: Record<string, OneWayAnovaResults>;
  data: Array<RandomizationTableData>;
}

export interface RandomizeMetric {
  Header: string;
  id: string;
  accessor: keyof Animal;
  align: string;
  isExclusion?: boolean;
}

export interface AttributeCapacity {
  cohortIndex: number;
  capacity: number;
}

export interface AllocationResult {
  bestGroupIndex: number;
  bestCohortIndex: number;
}

export interface RandomizationResult extends TreatmentGroup {
  originalSize: number;
  modifiedSize: number;
  attrCapacities: AttributeCapacity[];
  cohort_subject_ids: number[][];
  sex?: Record<string, number>;
}

export type RandomizationResultFlattened = Omit<RandomizationResult, 'cohort_subject_ids'> & {
  cohort_subject_ids: number[];
};

export interface RandomizationTableData {
  group: TreatmentGroup;
  name: string;
  size: number;
  significantMeasurement: Record<string, string>;
  sd: {
    significantMeasurement: Record<string, Record<string, string>>;
  };
  mad: {
    significantMeasurement: Record<string, Record<string, string>>;
  };
  subRows: Array<Partial<RandomAnimal>>;
  sex: Record<string, number>;
}

export interface RandomAnimal {
  api_id: string;
  alt_ids: Record<string, string>;
  name: string;
  donor: string | null;
  ear: string | null;
  tag: string | null;
  tail: string | null;
  dob: string | null;
  cage_name: string;
  sex: string;
  group: RandomTreatmentGroup;
  measurement_info: { [metric: PresetCalculation['id']]: number };
  created_at: ISODateTime;
  updated_at: ISODateTime;
}

export interface RandomTreatmentGroup {
  measurements: Record<string, Record<string, number>>;
  color: string;
  subjects: Record<string, Record<string, number>>;
  api_id: string;
  name: string;
  total_animals: number;
  created_at: ISODateTime;
  updated_at: ISODateTime;
}

export interface SubjectAttribute {
  id: string;
  Header: string;
  accessor: string;
}

export interface BlockRandomizationAnimal {
  id: ID | null;
  api_id: Animal['api_id'];
  measurement_info: RandomAnimal['measurement_info'];
}

export interface BlockRandomizationAllocatedAnimal extends BlockRandomizationAnimal {
  study_group_id: TreatmentGroup['api_id'];
}

export type GroupAllocations = Record<string, Array<BlockRandomizationAllocatedAnimal>>;

export interface BlockRandomizationResult {
  allocatedAnimals: Array<BlockRandomizationAllocatedAnimal>;
  groupAllocations: GroupAllocations;
}

export interface RandomizeOptions {
  attr: Column<RandomizationTableData>;
  groups: RandomizationResult[];
  metrics: RandomizeMetric[];
  kSize: number;
}
