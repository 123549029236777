import { FC } from 'react';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { ListDataTables } from '@/components/Studies/DataTables/List/ListDataTables.tsx';
import Header from '@/components/UI/Header';
import { DataTablesList } from '@/components/Studies/DataTables/List/DataTablesList.tsx';
import { createSelector } from '@reduxjs/toolkit';
import { featuresSelector } from '@/support/Selectors.tsx';
import { useSelector } from 'react-redux';
import { DataTableTemplatesList } from '@/components/Studies/DataTables/List/DataTableTemplatesList.tsx';

const selector = createSelector([featuresSelector], (features) => ({
  dataTableTemplatesEnabled: features?.datatables_templates ?? false,
}));

const DataTables: FC = () => {
  const { dataTableTemplatesEnabled } = useSelector(selector);

  return (
    <>
      {dataTableTemplatesEnabled ? (
        <div className="ph4 pt3">
          <Header mainHeaderText="Data Tables" />
          <Tabs>
            <div className="mb4 bb b--moon-gray pt3">
              <Tab className="f4" testId="data-tables-active-tab">
                Active
              </Tab>
              <Tab className="f4" testId="data-tables-templates-tab">
                Templates
              </Tab>
            </div>
            <div>
              <Panel>
                <DataTablesList />
              </Panel>
              <Panel>
                <DataTableTemplatesList />
              </Panel>
            </div>
          </Tabs>
        </div>
      ) : (
        <ListDataTables />
      )}
    </>
  );
};

export default DataTables;
