import { _notNil } from '@/littledash';
import { Animal, AnimalIdentifier, AnimalV1 } from '@/model/Animal.model.ts';
import { Nullable } from '@/model/Common.model.ts';

export const altIds: Array<{ key: string; title: string }> = [
  {
    key: 'name',
    title: 'Name',
  },
  {
    key: 'alt_ids.tail',
    title: 'Tail',
  },
  {
    key: 'alt_ids.ear',
    title: 'Ear',
  },
  {
    key: 'alt_ids.tag',
    title: 'Tag',
  },
  {
    key: 'alt_ids.donor',
    title: 'Donor',
  },
];

export const animalIdsRecord: Record<AnimalIdentifier, Record<string, string>> = {
  name: {
    animalIdKey: 'name',
    title: 'Name',
  },
  tail: {
    animalIdKey: 'alt_ids.tail',
    title: 'Tail',
  },
  ear: {
    animalIdKey: 'alt_ids.ear',
    title: 'Ear',
  },
  tag: {
    animalIdKey: 'alt_ids.tag',
    title: 'Tag',
  },
  donor: {
    animalIdKey: 'alt_ids.donor',
    title: 'Donor',
  },
};

export const isDead = (animal: Nullable<Animal>) =>
  _notNil(animal?.terminated_at) ? 'Deceased' : (animal?.cage?.name ?? '');

export const isDeadV1 = (animal: Nullable<AnimalV1>) =>
  _notNil(animal?.terminated_at) ? 'Deceased' : (animal?.cage?.name ?? '');

const ALLOWED_ORIGIN_PATTERN = new RegExp(
  '^https:\\/\\/([a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+(benchling|bnchcdn|overwatchresearch)\\.com(\\/.*)?$' + // Require valid subdomains
    '|^https:\\/\\/([a-zA-Z0-9]([a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)*invivo\\.bnchdev\\.org(\\/.*)?$' + // Match valid *.invivo.bnchdev.org
    '|^http:\\/\\/localhost:3000$' // Match only http://localhost:3000
);
export const isAllowedOrigin = (origin: string): boolean => {
  return ALLOWED_ORIGIN_PATTERN.test(origin);
};
