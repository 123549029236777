import { ErrorMessage } from '@hookform/error-message';
import type { State } from 'model/State.model';
import { useMemo, type FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form@latest';
import { useSelector } from 'react-redux';

/**
 *   Used in react-hook-form v7 forms
 *   errors has changed position in the formState object between versions
 *   controller object args have changed
 */

interface ReasonForChangeProps {
  isDelete?: boolean;
  enableRules?: boolean;
}

const ReasonForChangeV7: FC<ReasonForChangeProps> = ({ isDelete, enableRules = true }) => {
  const {
    features: { reason_for_change },
  } = useSelector(({ team: { features } }: State) => ({
    features,
  }));

  const {
    formState: { errors },
    control,
  } = useFormContext();

  const rules = useMemo(() => {
    if (enableRules) {
      return {
        required: `You must provide a reason for the ${isDelete ? 'deletion' : 'change'}`,
        minLength: {
          value: 3,
          message: 'Please enter at least 3 characters',
        },
        maxLength: {
          value: 255,
          message: 'Please enter less than 256 characters',
        },
      };
    }
  }, [enableRules, isDelete]);

  return (
    <>
      {reason_for_change && (
        <div className="pt2" data-testid="reason-for-change-container">
          <label htmlFor="reason_for_change" data-testid="reason-for-label">
            Reason for {isDelete ? 'deletion' : 'change'} <span className="blue">*</span>
          </label>
          <Controller
            name="reason_for_change"
            control={control}
            rules={rules}
            render={({ field: { onChange, value } }) => (
              <textarea
                defaultValue={value}
                onChange={onChange}
                id="reason_for_change"
                style={{ resize: 'none' }}
                className={`${errors.reason_for_change ? 'input__error' : ''} mb0`}
                data-testid="reason-for-change-field"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={`reason_for_change`}
            render={({ message }) => <p className="f6 red db lh-copy pt1">{message}</p>}
          />
        </div>
      )}
    </>
  );
};

export default ReasonForChangeV7;
