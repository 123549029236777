// @ts-nocheck: converted from JS

import { setGlobalData } from '@/actions/global-data';
import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import Button from '@/components/UI/Button';
import Header from '@/components/UI/Header';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { successToast } from '@/helpers';
import { _isEmpty, _notNil, safelyDecodeURIComponent } from '@/littledash';
import type { MetadataFieldWithValue } from '@/model/Metadata.model';
import { useFetchCollection } from '@/support/Hooks/fetch';
import Http from '@/support/http';
import { api as apiRoutes } from '@/support/route';
import { structuredCloneUtil } from '@/utils/browser.utils';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { ExtendedMetadataField, FormError } from '../SpeciesStrains/SpeciesComponent.model';
import { metaDataTypes, saveGlossaryMetadata, validateMetaData } from './Metadata.utils';
import Repeater from './Repeater';
import { ID } from '@/model/Common.model';
import { createSelector } from '@reduxjs/toolkit';

// TODO ExtendedMetadataItem
const Metadata: React.FC = () => {
  const [initialData, setInitialData] = useState<Array<ExtendedMetadataField>>([]);
  const [data, setData] = useState<Array<ExtendedMetadataField>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [formErrors, setFormErrors] = useState<Array<FormError>>([]);
  const [submissionError, setSubmissionError] = useState<false | Error>(false);

  const selectDiseaseInduction = createSelector(
    (state: { team: { features: { disease_induction: boolean } } }) => state.team.features.disease_induction,
    (disease_induction) => ({ disease_induction })
  );

  const { disease_induction } = useSelector(selectDiseaseInduction);

  const initialDataLookup = useMemo(
    () =>
      initialData.reduce<Record<ID, ExtendedMetadataField>>((acc, val) => {
        acc[val.id] = val;
        return acc;
      }, {}),
    [initialData]
  );

  const dispatch = useDispatch();

  const encodeMetadata = (row: MetadataFieldWithValue, index: number) => {
    let { options } = row;
    const { field_type } = row;

    if (field_type === 'multi_select' || field_type === 'select') {
      options = options?.map((option) => safelyDecodeURIComponent(option));
    }
    return { index, ...row, options, expanded: false };
  };

  const { collection, collectionLoading, fetchCollection } = useFetchCollection<MetadataFieldWithValue>({
    collectionType: 'metadataGlossary',
    queryParams: {
      include_inactive: 'true',
    },
  });

  useEffect(() => {
    if (_notNil(collection)) {
      const modifiedCollection = collection.map(encodeMetadata).sort((a, b) => b.active - a.active);
      setData(modifiedCollection);
      setInitialData(structuredCloneUtil(modifiedCollection));
      setLoading(collectionLoading);
    }
  }, [collection]);

  const validateField = async (d: ExtendedMetadataField): Promise<false | FormError> => {
    const validateRow = await validateMetaData(d, data);
    if (validateRow) {
      return {
        index: d.index,
        id: d.id,
        type: d.type,
        errors: {
          ...validateRow,
        },
      };
    }

    return false;
  };

  const validateFields = async () => {
    const results: Array<false | FormError> = [];
    for (let index = 0; index < data.length; index++) {
      results[index] = await validateField(data[index]);
    }

    return setFormErrors(results);
  };

  useEffect(() => {
    validateFields();
  }, [data]);

  const fetchGlobalData = async () => {
    try {
      const { data } = await Http.get(apiRoutes('global-data'));
      setGlobalData(data, dispatch);
    } catch (error) {
      setSubmissionError(error as Error);
    }
  };

  // TODO: Make single call, using useRequest
  const handleSubmit = async () => {
    const isValidForm = formErrors.every((o) => o === false);
    if (isValidForm) {
      setLoading(true);
      return saveGlossaryMetadata(data, initialData)
        .then(() => {
          setLoading(false);
          successToast('Successfully saved metadata');
          return Promise.all([fetchCollection(), fetchGlobalData()]);
        })
        .catch((error) => {
          setLoading(false);
          setSubmissionError(error);
        });
    }
  };

  const filteredMetadataTypes = disease_induction
    ? [...metaDataTypes]
    : metaDataTypes.filter(({ key }) => key !== 'disease_meta');

  return loading ? (
    <Loading txt="Fetching Metadata..." />
  ) : (
    <div className={`${loading ? 'ui__disabled' : ''}`}>
      <Header mainHeaderText="Metadata" />
      <div className="pb3">
        <p className="f6">
          Customise the metadata your team can select from.
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="dib ml1 link blue"
            href="https://help.benchling.com/hc/en-us/articles/20128144026125-Metadata"
          >
            Read more
          </a>
        </p>

        {submissionError && <ApiErrorBanner error={submissionError} />}
      </div>
      <Tabs>
        <div className="bb b--moon-gray">
          {filteredMetadataTypes.map(({ key, title }) => (
            <Tab
              key={key}
              className={`f5 f4-l ${!_isEmpty(formErrors.filter((e) => e && e.type === key)) ? 'red' : ''}`}
            >
              {title}
            </Tab>
          ))}
        </div>
        {filteredMetadataTypes.map(({ key, title }) => (
          <Panel key={key}>
            <Repeater
              title={title}
              data={data}
              errors={formErrors}
              type={key}
              setData={setData}
              loading={loading}
              initialDataLookup={initialDataLookup}
            />
          </Panel>
        ))}
      </Tabs>
      <div className="pt3 mt4 bt b--moon-gray">
        <Button
          tooltip={!formErrors.every((o) => o === false) ? 'Please fill in all required fields' : undefined}
          disabled={loading || !formErrors.every((o) => o === false)}
          className="mr3"
          onClick={handleSubmit}
        >
          Save
        </Button>
        <Button plain onClick={fetchCollection} testId={'button-undo-changes'}>
          Undo changes
        </Button>
      </div>
    </div>
  );
};

export default Metadata;
