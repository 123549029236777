// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';
import MetadataButton from '@/components/UI/MetadataButton';
import Spinner from '@/components/UI/Spinner';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { successToast } from '@/helpers';
import { _isNil, _isNotEmpty, _notNil } from '@/littledash';
import { Animal } from '@/model/Animal.model.ts';
import InVivoError from '@/model/InVivoError.ts';
import type { Study } from '@/model/Study.model';
import * as Auth from '@/support/auth';
import { notAborted, useAbortController } from '@/support/Hooks/fetch/useAbortController';
import useMountedState from '@/support/Hooks/fetch/useMountedState.ts';
import Http from '@/support/http';
import { api as apiRoute } from '@/support/route';
import { RouteService } from '@/support/RouteService.ts';
import { isClosed } from '@/support/study';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import React, { Dispatch, ReactNode, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getNextAnimal } from './Show.utils';
import DosingView from './Views/Dosing/DosingView';
import MeasurementView from './Views/MeasurementView';
import ObservationView from './Views/ObservationView';
import SamplesView from './Views/SamplesView';
import type { ShowState, ShowStateAction } from './Workflow.model';
import { useLegacyApiHook } from '@/support/Hooks/api/useLegacyApiHook.ts';

export interface WorkflowStageProps {
  study: Study;
  state: ShowState;
  dispatch: Dispatch<ShowStateAction>;
  openFlash: (res: ReactNode) => void;
  openModal: (modal: string, props: unknown) => void;
  closeModal: () => { type: string };
  focusSearch: () => void;
  openSetupWorkflow: () => void;
  fetchSubjects: () => Promise<void>;
}

const WorkflowStage: React.FC<WorkflowStageProps> = ({
  study,
  state,
  dispatch,
  openFlash,
  openModal,
  closeModal,
  focusSearch,
  openSetupWorkflow,
  fetchSubjects,
}) => {
  const { collections, subjects, workflow, checklist, measured_at, showGroups, selectedAnimal, startTab } = state;

  const { team, features } = useSelector(({ team: { team, features } }) => ({
    team,
    features,
  }));
  const atunesIntegrationEnabled = features.atunes_colony_management_integration === true;
  const atunesIntegrationUrl = atunesIntegrationEnabled ? team?.colony_exit_url : null;

  const hasToggleDosing = useMemo(
    () => (workflow ?? []).find((w) => w.name === 'dosing')?.skipDosing ?? false,
    [workflow]
  );
  const getWorkflowIndex = (type) => workflow.filter((w) => w.value).findIndex((w) => w.name === type);
  const [subject, setSubject] = useState({});
  const [measurements, setMeasurements] = useState([]);
  const [samples, setSamples] = useState([]);
  const [observations, setObservations] = useState([]);
  const [dosages, setDosages] = useState([]);

  const [loading, setLoading] = useState(true);
  const [unsavedMeasurements, setUnsavedMeasurements] = useState(false);
  const [activeTab, setActiveTab] = useState({ index: 0, clicked: false });
  const { newAbortController } = useAbortController();
  const { newAbortController: newMeasurementsAbortController } = useAbortController();
  const { newAbortController: newSamplesAbortController } = useAbortController();
  const { newAbortController: newObservationsAbortController } = useAbortController();
  const { newAbortController: newDosingAbortController } = useAbortController();
  const isMounted = useMountedState();
  const storeDispatch = useDispatch();
  const { id } = useParams();

  const dataUpdate = {
    measurements: (data) => setMeasurements(data),
    samples: (data) => setSamples(data),
    observations: (data) => setObservations(data),
    dosing: (data) => setDosages(data),
  };

  const { response: treatmentsResponse } = useLegacyApiHook({
    method: 'GET',
    apiRoute: 'studies.treatments',
    path: { studyId: study.id },
    query: { perPage: -1, include: 'study_groups,metadata' },
    options: { onError: { toast: false, slug: 'treatments-load' } },
  });

  const studyTreatments =
    (
      treatmentsResponse?.body as {
        data: Array<Treatment>;
      }
    )?.data ?? [];

  const fetchAnimalDataByType = async (
    subject: Animal,
    type: 'measurements' | 'samples' | 'observations' | 'dosing',
    query: Record<string, string | number | boolean | Array<string | number | boolean>> = {}
  ) => {
    try {
      switch (type) {
        case 'measurements':
          await Http.get(
            RouteService.api({
              endpoint: 'GET /api/v1/animals/{animalId}/measurements',
              path: { animalId: subject.id },
              query,
            }).url.href,
            {
              signal: newMeasurementsAbortController().signal,
            }
          ).then((response) => dataUpdate.measurements(response.data.data));
          break;
        case 'dosing':
          await Http.get(
            RouteService.api({
              endpoint: 'GET /api/v1/animals/{animalId}/dosages',
              path: { animalId: subject.id },
              query,
            }).url.href,
            {
              signal: newDosingAbortController().signal,
            }
          ).then((response) => dataUpdate.dosing(response.data.data));
          break;
        case 'observations':
          await Http.get(
            RouteService.api({
              endpoint: 'GET /api/v1/animals/{animalId}/observations',
              path: { animalId: subject.id },
              query,
            }).url.href,
            {
              signal: newObservationsAbortController().signal,
            }
          ).then((response) => dataUpdate.observations(response.data.data));
          break;
        case 'samples':
          await Http.get(
            RouteService.api({
              endpoint: 'GET /api/v1/animals/{animalId}/samples',
              path: { animalId: subject.id },
              query,
            }).url.href,
            {
              signal: newSamplesAbortController().signal,
            }
          ).then((response) => dataUpdate.samples(response.data.data));
      }
    } catch (error) {
      if (notAborted(error)) {
        ExceptionHandler.captureException(
          new InVivoError(`Could not load animal data by ${type}`, {
            cause: error,
            slug: `fetch-animal-data-by-${type}`,
          })
        );
      }
    }
  };

  useEffect(() => {
    const fetchAllAnimalEntities = async (subjectId) => {
      setLoading(true);
      try {
        await Promise.all([
          Http.get(
            RouteService.api({
              endpoint: 'GET /api/v1/animals/{animalId}/measurements',
              path: { animalId: subjectId },
            }).url.href,
            {
              signal: newMeasurementsAbortController().signal,
            }
          ),
          Http.get(
            RouteService.api({
              endpoint: 'GET /api/v1/animals/{animalId}/samples',
              path: { animalId: subjectId },
            }).url.href,
            {
              signal: newSamplesAbortController().signal,
            }
          ),
          Http.get(
            RouteService.api({
              endpoint: 'GET /api/v1/animals/{animalId}/observations',
              path: { animalId: subjectId },
            }).url.href,
            {
              signal: newObservationsAbortController().signal,
            }
          ),
          Http.get(
            RouteService.api({
              endpoint: 'GET /api/v1/animals/{animalId}/dosages',
              path: { animalId: subjectId },
              query: { sort: 'created_at', order: 'desc' },
            }).url.href,
            { signal: newDosingAbortController().signal }
          ),
        ]).then(([measurementsResponse, samplesResponse, observationsResponse, dosagesResponse]) => {
          if (isMounted()) {
            setMeasurements(measurementsResponse?.data?.data ?? []);
            setSamples(samplesResponse?.data?.data ?? []);
            setObservations(observationsResponse?.data?.data ?? []);
            setDosages(dosagesResponse?.data?.data ?? []);
            setLoading(false);
          }
        });
      } catch (error) {
        if (notAborted(error)) {
          setLoading(false);
          ExceptionHandler.captureException(
            new InVivoError('Could not load animal [measurements,samples,observations,dosing]', {
              cause: error,
              slug: 'animal-data-load',
            })
          );
        }
      }
    };
    const selectedSubjectFromMap = subjects.find((s) => Number(s.id) === Number(selectedAnimal));
    const subjectCage = state?.collections?.find(
      (cage) =>
        Number(cage.id) ===
        (_notNil(selectedSubjectFromMap?.terminated_at)
          ? Number(selectedSubjectFromMap?.deceasedCageId)
          : Number(selectedSubjectFromMap?.cage_id))
    );
    if (_notNil(subjectCage) && _notNil(selectedSubjectFromMap)) {
      setSubject({ cage: subjectCage, ...selectedSubjectFromMap });
      fetchAllAnimalEntities(selectedAnimal);
    } else {
      setSubject({ ...selectedSubjectFromMap });
      setLoading(false);
    }
  }, [selectedAnimal, workflow, study, subjects]);

  useEffect(() => {
    const measurementAlerts = measurements.flatMap((measurement) => measurement?.alerts ?? []);
    if (_isNotEmpty(measurementAlerts)) {
      dispatch({ type: 'updateAlerts', data: measurementAlerts });
    }
  }, [measurements]);

  useEffect(() => {
    setActiveTab({ index: getWorkflowIndex(startTab), clicked: false });
  }, [startTab, workflow, selectedAnimal]);

  const updateChecklist = (type, id) => {
    return dispatch({
      type: 'updateChecklist',
      data: { ...checklist, [id]: { [type]: true } },
    });
  };

  const canWrite = !isClosed(study) && Auth.isWriteUserForStudy(study);

  const manageTabWorkflowSwitch = (workflowIndex, clicked) => setActiveTab({ index: workflowIndex, clicked: clicked });

  const onSaveToggles = (workflow) =>
    workflow.reduce((acc, { name }) => {
      acc[`toggle-${name}`] = () => manageTabWorkflowSwitch(getWorkflowIndex(name), false);
      return acc;
    }, {});

  const onSave = {
    'focus-search': () => focusSearch(),
    'next-subject': () => {
      const nextAnimal = getNextAnimal(selectedAnimal, subjects);
      return dispatch({
        type: 'update',
        data: {
          selectedCage: nextAnimal.cage_id,
          selectedAnimal: nextAnimal.id,
        },
      });
    },
    ...onSaveToggles(workflow),
  };

  const markAsDeceased = async () => {
    closeModal();
    setLoading(true);
    try {
      const response = await Http.get(apiRoute('studies.show', { id }), {
        signal: newAbortController().signal,
      });
      storeDispatch({
        type: 'SET_CURRENT_STUDY',
        study: response.data.data,
      });
      const {
        data: { data: updatedSubject },
      } = await Http.get(
        apiRoute('studies.animal.show', { studyId: id, id: subject.id }, { include: 'terminated_at_data' })
      );
      setSubject(updatedSubject);
      dispatch({
        type: 'updateSubject',
        data: { ...updatedSubject, deceasedCageId: subject.cage_id },
      });
      successToast('Successfully marked animal as deceased');
      setLoading(false);
    } catch (error) {
      if (notAborted(error)) {
        setLoading(false);
        ExceptionHandler.captureException(
          new InVivoError('Could not mark animal as deceased', {
            cause: error,
            slug: 'mark-animal-deceased',
          })
        );
      }
    }
  };

  /**
   *
   * @param view {"measurements", "samples", "observations", "dosages"}
   */
  const handleSave = (view) => {
    switch (view) {
      case 'measurements': {
        onSave?.[workflow?.[0]?.onSave]?.();
        break;
      }
      case 'samples': {
        onSave?.[workflow?.[1]?.onSave]?.();
        break;
      }
      case 'observations': {
        onSave?.[workflow?.[2]?.onSave]?.();
        break;
      }
      case 'dosages': {
        onSave?.[workflow?.[3]?.onSave]?.();
        break;
      }
    }
  };

  const renderView: (view: string, clickedInto: boolean) => JSX.Element = (view, clickedInto = false) => {
    switch (view) {
      case 'measurements':
        return (
          <MeasurementView
            setSubject={setSubject}
            subject={subject}
            collections={collections}
            study={study}
            measurements={measurements}
            fetchAnimalDataByType={fetchAnimalDataByType}
            openModal={openModal}
            openFlash={openFlash}
            closeModal={closeModal}
            updateChecklist={updateChecklist}
            measured_at={measured_at}
            workflow={workflow[0]}
            focusSearch={focusSearch}
            dispatch={dispatch}
            onSave={() => handleSave('measurements')}
            showGroups={showGroups}
            canWrite={canWrite}
            fetchSubjects={fetchSubjects}
            setLoading={setLoading}
            setUnsavedMeasurements={setUnsavedMeasurements}
            playSound={state.playSound}
          />
        );
      case 'samples':
        return (
          <SamplesView
            subject={subject}
            study={study}
            collections={collections}
            samples={samples}
            fetchAnimalDataByType={fetchAnimalDataByType}
            openModal={openModal}
            closeModal={closeModal}
            updateChecklist={updateChecklist}
            measured_at={measured_at}
            focusSearch={focusSearch}
            workflow={workflow[1]}
            onSave={() => handleSave('samples')}
            canWrite={canWrite}
          />
        );
      case 'observations':
        return (
          <ObservationView
            subject={subject}
            study={study}
            observations={observations}
            fetchAnimalDataByType={fetchAnimalDataByType}
            openModal={openModal}
            observed_at={measured_at}
            closeModal={closeModal}
            openFlash={openFlash}
            focusSearch={focusSearch}
            workflow={workflow[2]}
            onSave={() => handleSave('observations')}
            canWrite={canWrite}
          />
        );
      case 'dosing':
        return (
          <DosingView
            setSubject={setSubject}
            subject={subject}
            study={study}
            dosages={dosages}
            measurements={measurements}
            dosed_at={measured_at}
            fetchAnimalDataByType={fetchAnimalDataByType}
            openModal={openModal}
            closeModal={closeModal}
            updateChecklist={updateChecklist}
            workflow={workflow[3]}
            dispatch={dispatch}
            onSave={() => handleSave('dosages')}
            canWrite={canWrite}
            openSetupWorkflow={openSetupWorkflow}
            hasToggleDosing={hasToggleDosing}
            isClicked={clickedInto}
            studyTreatments={studyTreatments}
          />
        );
      default:
        return <LoadingAnimal />;
    }
  };

  const MarkAsDeceasedButton = () => (
    <Button
      className="link f6 basier-reg"
      stateless
      disabled={unsavedMeasurements}
      tooltip={unsavedMeasurements ? 'There are unsaved measurements' : ''}
      onClick={() =>
        openModal('EUTHANISE_SUBJECT', {
          subjects: [subject],
          handleCallback: markAsDeceased,
          closeModal,
        })
      }
    >
      Mark as deceased
    </Button>
  );

  return (
    <div className="w-60 bg-light-gray flex flex-column" style={{ minHeight: '100%' }}>
      {_isNotEmpty(subject) && _isNotEmpty(workflow) && (
        <Tabs outerState={[activeTab.index, (index) => manageTabWorkflowSwitch(index, true)]}>
          <div className="bb b--moon-gray ph3 pt3 bg-white flex">
            <div>
              {workflow.reduce((acc, { value, displayName }, i) => {
                if (value) {
                  acc.push(
                    <Tab key={i} className="f6" testId={`workflow-${displayName.toLowerCase()}-tab-button`}>
                      {displayName}
                    </Tab>
                  );
                }
                return acc;
              }, [])}
            </div>
            <div className="ml-auto flex">
              {_isNotEmpty(subject.metadata) && (
                <div>
                  <MetadataButton entity={subject} entityName="animalMeta" tooltip="Animal metadata" />
                </div>
              )}
              {_isNil(subject.terminated_at) && (
                <>
                  {!atunesIntegrationEnabled ? (
                    <MarkAsDeceasedButton />
                  ) : (
                    <ExitThroughAtunesButton subject={subject} url={atunesIntegrationUrl} />
                  )}
                </>
              )}
            </div>
          </div>
          {workflow.reduce((acc, { value, name }, i) => {
            if (value) {
              acc.push(
                <Panel key={i} className="ui__workflow__expand">
                  {!loading ? renderView(name, activeTab.clicked) : renderView('empty')}
                </Panel>
              );
            }
            return acc;
          }, [])}
        </Tabs>
      )}
    </div>
  );
};
const ExitThroughAtunesButton = ({ url, subject }) => {
  if (_notNil(url) && _notNil(subject?.external_id)) {
    const integrationLink = new URL(url);
    integrationLink.searchParams.append('id', subject.external_id);
    integrationLink.searchParams.append('module', 'Breeding');
    integrationLink.searchParams.append('action', 'ReductionSingle');
    integrationLink.searchParams.append('hdvalue', 'L-714751882P36$-1732212204');

    return (
      <a href={integrationLink} target="_blank" rel="noreferrer">
        tick@lab Exit
      </a>
    );
  }
  return <>tick@lab Exit</>;
};
const LoadingAnimal = () => (
  <div className="flex justify-center items-center h-100">
    <div className="tc ph3 mw6 center">
      <div className="tc">
        <Spinner size="large" color="blue" className="dib" />
        <h3 className="lh-title f4 basier-reg normal pv3">Loading...</h3>
      </div>
    </div>
  </div>
);

export default WorkflowStage;
