import Loading from '@/components/Loading';
import MoveToStudyHeader from '@/components/Modals/MoveToStudy/MoveToStudyHeader';
import Table from '@/components/UI/Table';
import { _isNil, _isNotEmpty, _notNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import type { CageApiId } from '@/model/Cage.model';
import type { Study } from '@/model/Study.model';
import React, { useMemo } from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiService } from '@/support/ApiService';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { ModalActions, ModalContainer, useModalAction } from '@/utils/modal';
import { ID } from '@/model/Common.model.ts';
import { createSelector } from '@reduxjs/toolkit';
import { featuresSelector } from '@/support/Selectors.tsx';
import { useSelector } from 'react-redux';

interface MoveToStudyProps {
  subjects: Array<Animal>;
  handleCallback: (cageApiIds: Array<string | undefined>, currentStudy: Study, animalCount: number) => void;
}

const columns = [
  { id: 'no', Header: 'No.', accessor: 'number' },
  { id: 'id', Header: 'Name', accessor: 'name' },
  { id: 'cage', Header: 'Cage', accessor: 'cage.name' },
  { id: 'sex', Header: 'Sex', accessor: 'sex' },
];

const moveAnimalsV2Selector = createSelector([featuresSelector], (features) => ({
  moveAnimalsV2: features?.move_animals_study_v2 ?? false,
}));

const MoveToStudy: React.FC<MoveToStudyProps> = ({ subjects, handleCallback }) => {
  const { moveAnimalsV2 } = useSelector(moveAnimalsV2Selector);
  const [animals, setAnimals] = useState<Animal[]>([]);
  const [animalsLoading, setAnimalsLoading] = useState<boolean>(true);

  const { closeModal } = useModalAction();
  const { id: studyId } = useParams<{ id: string }>();
  const { entity: currentStudy } = useFetchEntity<Study>({
    entityType: 'study',
    params: { id: studyId },
  });

  const { cageApiIds, cageIds } = (subjects ?? []).reduce(
    (acc, animal) => {
      if (_notNil(animal.cage?.api_id)) {
        acc.cageApiIds.add(animal.cage.api_id);
      }
      if (_notNil(animal.cage?.id)) {
        acc.cageIds.add(animal.cage.id);
      }
      return acc;
    },
    { cageApiIds: new Set<CageApiId>(), cageIds: new Set<ID>() }
  );

  const handleFormSubmit = () => {
    if (_notNil(currentStudy)) {
      handleCallback(Array.from(cageApiIds), currentStudy, animals?.length ?? 0);
    }
  };

  const fetchSubjects = async () => {
    if (_notNil(currentStudy?.api_id)) {
      if (moveAnimalsV2) {
        setAnimals(subjects?.filter((subject) => _isNil(subject.terminated_at)) ?? []);
      } else {
        const encodedCageIdsFilter = encodeURIComponent(`cage|in|${Array.from(cageIds).join(',')}`);
        const encodedNullFilter = encodeURIComponent(`cage|ne|null`);
        const response = await ApiService.call({
          endpoint: 'GET /api/v1/studies/{studyId}/animals',
          path: { studyId: currentStudy.api_id },
          query: {
            perPage: -1,
            ...(_isNotEmpty(cageIds)
              ? {
                  include: ['cage'],
                  filterType: 'and',
                }
              : {}),
          },
          filters: `filters%5B%5D=${encodedCageIdsFilter}&filters%5B%5D=${encodedNullFilter}`,
        });
        if (response.type === 'success') {
          setAnimals(response.body.data as unknown as Animal[]);
        }
      }
      setAnimalsLoading(false);
    }
  };

  useEffect(() => {
    fetchSubjects();
  }, [currentStudy]);

  const cageCount = useMemo(
    () =>
      (animals ?? []).reduce((acc, animal) => {
        const cageId = animal?.cage?.api_id;
        if (_notNil(cageId)) {
          acc.add(cageId);
        }
        return acc;
      }, new Set<CageApiId>()).size,
    [animals]
  );

  if (animalsLoading) {
    return <Loading />;
  }

  return (
    <ModalContainer size="medium" className="bg-white h-100">
      <MoveToStudyHeader cageCount={cageCount} animalCount={animals.length} />
      <Table className="pa3" data={animals ?? []} columns={columns} />
      <ModalActions
        submitBtnText="Continue"
        cancelBtnText="Cancel"
        submitButtonProps={{
          className: 'ml3',
          disabled: false,
          loading: false,
        }}
        onCancel={closeModal}
        onSubmit={handleFormSubmit}
        className="pa3 flex self-end bt b--moon-gray w-100"
      />
    </ModalContainer>
  );
};

export default MoveToStudy;
