import Banner from '@/components/UI/Banner/index';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { featuresSelector } from '@/support/Selectors.tsx';

interface MoveToStudyHeaderProps {
  animalCount: number;
  cageCount: number;
}

const moveAnimalsV2Selector = createSelector([featuresSelector], (features) => ({
  moveAnimalsV2: features?.move_animals_study_v2 ?? false,
}));

const MoveToStudyHeader: FC<MoveToStudyHeaderProps> = ({ animalCount = 1, cageCount = 1 }) => {
  const { moveAnimalsV2 } = useSelector(moveAnimalsV2Selector);
  const header = moveAnimalsV2
    ? `Move ${animalCount} animal${animalCount > 1 ? 's' : ''} to study`
    : `Move ${cageCount} cage${cageCount > 1 ? 's' : ''} to study`;

  const description = moveAnimalsV2
    ? 'Animals that are deceased have been excluded from selection.'
    : 'Studies automatically includes any additional animals in chosen cages while excluding those deceased.';

  return (
    <div>
      <div className="ph3 pt3 pb2">
        <h3 className="basier-med normal f4 lh-title near-black">{header}</h3>
      </div>
      <div className="ml3 mb3">
        <span>
          <p className="f6 lh-copy ma0">
            {description}{' '}
            <a
              href="https://help.benchling.com/hc/en-us/articles/19900024203661-Moving-Cages-of-Animals-between-Studies"
              target="_blank"
              rel="noopener noreferrer"
              className="dib link blue f6 lh-copy"
            >
              Read more
            </a>
          </p>
        </span>
      </div>
      <Banner info className="ml3 mr3 mb2" dismiss={true} center>
        <h3 className="f6 normal lh-title">
          <p>
            <span className="mr2 fw7">All groups, doses, alerts or tracking dates will be removed.</span>
            <span>Measurements, samples and observations will remain intact.</span>
          </p>
        </h3>
      </Banner>
    </div>
  );
};

export default MoveToStudyHeader;
