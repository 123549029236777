import { cageCardsTemplateMap } from '@/components/Team/CageCards/CageCards';
import { formatNumber } from '@/helpers';
import { _notNil } from '@/littledash';
import { Cage } from '@/model/Cage.model';
import type { ID } from '@/model/Common.model';
import type { MetadataField } from '@/model/Metadata.model';
import type { CageCardSettings, Team } from '@/model/Team.model';
import { CageCardSize } from '@/model/Team.model';
import { useApiHook } from '@/support/Hooks/api/useApiHook.ts';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { DateUtils } from '@/utils/Date.utils';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

const getCardMetadata = (cageCardMetadataIds: Array<ID>, metadataGlossary: Array<MetadataField>, cage: Cage) => {
  // @ts-expect-error: cage meta mismatch
  const metadataValues = [...(cage?.meta?.data ?? []), ...(cage?.study?.data?.metas?.data ?? [])].reduce(
    (acc, { glossary_id: glossaryId, value }) => {
      if (cageCardMetadataIds.includes(glossaryId)) {
        return { ...acc, [glossaryId]: value };
      }
      return acc;
    },
    {}
  );

  return metadataGlossary.reduce<Array<{ id: ID; title: string; value: string | number }>>(
    (acc, { id, title, field_type: fieldType }) => {
      if (cageCardMetadataIds.includes(id)) {
        let value = '-';
        const metadataValue = metadataValues[id];
        if (_notNil(metadataValue)) {
          switch (fieldType) {
            case 'numeric':
              value = `${formatNumber(metadataValue)}`;
              break;
            case 'date':
              value = DateUtils.renderDate(metadataValue);
              break;
            default:
              value = metadataValue;
          }
        }
        acc.push({ id, title, value });
      }
      return acc;
    },
    []
  );
};

interface CageCardTemplateWrapperProps {
  cageId: Cage['id'];
  onComplete: () => void;
}

const CageCardTemplateWrapper: React.FC<CageCardTemplateWrapperProps> = ({ cageId, onComplete }) => {
  const printContentRef = useRef(null);
  const handleOnAfterPrint = () => {
    if (typeof onComplete === 'function') {
      onComplete?.();
    }
  };

  const handlePrint = useReactToPrint({ contentRef: printContentRef, onAfterPrint: () => handleOnAfterPrint() });

  const { cageCardSize, cageCardMetadataIds } = useSelector<
    { team: { team: Team } },
    { cageCardSize?: CageCardSettings['size']; cageCardMetadataIds: Array<ID> }
  >(({ team: { team } }) => ({
    cageCardSize: team?.cage_card_settings?.size ?? CageCardSize.FourByThree,
    cageCardMetadataIds: (team?.cage_card_settings?.metadata ?? []) as Array<ID>,
  }));

  const CageCardsTemplate = cageCardsTemplateMap[cageCardSize ?? CageCardSize.FourByThree];
  const { entity: metadata, entityLoading: loadingMetadata } = useFetchEntity<Array<MetadataField>>({
    entityType: 'glossaryMeta',
    params: { filter_type: 'study_meta|collection_meta' },
  });
  const { response: speciesResponse, loading: loadingSpecies } = useApiHook({
    endpoint: 'GET /api/v1/species',
    invokeOnInit: true,
    query: { include: ['strains'] },
    options: { onError: { throw: false, capture: true, toast: false } },
  });
  const { entity: cage, entityLoading: loadingCage } = useFetchEntity<Cage>({
    entityType: 'cage',
    params: { id: cageId, query: 'meta,study.metas,subjects' },
  });
  const loading = loadingMetadata || loadingCage || loadingSpecies;
  useEffect(() => {
    if (!(loadingMetadata || loadingCage || loadingSpecies)) {
      handlePrint();
    }
  }, [loadingMetadata, loadingCage, loadingSpecies]);
  if (loading) {
    return null;
  }

  const selectedMetadata = getCardMetadata(cageCardMetadataIds, metadata as Array<MetadataField>, cage as Cage);
  return (
    <div style={{ display: 'none' }}>
      <span ref={printContentRef}>
        <CageCardsTemplate
          cages={[cage]}
          study={cage?.study?.data}
          selectedMetadata={selectedMetadata}
          species={speciesResponse?.body?.data ?? []}
        />
      </span>
    </div>
  );
};

export default CageCardTemplateWrapper;
