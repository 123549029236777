import { ApiErrorType, default as ApiErrorBanner } from '@/components/ApiErrorBanner/ApiErrorBanner';
import Loading from '@/components/Loading';
import { DataTable } from '@/components/UI/DataTable/DataTable';
import type { DataTableCellAlertUpdateEvent, DataTableApiId } from '@/components/UI/DataTable/DataTable.model';
import {
  DataTableEvent,
  DataTableRowHeaderId,
  DataTableStateChangeEvent,
} from '@/components/UI/DataTable/DataTable.model';
import { DataTableApiService } from '@/components/UI/DataTable/service/DataTableApiService';
import { DataTableService } from '@/components/UI/DataTable/service/DataTableService';
import { _isNil, _notNil } from '@/littledash';
import InVivoError from '@/model/InVivoError.ts';
import type { MetadataField } from '@/model/Metadata.model';
import type { PresetCalculation } from '@/model/PresetCalculation.model';
import type { State } from '@/model/State.model';
import type { Study, StudyApiId } from '@/model/Study.model';
import * as Auth from '@/support/auth';
import { useFetchCollection, useFetchEntity } from '@/support/Hooks/fetch';
import http from '@/support/http';
import { AlertService } from '@/utils/alerts/useAlert';
import { ExceptionHandler } from '@/utils/ExceptionHandler';
import { createContext, FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

interface DataTableCustomColumnSettings {
  metrics?: Array<PresetCalculation>;
  observations?: Array<MetadataField>;
}

export const ColumnContext = createContext<DataTableCustomColumnSettings>({});

export const DisplayDatatable: FC = () => {
  const { id: studyId, dataTableId } = useParams<{ id: StudyApiId; dataTableId: DataTableApiId }>();
  const [dataTableService, setDataTableService] = useState<DataTableService | null>(null);
  const [dataTableError, setDataTableError] = useState(null);
  const [initialising, setInitialising] = useState(true);
  const [columnCount, setColumnCount] = useState<number | undefined>();
  const [rowCount, setRowCount] = useState<number | undefined>();
  const { features } = useSelector(({ team: { features } }: State) => ({
    features,
  }));

  const { entity: study, entityLoading: studyLoading } = useFetchEntity<Study>({
    entityType: 'study',
    params: { id: studyId },
    includes: 'users',
  });
  const studyApiId = study?.api_id;

  const { collection: observations } = useFetchCollection<MetadataField>({
    queryParams: {
      group: 'observations',
    },
    collectionType: 'teamGlossary',
  });

  const columnContext = { metrics: study?.settings.calculations, observations: observations };

  useEffect(() => {
    setInitialising(true);
    if (!studyLoading && _notNil(studyApiId) && _notNil(dataTableId)) {
      const _dataTableService = new DataTableService({
        readonly: !(_notNil(study) && Auth.isWriteUserForStudy(study)),
        apiService: new DataTableApiService({ dataTableId, studyId: studyApiId, axios: http }),
        studyApiId,
        rowHeaders: [
          { id: DataTableRowHeaderId.animalName, title: 'Name', displayed: true },
          {
            id: DataTableRowHeaderId.animalStudyGroup,
            title: 'Study Group',
            displayed: true,
          },
          { id: DataTableRowHeaderId.animalCage, title: 'Cage', displayed: false },
          {
            id: DataTableRowHeaderId.animalTail,
            title: 'Tail',
            displayed: false,
          },
          {
            id: DataTableRowHeaderId.animalEar,
            title: 'Ear',
            displayed: false,
          },
          {
            id: DataTableRowHeaderId.animalTag,
            title: 'Tag',
            displayed: false,
          },
          {
            id: DataTableRowHeaderId.animalDonor,
            title: 'Donor',
            displayed: false,
          },
        ],
        dimensions: {
          cellWidth: 150,
          cellHeight: 27,
          columnHeight: 40,
        },
        featureFlags: {
          observation: features?.datatables_observation ?? false,
          templates: features?.datatables_templates ?? false,
        },
      });
      _dataTableService
        .initialise()
        .then((dts) => {
          setDataTableService(dts);
          setColumnCount(dts.columnCount);
          setRowCount(dts.rowCount);
          dts.validate();
          setInitialising(false);
        })
        .catch((error) => {
          ExceptionHandler.captureException(
            new InVivoError('Could not initialise datatable', {
              cause: error,
              slug: 'datatable-init',
            })
          );
          setDataTableError(error);
        });
      const tableStateChangeListener = (event: DataTableStateChangeEvent) => {
        if (event.detail.type.has('columns')) {
          setColumnCount(_dataTableService.columnCount);
        }
        if (event.detail.type.has('rows')) {
          setRowCount(_dataTableService.rowCount);
        }
      };
      const cellAlertListener = (event: DataTableCellAlertUpdateEvent) => {
        const studyId = study?.id;
        if (event.detail.trigger === 'cell-update' && _notNil(studyId)) {
          Object.values(event.detail.cellAlerts).forEach((cellAlert) => {
            const row = _dataTableService.rowHeader(cellAlert.row);
            if (row?.type === 'animal') {
              AlertService.createAlerts({
                studyId,
                alerts: Array.from(cellAlert.alerts.values()),
                animalName: row?.animal?.name ?? '',
                cageName: row?.animal?.cage?.name ?? '',
                calculations: study?.settings?.calculations ?? [],
              });
            }
          });
        }
      };
      _dataTableService.subscribe(DataTableEvent.TableStateChange, tableStateChangeListener);
      _dataTableService.subscribe(DataTableEvent.CellAlertUpdate, cellAlertListener);
      return () => {
        _dataTableService.unsubscribe(DataTableEvent.TableStateChange, tableStateChangeListener);
        _dataTableService.unsubscribe(DataTableEvent.CellAlertUpdate, cellAlertListener);
        _dataTableService.destroy();
      };
    }
  }, [studyApiId, dataTableId, features]);

  if (_notNil(dataTableError)) {
    return <ApiErrorBanner error={dataTableError} errorType={ApiErrorType.FETCH} />;
  }
  if (initialising) {
    return <Loading />;
  }

  if (_isNil(dataTableService) || _isNil(columnCount) || _isNil(rowCount)) {
    return null;
  }

  return (
    <ColumnContext.Provider value={columnContext}>
      <DataTable dataTableService={dataTableService} columnCount={columnCount} rowCount={rowCount} />
    </ColumnContext.Provider>
  );
};
