import Navigation from '@/components/Navigation/index';
import { RouteProps } from '@/components/Navigation/Navigation.tsx';
import { adminConsoleUserSelector, featuresSelector, teamOwnerSelector } from '@/support/Selectors';
import { createSelector } from '@reduxjs/toolkit';
import { HiCog8Tooth } from 'react-icons/hi2';
import { RiBookmarkLine, RiFile3Line, RiGroupLine, RiShareLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';

const teamNavStateSelector = createSelector(
  [featuresSelector, adminConsoleUserSelector, teamOwnerSelector],
  (features, admin_console_user, teamOwner) => ({
    displayAdminConsole: teamOwner && admin_console_user,
  })
);

const TeamNav = ({ route }: RouteProps) => {
  const { displayAdminConsole } = useSelector(teamNavStateSelector);

  const navigation = [
    {
      name: 'Users',
      webRoute: 'team.manage',
      icon: <RiGroupLine />,
    },
    {
      name: 'Glossary',
      webRoute: '',
      icon: <RiBookmarkLine />,
      children: [
        {
          name: 'Metadata',
          webRoute: 'team.glossary.metadata',
        },
        {
          name: 'Observations',
          webRoute: 'team.glossary.observations',
        },
        {
          name: 'Samples',
          webRoute: 'team.glossary.samples',
        },
        {
          name: 'Deceased',
          webRoute: 'team.glossary.deceased',
        },
        {
          name: 'Species & Strains',
          webRoute: 'team.glossary.species_strains',
        },
        {
          name: 'Treatments',
          webRoute: 'team.glossary.treatments',
        },
        {
          name: 'Presets',
          webRoute: 'team.glossary.presets',
        },
      ],
    },
    {
      name: 'Studies',
      webRoute: '',
      icon: <RiFile3Line />,
      children: [
        {
          name: 'Forms',
          webRoute: 'team.studies.forms',
        },
        {
          name: 'Reviews',
          webRoute: 'team.studies.approvals',
        },
        {
          name: 'Requests',
          webRoute: 'team.studies.requests',
        },
        {
          name: 'Cage cards',
          webRoute: 'team.studies.cage_cards',
        },
        {
          name: 'Groups',
          webRoute: 'team.studies.groups',
        },
        {
          name: 'Study codes',
          webRoute: 'team.studies.study_codes',
        },
      ],
    },
    {
      name: 'Integrations',
      webRoute: '',
      icon: <RiShareLine />,
      children: [
        {
          name: 'Single sign-on',
          webRoute: 'team.integrations.sso',
        },
        {
          name: 'API keys',
          webRoute: 'team.integrations.api',
        },
        {
          name: 'Devices',
          webRoute: 'team.studies.devices',
        },
      ],
    },
    ...(displayAdminConsole
      ? [
          {
            name: 'Admin Console',
            webRoute: '',
            icon: <HiCog8Tooth />,
            children: [
              {
                name: 'Feature Flag Config',
                webRoute: 'team.admin.feature_flags',
              },
              {
                name: 'Bioresearch Config',
                webRoute: 'team.admin.integration',
              },
              {
                name: 'tick@lab Config',
                webRoute: 'team.admin.tickatlab',
              },
            ],
          },
        ]
      : []),
  ] as RouteProps['route']['navigation'];

  return <Navigation route={{ ...route, navigation }} />;
};

export default TeamNav;
