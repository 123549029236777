import Loading from '@/components/Loading';
import Spinner from '@/components/UI/Spinner';
import type { operations } from '@/generated/internal-api/openapi-types';
import { _flatten, _isEmpty, _isNil, _isNotEmpty, _notNil } from '@/littledash';
import type { TaskApiId, TaskV1 } from '@/model/Task.model';
import { usePrevious } from '@/support/Hooks';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { useApiPagination } from '@/support/Hooks/api/useApiPagination';
import { RouteService } from '@/support/RouteService.ts';
import { useModalAction } from '@/utils/modal';
import { useVirtualizer } from '@tanstack/react-virtual';
import _isEqual from 'lodash/isEqual';
import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import type { CalendarFilters, DateRange, StudyColours } from '../../Calendar.model';
import DayCard from './DayCard';

interface DayListProps {
  dateRange: DateRange;
  filters: CalendarFilters;
  studyColours: StudyColours;
  narrow?: boolean;
}

const WIDE_LIST_ITEM_SIZE = 70;
const NARROW_LIST_ITEM_SIZE = 108;
/** @Todo: It would be nice if this was worked out based on container height
 * eg. itemSize (70) / containerHeight (700) = 10 items to call first
 */
const PAGE_SIZE = 25;

const DayList: FC<DayListProps> = ({ dateRange, filters, studyColours, narrow }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [updatedTasks, setUpdatedTasks] = useState<Record<TaskApiId, TaskV1>>({});
  const query = useMemo<operations['get-tasks']['parameters']['query']>(
    () => ({
      sort: 'start',
      order: 'asc',
      perPage: PAGE_SIZE,
      start: dateRange.start,
      end: dateRange.end,
      ...(narrow ? {} : { include: ['target_animal_count'] }),
      ...filters,
    }),
    [dateRange, filters]
  );
  const prevQuery = usePrevious(query);
  const { openModal, closeModal } = useModalAction();

  const {
    response: tasks,
    loading: fetchingTasks,
    invoke: getTasks,
  } = useApiHook({
    endpoint: 'GET /api/v1/tasks',
    query,
  });

  const {
    pages: tasksPages,
    nextPage: nextTasksPage,
    hasNextPage: hasNextTasksPage,
    reset: resetTasks,
  } = useApiPagination({ response: tasks });
  const history = useHistory();
  const rows = useMemo(() => _flatten(tasksPages) ?? [], [tasksPages]);

  const rowVirtualizer = useVirtualizer({
    count: (rows ?? []).length,
    getScrollElement: () => containerRef?.current,
    estimateSize: () => (narrow ? NARROW_LIST_ITEM_SIZE : WIDE_LIST_ITEM_SIZE),
  });

  useEffect(() => {
    if (!fetchingTasks && !_isEqual(query, prevQuery)) {
      resetTasks();
      getTasks({ query });
    }
  }, [prevQuery, resetTasks, getTasks, query, fetchingTasks]);

  const handleScrollBottom = (e: React.UIEvent<HTMLDivElement>): void => {
    if (e.target) {
      const { target } = e;
      if (!(target instanceof HTMLDivElement)) {
        return;
      }
      const { scrollTop, clientHeight, scrollHeight } = target;
      const atBottom = scrollTop + clientHeight >= scrollHeight;
      if (atBottom && !fetchingTasks && hasNextTasksPage) {
        getTasks({
          query: {
            ...query,
            page: nextTasksPage,
          },
        });
      }
    }
  };

  const onTaskUpdate = (task: TaskV1): void => {
    setUpdatedTasks({ ...updatedTasks, [task.id]: task });
  };
  const onTaskExecute = (task: TaskV1) => {
    closeModal();
    if (_notNil(task.study.id) && _notNil(task.id)) {
      history.push(
        RouteService.web({
          route: 'studies.workflow',
          path: { id: task.study.id },
          query: {
            type: 'task-execution',
            taskApiId: task.id,
          },
        }).route
      );
    }
  };

  if (_isNil(tasks)) {
    return <Loading />;
  }

  return (
    <>
      {!fetchingTasks && _isEmpty(rows) && !narrow && <NoEntries />}
      <div
        ref={containerRef}
        className="w-100 h-100 ui__overflow__scroll_y mt2"
        onScroll={handleScrollBottom}
        data-test-component="DayList"
        data-test-element="container"
        data-testid="day-list-container"
      >
        <div
          className="w-100 relative"
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            willChange: 'transform',
          }}
        >
          {_isNotEmpty(rows) &&
            rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const isLoaderRow = virtualRow.index > rows.length - 1;
              const virtualItem: TaskV1 = rows[virtualRow.index];
              const task: TaskV1 = updatedTasks?.[virtualItem.id] ?? virtualItem;
              return (
                <div
                  key={virtualRow.index}
                  className="absolute top-0 left-0 w-100 z-1 ph2 pb2"
                  style={{
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${virtualRow.start}px)`,
                  }}
                >
                  {isLoaderRow ? (
                    <LoadingEntries />
                  ) : (
                    <DayCard
                      task={task}
                      colours={studyColours?.[task.study.api_id]}
                      narrow={narrow}
                      onClick={() =>
                        openModal('TASK_DETAILS', {
                          taskId: task.id,
                          onTaskUpdate,
                          onTaskExecute,
                        })
                      }
                      maxUserBeforeTruncate={narrow ? 3 : 4}
                    />
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

const LoadingEntries = memo(() => (
  <div className="flex items-center justify-center pa3">
    <Spinner size="small" color="" /> <h3 className="f6 lh-title ml2">Loading...</h3>
  </div>
));

const NoEntries = memo(() => (
  <div className="bg-light-gray br2 pa3 ma3 tc" data-testid="no-task-entries">
    <h3 className="lh-title f5 fw5">No tasks for this day</h3>
  </div>
));

export default DayList;
