import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { _notNil } from '@/littledash';
import { ModalContainer, ModalHeader } from '@/utils/modal';
import type { TaskSpec, TaskSpecCreate } from 'model/Task.model';
import { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form@latest';
import { allSteps } from './AddTask';
import {
  AddTaskFormTypes,
  AddTaskProps,
  IStep,
  OptionalStepFormPayloadProps,
  StepFormPayloadProps,
  TaskSpecFieldNames,
} from './AddTask.model';
import styles from './AddTask.module.scss';
import { handleAddTaskStepFormPayload } from './AddTask.utils';
import { editTaskSpecDefaultValues, editTaskSpecStudyCreateDefaultValues } from './EditTask.utils';

export interface EditTaskProps extends Omit<AddTaskProps, 'studyStartedOn'> {
  // Existing task spec
  taskSpec?: TaskSpec;
  // A task spec that is in the process of being created in the Study Creation form
  studyCreationTaskSpec?: TaskSpecCreate;
  disabledSections: AddTaskFormTypes[];
  initialTab?: AddTaskFormTypes;
}

const EditTask: FC<EditTaskProps> = ({
  closeModal,
  taskSpec,
  studyCreationTaskSpec,
  studyId,
  studyApiId,
  studyCreationUsers,
  teamUsers,
  studyCreationGroups,
  execution,
  handleSubmit,
  initialTab,
}) => {
  const { initialValues, steps } = useMemo<{ initialValues: StepFormPayloadProps; steps: Array<IStep> }>(() => {
    const initialValues = _notNil(studyCreationTaskSpec)
      ? editTaskSpecStudyCreateDefaultValues(studyCreationTaskSpec)
      : editTaskSpecDefaultValues(taskSpec as TaskSpec);
    const steps = allSteps.filter((s) => s.display(initialValues.overview.type) ?? false);
    return { initialValues, steps };
  }, [taskSpec, studyCreationTaskSpec]);

  const [stepFormState, setStepFormState] = useState<StepFormPayloadProps>(initialValues);
  const [activeTab, setActiveTab] = useState<number>(0);
  const formMethods = useForm({ defaultValues: stepFormState });
  const { reset: resetForm, trigger, getValues } = formMethods;
  useEffect(() => {
    if (_notNil(initialTab)) {
      setActiveTab(steps.findIndex((step) => step.name === initialTab));
    }
  }, [steps, initialTab]);

  const handleEditTaskFormSubmit = (stepFormData: OptionalStepFormPayloadProps): void => {
    const updatedStepFormPayload: StepFormPayloadProps = { ...stepFormState, ...stepFormData };
    if (_notNil(handleSubmit)) {
      handleSubmit(handleAddTaskStepFormPayload(updatedStepFormPayload));
      closeModal();
    }
  };

  const handleEditTaskTabChange = async (tabIndex: number): Promise<void> => {
    const isValid = await trigger();
    if (isValid) {
      const stepFormData: OptionalStepFormPayloadProps = getValues();
      const updatedStepFormPayload: StepFormPayloadProps = { ...stepFormState, ...stepFormData };
      setStepFormState(updatedStepFormPayload);
      // On tab change we reset the defaultValues cache with the updated form state
      resetForm(updatedStepFormPayload);
      setActiveTab(tabIndex);
    }
  };

  const disabledFields: Set<TaskSpecFieldNames> = useMemo(() => {
    /** When editing a task spec in an existing study, some fields are not yet mutable in the API*/
    if (_notNil(studyApiId)) {
      return new Set([
        'overview.type',
        'schedule.type',
        'schedule.repeat',
        'schedule.repeat.days',
        'schedule.condition.type',
        'target.groups',
        'target.type',
      ]);
    }
    return new Set(['overview.type']);
  }, [studyApiId]);

  return (
    <ModalContainer size="medium">
      <ModalHeader title="Update task" closeModal={closeModal} />
      <Tabs outerState={[activeTab, handleEditTaskTabChange]}>
        <div className="ph3 mv3">
          {steps.map(({ name, title }) => (
            <Tab key={`${name}_tab`} testId={`${name}_tab`}>
              {title}
            </Tab>
          ))}
        </div>
        <FormProvider {...formMethods}>
          {_notNil(taskSpec) || _notNil(studyCreationTaskSpec) ? (
            steps.map((section) => (
              <Panel key={`${section.name}_panel`}>
                <div className={`${styles['step-container']} h-100`}>
                  {section.render({
                    onSubmit: handleEditTaskFormSubmit,
                    onCancel: closeModal,
                    submitButtonText: 'Save',
                    cancelButtonText: 'Close',
                    studyApiId,
                    studyId,
                    studyCreationUsers,
                    teamUsers,
                    studyCreationGroups,
                    execution,
                    disabledFields,
                  })}
                </div>
              </Panel>
            ))
          ) : (
            <div className="bg-light-gray br2 pa3 ma3 tc">
              <h3 className="lh-title f5 fw5">No task spec to show</h3>
            </div>
          )}
        </FormProvider>
      </Tabs>
    </ModalContainer>
  );
};

export default EditTask;
