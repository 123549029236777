import Loading from '@/components/Loading/Loading';
import { ExecutionPanel, TypedTaskData } from '@/components/Modals/TaskDetails/TaskDetailsTabs/ExecutionPanel.tsx';
import { TargetPanel } from '@/components/Modals/TaskDetails/TaskDetailsTabs/TargetPanel';
import { TaskOverdue } from '@/components/Studies/Tasks/Task';
import Button from '@/components/UI/Button';
import { Dropdown, DropdownMenuItem } from '@/components/UI/Dropdown';
import { Panel, Tab, Tabs } from '@/components/UI/Tabs';
import { defaultPromiseErrorHandler, errorToast } from '@/helpers';
import { _isFunction, _isNil, _notNil } from '@/littledash';
import { PickRequired } from '@/model/Common.model';
import { GlossaryItem } from '@/model/Glosary.model.ts';
import { PresetCalculation } from '@/model/PresetCalculation.model.ts';
import { Study } from '@/model/Study.model.ts';
import type { TaskApiId, TaskAssignees, TaskStatus, TaskType, TaskV1 } from '@/model/Task.model';
import { Treatment } from '@/model/Treatment.model.ts';
import type { StudyUserSummaryV1, UserApiId } from '@/model/User.model';
import { ApiService, ApiSuccessResponse } from '@/support/ApiService';
import * as Auth from '@/support/auth';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import useMountedState from '@/support/Hooks/fetch/useMountedState.ts';
import { LegacyApiService } from '@/support/LegacyApiService.ts';
import { ModalActions, ModalContainer } from '@/utils/modal';
import cn from 'classnames';
import { type FC, useEffect, useMemo, useState } from 'react';
import { RiArrowDownSFill } from 'react-icons/ri';
import { AssigneesPanel } from './TaskDetailsTabs/AssigneesPanel';
import { OverviewPanel } from './TaskDetailsTabs/OverviewPanel';
import { isTaskExecutionEnabled } from '@/components/Modals/TaskDetails/TaskDetails.utils.tsx';

export interface TaskDetailsProps {
  taskId: TaskApiId;
  onTaskUpdate: (task: TaskV1) => void;
  onTaskExecute: (task: TaskV1) => void;
  startingTab?: TaskDetailsTabType;
  selectionDisabled?: boolean;
  closeModal: () => void;
}

interface TaskDetailContainerProps {
  task: PickRequired<TaskV1, 'animals'>;
  typedTaskData?: TypedTaskData;
  users: Array<StudyUserSummaryV1>;
  handleTaskUpdate: () => void;
  isWriteUser: boolean;
  startingTab?: TaskDetailsTabType;
}

interface TaskStatusOption {
  value: TaskStatus;
  label: string;
}

export const statusOptions: Record<TaskStatus, TaskStatusOption> = {
  to_do: { value: 'to_do', label: 'To do' },
  in_progress: { value: 'in_progress', label: 'In progress' },
  done: { value: 'done', label: 'Done' },
};
export type TaskDetailsTabType = 'overview' | 'assignees' | 'target' | 'execution';
type TaskDetailsTab = {
  type: TaskDetailsTabType;
  title: string;
  display: (context: { type: TaskType }) => boolean;
};
const taskDetailTabs: Array<TaskDetailsTab> = [
  { type: 'overview', title: 'Overview', display: () => true },
  { type: 'execution', title: 'Execution', display: (context) => context.type !== 'other' },
  { type: 'target', title: 'Target', display: () => true },
  { type: 'assignees', title: 'Assignees', display: () => true },
];

const loadTypedTaskData = async (
  type: TaskType,
  studyId: TaskV1['study']['id'],
  signal: AbortSignal
): Promise<TypedTaskData | undefined> => {
  switch (type) {
    case 'measurement':
      return LegacyApiService.call({
        method: 'GET',
        apiRoute: 'studies.show.p',
        path: { id: studyId },
        options: { onError: { toast: false, slug: 'typed-task-details-study-load' } },
        signal,
      }).then((response) => ({
        type,
        data: new Map<PresetCalculation['id'], PresetCalculation>(
          ((response.body as { data?: Study })?.data?.settings?.calculations ?? []).map((calculation) => [
            calculation.id,
            calculation,
          ])
        ),
      }));
    case 'sample':
      return LegacyApiService.call({
        method: 'GET',
        apiRoute: 'team-glossary.list',
        query: { group: 'samples' },
        options: { onError: { toast: false, slug: 'typed-task-details-samples-load' } },
        signal,
      }).then((response) => ({
        type,
        data: new Map<GlossaryItem['id'], GlossaryItem>(
          ((response?.body as { data?: Array<GlossaryItem> })?.data ?? []).map((calculation) => [
            calculation.id,
            calculation,
          ])
        ),
      }));
    case 'observation':
      return LegacyApiService.call({
        method: 'GET',
        apiRoute: 'team-glossary.list',
        query: { group: 'observations' },
        options: { onError: { toast: false, slug: 'typed-task-details-observations-load' } },
        signal,
      }).then((response) => ({
        type,
        data: new Map<GlossaryItem['id'], GlossaryItem>(
          ((response?.body as { data?: Array<GlossaryItem> })?.data ?? []).map((glossary) => [glossary.id, glossary])
        ),
      }));
    case 'dosing':
      return LegacyApiService.call({
        method: 'GET',
        apiRoute: 'studies.treatments',
        path: { studyId },
        query: { perPage: -1, include: 'metadata' },
        options: { onError: { toast: false, slug: 'typed-task-details-dosing-load' } },
        signal,
      }).then((response) => ({
        type,
        data: new Map<Treatment['api_id'], Treatment>(
          ((response?.body as { data: Array<Treatment> })?.data ?? []).map((treatment) => [treatment.api_id, treatment])
        ),
      }));
  }
};

export const TaskDetails: FC<TaskDetailsProps> = ({ taskId, onTaskUpdate, onTaskExecute, closeModal, startingTab }) => {
  const [typedTaskData, updateTypedTaskData] = useState<TypedTaskData | undefined>();
  const [loading, setLoading] = useState(true);
  const [users, updateUsers] = useState<Array<StudyUserSummaryV1>>([]);
  const isMounted = useMountedState();

  const { response, invoke: reloadTask } = useApiHook({
    endpoint: 'GET /api/v1/tasks/{taskId}',
    path: { taskId },
    query: { include: ['animals'] },
  });

  const isWriteUser = Auth.isWriteUserForStudyUsers(users);

  const task =
    response?.type === 'success'
      ? (response.body as PickRequired<ApiSuccessResponse<'GET /api/v1/tasks/{taskId}'>['body'], 'animals'>)
      : null;
  const taskExecutionEnabled = isTaskExecutionEnabled(task, isWriteUser);
  const handleTaskUpdate = async () => {
    const result = await reloadTask();
    if (result?.type === 'success') {
      onTaskUpdate(result.body);
    }
  };
  const handleTaskExecute = () => {
    if (taskExecutionEnabled && _notNil(task) && _isFunction(onTaskExecute)) {
      onTaskExecute(task);
    }
  };
  useEffect(() => {
    const abortController = new AbortController();
    if (_notNil(task?.type) && _notNil(task?.study?.id) && _notNil(task?.study?.api_id)) {
      setLoading(true);
      Promise.allSettled([
        ApiService.call({
          endpoint: 'GET /api/v1/studies/{studyId}/users',
          path: { studyId: task.study.api_id },
          query: { perPage: -1, status: ['active'] },
          signal: abortController.signal,
        }),
        loadTypedTaskData(task.type, task.study.id, abortController.signal),
      ])
        .then(([userResponse, typedTaskDataResponse]) => {
          if (isMounted()) {
            updateUsers(userResponse.status === 'fulfilled' ? (userResponse?.value?.body?.data ?? []) : []);
            updateTypedTaskData(typedTaskDataResponse.status === 'fulfilled' ? typedTaskDataResponse.value : undefined);
          }
        })
        .catch(defaultPromiseErrorHandler)
        .finally(() => setLoading(false));
    }
    return () => abortController.abort('effect teardown');
  }, [task?.type, task?.study?.id, task?.study?.api_id, isMounted, updateTypedTaskData, updateUsers]);

  if (_isNil(task) || loading) {
    return <Loading />;
  }

  return (
    <ModalContainer size="narrow">
      <TaskDetailsContainer
        task={task}
        typedTaskData={typedTaskData}
        users={users}
        handleTaskUpdate={handleTaskUpdate}
        isWriteUser={isWriteUser}
        startingTab={startingTab}
      />
      <ModalActions
        className="pa3 mt2 bt b--moon-gray"
        submitBtnText={taskExecutionEnabled ? 'Execute task' : undefined}
        onSubmit={taskExecutionEnabled ? handleTaskExecute : undefined}
        onCancel={closeModal}
        cancelBtnText="Close"
      />
    </ModalContainer>
  );
};

export const checkCanTransitionTask = (isWriteUser: boolean, assignees: TaskAssignees, studyActive: boolean) => {
  if (!isWriteUser) {
    const currentUser = Auth.getCurrentUser();
    return (studyActive && assignees?.some(({ id }) => id === currentUser?.api_id)) ?? false;
  }
  return studyActive;
};

const TaskDetailsContainer: FC<TaskDetailContainerProps> = ({
  task,
  startingTab,
  typedTaskData,
  users,
  handleTaskUpdate,
  isWriteUser,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const { tabs, tabTypeToIndex } = useMemo(() => {
    const tabs = taskDetailTabs.filter((t) => t.display({ type: task.type }));
    const tabTypeToIndex = tabs.reduce<Partial<Record<TaskDetailsTabType, number>>>(
      (acc, tab, currentIndex) => ({ ...acc, [tab.type]: currentIndex }),
      {}
    );
    return { tabs, tabTypeToIndex };
  }, [task]);

  useEffect(() => {
    if (_notNil(startingTab) && Object.prototype.hasOwnProperty.call(tabTypeToIndex, startingTab)) {
      setActiveTab(tabTypeToIndex?.[startingTab] ?? 0);
    }
  }, [tabTypeToIndex, startingTab, setActiveTab]);

  const taskTransitionDisabled = !checkCanTransitionTask(
    isWriteUser,
    task.assignees ?? [],
    task.study?.active ?? false
  );
  const { invoke: updateStatus } = useApiHook({
    endpoint: 'PATCH /api/v1/studies/{studyId}/tasks/{taskId}/transition',
    path: { studyId: task.study.api_id, taskId: task.id },
    invokeOnInit: false,
  });
  const { invoke: updateAssignees } = useApiHook({
    endpoint: 'PATCH /api/v1/studies/{studyId}/tasks/{taskId}/assign',
    path: { studyId: task.study.api_id, taskId: task.id },
    invokeOnInit: false,
  });
  const handleTransition = async (status: TaskStatus) => {
    await updateStatus({
      path: { studyId: task.study.api_id, taskId: task.id },
      body: { status },
    });
    await handleTaskUpdate();
  };
  const handleAssigneeUpdate = async (selection: UserApiId[]) => {
    try {
      await updateAssignees({
        path: { studyId: task.study.api_id, taskId: task.id },
        body: { assignees: selection },
      });
      await handleTaskUpdate();
    } catch (e) {
      errorToast('Error updating task assignees');
    }
  };

  return (
    <>
      <div className="flex pa3 w-100 justify-between center">
        <div className="flex items-center">
          {task.overdue && task.status !== 'done' && <TaskOverdue />}
          <p className="fw5 f5 near-black">{task.title}</p>
        </div>
        <Dropdown
          renderMenu={() => (
            <>
              {Object.values(statusOptions).map((option) => (
                <DropdownMenuItem key={option.value} onClick={() => handleTransition(option.value)}>
                  {option.label}
                </DropdownMenuItem>
              ))}
            </>
          )}
          disabled={taskTransitionDisabled}
        >
          <Button paleBlue className={cn('ph3 br-pill', { ui__disabled: taskTransitionDisabled })}>
            <div className="flex items-center">
              {statusOptions[task.status].label} {!taskTransitionDisabled ? <RiArrowDownSFill className="ml1" /> : null}
            </div>
          </Button>
        </Dropdown>
      </div>
      <Tabs outerState={[activeTab, setActiveTab]}>
        <div className="pt2 mh4 bb b--moon-gray">
          {tabs.map((tab) => (
            <Tab key={tab.type}>{tab.title}</Tab>
          ))}
        </div>
        {tabs.map((tab) => {
          switch (tab.type) {
            case 'overview':
              return (
                <Panel key={tab.type} style={{ minHeight: '50vh' }}>
                  <OverviewPanel
                    task={task}
                    typedTaskData={typedTaskData}
                    setActiveTab={(nextTab) => setActiveTab((previousTab) => tabTypeToIndex?.[nextTab] ?? previousTab)}
                  />
                </Panel>
              );
            case 'execution':
              return (
                <Panel key={tab.type} className="overflow-scroll" style={{ minHeight: '50vh', maxHeight: '50vh' }}>
                  <ExecutionPanel task={task} typedTaskData={typedTaskData} />
                </Panel>
              );
            case 'target':
              return (
                <Panel key={tab.type} className="overflow-scroll" style={{ minHeight: '50vh', maxHeight: '50vh' }}>
                  <TargetPanel task={task} />
                </Panel>
              );
            case 'assignees':
              return (
                <Panel key={tab.type} style={{ minHeight: '50vh' }}>
                  <AssigneesPanel
                    task={task}
                    handleAssigneesChange={handleAssigneeUpdate}
                    selectionDisabled={task.status === 'done' || !isWriteUser}
                    users={users}
                  />
                </Panel>
              );
          }
          return null;
        })}
      </Tabs>
    </>
  );
};
