import { DataTableTemplateApiId } from '@/model/DataTable.model.ts';
import { FC } from 'react';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { useApiHook } from '@/support/Hooks/api/useApiHook.ts';
import ApiErrorBanner from '@/components/ApiErrorBanner';
import { errorToast, successToast } from '@/helpers.tsx';
import { useForm } from 'react-hook-form@latest';
import { ErrorMessage } from '@hookform/error-message';
import InVivoError from '@/model/InVivoError.ts';
import { ExceptionHandler } from '@/utils/ExceptionHandler.ts';

export interface RenameDataTableTemplateProps {
  dataTableTemplateName: string;
  dataTableTemplateApiId: DataTableTemplateApiId;
  closeModal: () => void;
  fetchTableData: () => Promise<void>;
}

interface FormValues {
  name: string;
}

const RenameDataTableTemplate: FC<RenameDataTableTemplateProps> = ({
  dataTableTemplateName,
  dataTableTemplateApiId,
  closeModal,
  fetchTableData,
}) => {
  return (
    <ModalContainer size="narrow">
      <ModalHeader title="Edit Template name" closeModal={closeModal} className="pa3 bb b--moon-gray bg-white" />
      <RenameDataTableTemplateForm
        dataTableTemplateName={dataTableTemplateName}
        closeModal={closeModal}
        dataTableTemplateApiId={dataTableTemplateApiId}
        fetchTableData={fetchTableData}
      />
    </ModalContainer>
  );
};

const RenameDataTableTemplateForm: FC<RenameDataTableTemplateProps> = ({
  dataTableTemplateName,
  dataTableTemplateApiId,
  closeModal,
  fetchTableData,
}) => {
  const {
    invoke: patchTemplate,
    response: templatePATCHResponse,
    loading: patchingTemplate,
  } = useApiHook({
    endpoint: 'PATCH /api/v1/datatables/templates/{datatableTemplateApiId}/rename',
    path: { datatableTemplateApiId: dataTableTemplateApiId },
    invokeOnInit: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { name: dataTableTemplateName },
  });
  const onSubmit = async (formValues: FormValues): Promise<void> => {
    try {
      await patchTemplate({
        path: { datatableTemplateApiId: dataTableTemplateApiId },
        body: formValues,
      });
      closeModal();
      fetchTableData();
      successToast('Template updated successfully');
    } catch (err) {
      const ivError = new InVivoError('Could not rename data table template', {
        slug: 'rename-data-table-template',
        cause: err,
      });
      ExceptionHandler.captureException(ivError);
      errorToast('Could not rename data table template');
    }
  };
  return (
    <form>
      {templatePATCHResponse?.type === 'error' && <ApiErrorBanner />}
      <div className="pa3">
        <label>Name</label>
        <input
          type="text"
          id="name"
          data-testid={'template-name-input'}
          style={{ marginBottom: 0 }}
          {...register('name', {
            required: 'Name is required',
            maxLength: { value: 255, message: 'Maximum of 255 characters' },
          })}
          autoFocus
        />
        <ErrorMessage
          errors={errors}
          name="name"
          render={({ message }) => <small className="red db pt2">{message}</small>}
        />
      </div>
      <ModalActions
        submitButtonProps={{ disabled: patchingTemplate || templatePATCHResponse?.type === 'error' }}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={closeModal}
        className="pa3 bt b--moon-gray"
      />
    </form>
  );
};

export default RenameDataTableTemplate;
