// @ts-nocheck: converted from JS

import ApiErrorBanner from '@/components/ApiErrorBanner';
import Loading from '@/components/Loading';
import { classNames, errorToast, successToast } from '@/helpers';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { ErrorMessage } from '@hookform/error-message';
import type { FC } from 'react';
//@ts-expect-error - old hook form - replace with `react-hook-form@latest`
import { useForm } from 'react-hook-form';
import InVivoError from '@/model/InVivoError.ts';
import { ExceptionHandler } from '@/utils/ExceptionHandler.ts';

interface EditDatatableProps {
  studyId: string;
  dataTableId: string;
  closeModal: () => void;
  fetchTableData: () => Promise<void>;
}

interface FormValues {
  name: string;
}

const EditDatatable: FC<EditDatatableProps> = ({ studyId, dataTableId, closeModal, fetchTableData }) => {
  const { response: datatableGETResponse, loading: fetchingDatatable } = useApiHook({
    endpoint: 'GET /api/v1/studies/{studyApiId}/datatables/{dataTableApiId}',
    path: { studyApiId: studyId, dataTableApiId: dataTableId },
  });

  if (datatableGETResponse?.type === 'error') {
    closeModal();
    return null;
  }

  return (
    <ModalContainer size="narrow">
      <ModalHeader
        title="Create template from data table"
        closeModal={closeModal}
        className="pa3 bb b--moon-gray bg-white"
      />
      {fetchingDatatable && !datatableGETResponse?.body ? (
        <Loading />
      ) : (
        <EditDatatableForm
          closeModal={closeModal}
          studyId={studyId}
          datatable={datatableGETResponse?.body}
          fetchTableData={fetchTableData}
        />
      )}
    </ModalContainer>
  );
};

const EditDatatableForm: FC<EditDatatableProps> = ({ studyId, datatable, closeModal, fetchTableData }) => {
  const {
    invoke: patchDatatable,
    response: datatablePATCHResponse,
    loading: patchingDatatable,
  } = useApiHook({
    endpoint: 'PATCH /api/v1/studies/{studyApiId}/datatables/{dataTableApiId}',
    path: { studyApiId: studyId, dataTableApiId: datatable?.id },
    invokeOnInit: false,
  });
  const { register, errors, handleSubmit } = useForm({
    defaultValues: { name: datatable?.name ?? '', unit: datatable?.unit },
  });
  const onSubmit = async (formValues: FormValues): Promise<void> => {
    try {
      await patchDatatable({
        endpoint: 'PATCH /api/v1/studies/{studyApiId}/datatables/{dataTableApiId}',
        path: { studyApiId: studyId, dataTableApiId: datatable?.id },
        body: formValues,
      });
      await fetchTableData();
      closeModal();
      successToast('Updated datatable');
    } catch (err) {
      const ivError = new InVivoError('Could not edit data table', {
        slug: 'edit-data-table',
        cause: err,
      });
      ExceptionHandler.captureException(ivError);
      errorToast('Could not edit data table');
    }
  };
  return (
    <>
      {datatablePATCHResponse?.type === 'error' && <ApiErrorBanner />}
      <div className="pa3">
        <label>Name</label>
        <input
          type="text"
          name="name"
          ref={register({
            required: 'This field is required',
            maxLength: { value: 255, message: 'Maximum of 255 characters' },
          })}
          style={{ marginBottom: 0 }}
          className={classNames('', { input__error: errors?.name })}
          autoFocus
        />
        <ErrorMessage
          errors={errors}
          name="name"
          render={({ message }) => <small className="red db pt2">{message}</small>}
        />
      </div>
      {datatable.type === 'numeric' && (
        <div className="ph3 pb3">
          <label>Unit</label>
          <input
            ref={register({
              required: 'This field is required',
              maxLength: { value: 255, message: 'Maximum of 255 characters' },
            })}
            type="text"
            name="unit"
            style={{ marginBottom: 0 }}
            className={classNames('', { input__error: errors?.unit })}
          />
          <ErrorMessage
            errors={errors}
            name="unit"
            render={({ message }) => <small className="red db pt2">{message}</small>}
          />
        </div>
      )}
      <ModalActions
        submitButtonProps={{ disabled: patchingDatatable || datatablePATCHResponse?.type === 'error' }}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={closeModal}
        className="pa3 bt b--moon-gray"
      />
    </>
  );
};

export default EditDatatable;
