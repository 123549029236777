import Loading from '@/components/Loading';
import Button from '@/components/UI/Button';
import { Dropdown, DropdownMenuDivider, DropdownMenuItem } from '@/components/UI/Dropdown';
import APITable from '@/components/UI/Table/Reusable/APITable';
import type { Columns } from '@/components/UI/Table/TableComponent.model';
import { capitalise, errorToast, successToast } from '@/helpers';
import { _isNil } from '@/littledash';
import { DataTableTemplateApiId, DataTableTemplateInfoV1, DataTableTemplateVisibility } from '@/model/DataTable.model';
import type { Study } from '@/model/Study.model';
import { RiMoreFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Auth from '@/support/auth';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { RouteService } from '@/support/RouteService';
import { useModalAction } from '@/utils/modal';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import UserAvatar from '@/components/UI/UserAvatar';
import { State } from '@/model/State.model.ts';
import { FC, useMemo } from 'react';
import InVivoError from '@/model/InVivoError.ts';
import { ExceptionHandler } from '@/utils/ExceptionHandler.ts';
import { useLegacyApiHook } from '@/support/Hooks/api/useLegacyApiHook.ts';

export const DataTableTemplatesList = () => {
  const { openModal } = useModalAction();
  const { id: studyId } = useParams<{ id: string }>();
  // TODO: Implement navigation to create data table template
  const navigateToCreateDataTableTemplate = () => {};

  const { response: studyResponse, loading: studyLoading } = useLegacyApiHook({
    method: 'GET',
    apiRoute: 'studies.show.p',
    path: { id: studyId },
    query: { include: 'users' },
    options: { onError: { toast: true, slug: 'study-load' } },
  });

  const study = (studyResponse?.body as { data: Study })?.data;

  const isStudyWriteUser = Auth.isWriteUserForStudy(study as Study);
  const isTenantAdminUser = Auth.isCurrentTeamOwner();

  const { invoke: invokeDeleteDataTableTemplate } = useApiHook({
    endpoint: 'DELETE /api/v1/datatables/templates/{datatableTemplateApiId}',
    path: { datatableTemplateApiId: 'dtt_empty' },
    invokeOnInit: false,
  });
  const settings = useSelector((state: State) => state.ui.settings);

  const columns: Columns<DataTableTemplateInfoV1> = useMemo(
    () => [
      {
        id: 'name',
        Header: 'Name',
        accessor: 'name',
        sortBy: 'name',
        isVisible: true,
      },
      {
        id: 'created_at',
        Header: 'Created on',
        accessor: 'created_at',
        sortBy: 'created_at',
        isVisible: true,
        Cell: ({ row: { original } }) => (original ? <DateTimeRenderer value={original.created_at} /> : null),
      },
      {
        id: 'type',
        Header: 'Type',
        accessor: 'type',
        sortBy: 'type',
        isVisible: true,
        sortDisabled: true,
      },
      {
        id: 'created_by',
        Header: 'Created by',
        isVisible: true,
        sortBy: 'created_by',
        accessor: 'created_by',
        Cell: ({ row: { original } }) =>
          original && original.created_by ? (
            <UserAvatar user={original.created_by} className="ml1 dib" tooltip={original.created_by.name} />
          ) : null,
      },
      {
        id: 'updated_at',
        Header: 'Updated on',
        accessor: 'updated_at',
        sortBy: 'updated_at',
        isVisible: true,
        Cell: ({ row: { original } }) => (original ? <DateTimeRenderer value={original.updated_at} /> : null),
      },
      {
        id: 'visibility',
        Header: 'Visibility',
        accessor: 'visibility',
        sortBy: 'visibility',
        sortDisabled: true,
        isVisible: true,
        Cell: ({ row: { original } }) => (original ? capitalise(original.visibility) : null),
      },
      {
        id: 'actions',
        sortDisabled: true,
        isVisible: isStudyWriteUser,
        width: 35,
        Cell: ({ apiTable: { fetchTableData }, row: { original } }) =>
          original ? (
            <TemplateListActions
              templateApiId={original.api_id}
              state={original.visibility as DataTableTemplateVisibility}
              name={original.name}
              fetchTableData={fetchTableData}
            />
          ) : null,
      },
    ],
    [isTenantAdminUser, isStudyWriteUser, study]
  );

  interface TemplateListActionProps {
    templateApiId: DataTableTemplateApiId;
    state: DataTableTemplateVisibility;
    name: string;
    fetchTableData: () => void;
  }

  const TemplateListActions: FC<TemplateListActionProps> = ({ templateApiId, state, name, fetchTableData }) => {
    if (!isTenantAdminUser && state === 'published') {
      return null;
    }

    return (
      <Dropdown
        renderMenu={() => (
          <>
            <DropdownMenuItem
              onClick={() => handleDataTableTemplateEdit(name, templateApiId, fetchTableData as () => Promise<void>)}
            >
              Edit
            </DropdownMenuItem>
            <DropdownMenuDivider />
            <DropdownMenuItem
              onClick={() => handleDataTableTemplateDelete(templateApiId, fetchTableData as () => Promise<void>)}
            >
              <span className="red">Delete</span>
            </DropdownMenuItem>
          </>
        )}
      >
        <RiMoreFill size={22} />
      </Dropdown>
    );
  };

  const handleDataTableTemplateDelete = (
    dataTableTemplateApiId: DataTableTemplateApiId,
    fetchTableData: () => Promise<void>
  ): void => {
    openModal('CONFIRM_DELETE_DATATABLE_TEMPLATE', {
      onClick: async () => {
        try {
          await invokeDeleteDataTableTemplate({
            path: { datatableTemplateApiId: dataTableTemplateApiId },
          });
          await fetchTableData();
          successToast('Deleted template');
        } catch (err) {
          const ivError = new InVivoError('Could not delete data table template', {
            slug: 'delete-data-table-template',
            cause: err,
          });
          ExceptionHandler.captureException(ivError);
          errorToast('Could not delete data table template');
        }
      },
    });
  };

  const handleDataTableTemplateEdit = (
    dataTableTemplateName: string,
    dataTableTemplateApiId: DataTableTemplateApiId,
    fetchTableData: () => Promise<void>
  ): void => {
    openModal('RENAME_DATA_TABLE_TEMPLATE', {
      dataTableTemplateName: dataTableTemplateName,
      studyApiId: study?.api_id ?? '',
      dataTableTemplateApiId,
      fetchTableData,
    });
  };

  if (studyLoading || _isNil(study)) {
    return <Loading txt="Loading templates" />;
  }

  return (
    <div className="h-100">
      <div className="">
        <APITable
          apiInfo={{
            type: 'internalApi',
            route: RouteService.api({
              endpoint: 'GET /api/v1/datatables/templates',
              path: undefined,
            }).url,
          }}
          columns={columns}
          defaultSortBy={{ id: 'created_at', desc: false }}
          searchPlaceholderText="Search"
          settings={settings}
          reduxTableName={'dataTableTemplates'}
          testIdPrefix={'dataTableTemplates'}
          AsideComponent={() =>
            isStudyWriteUser ? (
              <Button testId="create-new-datatable-template" onClick={navigateToCreateDataTableTemplate}>
                Create new template
              </Button>
            ) : null
          }
        />
      </div>
    </div>
  );
};
