import { RandomizationAttributes } from '@/components/Studies/Randomization/Report/Report.model';
import Chip from '@/components/UI/Chip/Chip';
import GroupLabel from '@/components/UI/GroupLabel';
import type { Column } from '@/components/UI/Table/TableComponent.model';
import {
  calculateAge,
  formatNumber,
  hasOwnProperty,
  roundToTwo,
  standardDeviation,
  standardErrorOfMean,
} from '@/helpers';
import { _get, _isEmpty, _isNil, _isNumber, _notNil } from '@/littledash';
import type { Animal } from '@/model/Animal.model';
import { PresetCalculation } from '@/model/PresetCalculation.model';
import { DateUtils } from '@/utils/Date.utils';
import _set from 'lodash/set';
import kmeans, { ClusteringOutput } from 'node-kmeans';
import { TbInfoCircle } from 'react-icons/tb';
import { mean, median, medianAbsoluteDeviation, shuffle } from 'simple-statistics';
import { RandomizeAnimal } from './Randomize';
import type {
  AllocationResult,
  AttributeCapacity,
  RandomizationResult,
  RandomizationResultFlattened,
  RandomizationTableData,
  RandomizeMetric,
  RandomizeOptions,
  RandomizeOutput,
  RandomizeState,
  ResultsTable,
  SubjectAttribute,
} from './Randomize.model';
import { calculatePValueAndAnova } from './Statistics';
import type { AnovaMetric as AnovaMetricProps, OneWayAnovaResults } from './Statistics.model';

interface Metric {
  accessor: string;
}

export const subjectAttrs: Array<SubjectAttribute> = [
  {
    id: 'sex',
    Header: 'Sex',
    accessor: 'sex',
  },
  {
    id: 'donor_id',
    Header: 'Donor ID',
    accessor: 'alt_ids.donor',
  },
  {
    id: 'dob',
    Header: 'Date of birth',
    accessor: 'dob',
  },
];

export const mappedAttrs = subjectAttrs.reduce<Record<string, SubjectAttribute>>((acc, att) => {
  acc[att.id] = att;
  return acc;
}, {});

export const constructCohorts = (attr: Column<RandomizationTableData>, subjects: Animal[]) => {
  if (!_isEmpty(attr)) {
    const result: Animal[][] = [];
    const uniqueAttrs = [...new Set(subjects.map((s) => _get(s, attr.accessor)))];
    uniqueAttrs.map((a) => {
      const cohort = subjects.filter((s) => _get(s, attr.accessor) === a);
      return result.push(cohort);
    });

    return result;
  } else {
    return [subjects];
  }
};

export const constructVectors = (subjects: Animal[], options: Metric[]): number[][] => {
  return subjects.reduce<number[][]>((acc, v) => {
    const vectors: number[] = [];
    options.forEach((o) => {
      const attr = _get(v, o.accessor);
      if (attr) {
        vectors.push(attr);
      }
    });
    acc.push(vectors);

    return acc;
  }, []);
};

export const clusterData = (vectors: number[][], k = 4): Promise<ClusteringOutput[]> => {
  return new Promise((resolve, reject) => {
    kmeans.clusterize(vectors, { k: k > vectors.length ? vectors.length : k }, (err, res) => {
      if (res) {
        resolve(res);
      } else {
        reject(new Error('Could not cluster data', { cause: err }));
      }
    });
  });
};

export const clusterCohort = async (cohort: Animal[], cohortLength: number, metrics: Metric[], kSize: number) => {
  const vectors = constructVectors(cohort, metrics);
  const kmeansResults = await clusterData(vectors, cohortLength < 4 ? 2 : kSize);
  const clusters = kmeansResults.reduce<number[][]>((acc, v, i) => {
    acc[i] = v.clusterInd;
    return acc;
  }, []);

  return clusters.map((c) => shuffle(c));
};

export const distributeByMetrics = async (
  groups: readonly RandomizationResult[],
  cohorts: Readonly<Array<Array<number>>>,
  animals: readonly Animal[][],
  metrics: Array<RandomizeMetric>,
  kSize: number
): Promise<RandomizationResult[]> => {
  const updatedGroups = [...groups];
  const subjectStore = [...animals];
  const remainingCohorts = [...cohorts];

  let groupCount = Math.floor(Math.random() * (updatedGroups.length - 1));

  for (let index = 0; index < cohorts.length; index++) {
    const store = remainingCohorts[index];
    const clusters = await clusterCohort(subjectStore[index], store?.length ?? 0, metrics, kSize);
    const flattenedClusters = clusters.flat() ?? [];

    while (
      store.length > 0 &&
      updatedGroups.some((group) => group.cohort_subject_ids[index].length < group.attrCapacities[index].capacity)
    ) {
      const group = updatedGroups[groupCount];
      const groupIndex = updatedGroups.findIndex((g) => g.id === group.id);
      const subject = subjectStore[index][flattenedClusters[0]];

      if (group.cohort_subject_ids[index].length < group.attrCapacities[index].capacity) {
        updatedGroups[groupIndex].cohort_subject_ids[index].push(subject.id as number);
        flattenedClusters.shift();
        store.splice(
          store.findIndex((s) => s === subject.id),
          1
        );
      }

      if (groupCount + 1 !== groups.length) {
        groupCount++;
      } else {
        groupCount = 0;
      }
    }
  }

  return updatedGroups;
};

export const distributeByAttribute = (
  groups: readonly RandomizationResult[],
  cohorts: Readonly<Array<Array<number>>>
): RandomizationResult[] => {
  const remainingCohorts = [...cohorts];
  const shuffledGroups = shuffle([...groups]);

  return shuffledGroups.map((group) => {
    const result = {
      ...group,
      cohort_subject_ids: [...group.cohort_subject_ids],
    };

    shuffle([...group.attrCapacities]).forEach((attr) => {
      const cohort = remainingCohorts[attr.cohortIndex];
      if (cohort?.length) {
        result.cohort_subject_ids[attr.cohortIndex] = cohort.splice(0, Math.min(attr.capacity, cohort.length));
      }
    });

    return result;
  });
};

/**
 * @randomiseSubjects
 * ———
 * Attributes: Sex, donor ID, strain
 * Metrics: Tumour volume, weight, glucose (This is what we cluster by)
 * Cohort: Subjects grouped by their unique selected attribute (defaults to 1 if NONE is selected)
 *
 * Outcome:
 * ———
 * The goal of this function is to have fair distributions of attributes and a variety of weighted metric(s) in each group
 *
 * Steps:
 * ———
 * 1. Loop through each cohort and k-means Cluster the subjects values in that cohort,
 * 2. Loop through each group picking a value from each cluster
 *
 **/

export const randomiseSubjects = async (
  subjects: Animal[],
  state: RandomizeOptions
): Promise<RandomizationResultFlattened[]> => {
  const { groups, metrics, attr, kSize } = state;

  const animals = constructCohorts(attr, subjects);
  const cohorts = animals.map((c) => c.map((s) => Number(s.id)));

  const initialGroups = calculateAttributeSizes(groups, cohorts);

  const updatedGroups =
    metrics.length > 0
      ? await distributeByMetrics(initialGroups, cohorts, animals, metrics, kSize)
      : distributeByAttribute(initialGroups, cohorts);

  return updatedGroups.map((g) => ({
    ...g,
    cohort_subject_ids: g.cohort_subject_ids.flat(),
  }));
};

export const allAttrVariantsPresent = (attr: Partial<SubjectAttribute>, subjects: Animal[]) => {
  const subjectsWithNoVariantPresent = subjects.filter((s) => !_get(s, attr.accessor));
  return _isEmpty(subjectsWithNoVariantPresent);
};

const resultTableColumns = (
  selectedAttr: Column<RandomizationTableData>,
  selectedMetrics: Array<RandomizeMetric> = [],
  pValues: Record<string, OneWayAnovaResults> = {},
  openModal: (modal: any, props: any) => void,
  closeModal: () => void
) => {
  let columns: Column<RandomizationTableData>[] = [
    {
      id: 'group',
      Header: 'Group',
      accessor: 'name',
      Cell: ({ row: { original } }) => {
        if (hasOwnProperty(original, 'group')) {
          return <GroupLabel group={original.group} />;
        }

        return original.name;
      },
    },
    {
      id: 'population',
      Header: 'Population',
      accessor: 'size',
      width: 100,
    },
  ];

  if (!_isEmpty(selectedMetrics)) {
    columns = [
      ...columns,
      ...selectedMetrics.reduce<Column<RandomizationTableData>[]>((acc, metric) => {
        if (!metric.isExclusion) {
          const pValue = pValues[metric.accessor.split('.')[1]]?.pvalue ?? 0;
          acc.push({
            ...metric,
            Header: (
              <div>
                <div>{metric.Header}</div>
                <div className="dark-gray normal f6 flex flex-row">
                  {_notNil(pValue) && _isNumber(pValue) ? `P = ${pValue?.toFixed(4)}` : 'No P Value'}
                  {
                    <a
                      className="flex items-center ml1"
                      onClick={() => {
                        openModal('ONEWAYANOVA_TABLE', {
                          oneWayAnova: pValues[metric.accessor.split('.')[1]],
                          closeModal: closeModal,
                        });
                      }}
                    >
                      <TbInfoCircle />
                    </a>
                  }
                </div>
              </div>
            ),
          });
        }
        return acc;
      }, []),
    ];
  }

  if (!_isEmpty(selectedAttr)) {
    columns.push({
      ...selectedAttr,
      width: 350,
      Cell: ({ row: { original } }) => {
        const attributes = _get(original, selectedAttr.accessor);
        if (typeof attributes === 'object') {
          return (
            <>
              {Object.keys(attributes)
                .sort()
                .map((k, i) => {
                  const title =
                    selectedAttr?.id === RandomizationAttributes.dob
                      ? `${k} (${calculateAge(k, DateUtils.dateNow(), true)})`
                      : k;
                  return <Chip key={k} className={`${i !== 0 ? 'ml2' : ''}`} title={title} value={attributes[k]} />;
                })}
            </>
          );
        }
        return attributes || '';
      },
    });
  }

  return columns;
};

const accessor = (m: string, average?: string) => {
  return `significantMeasurement.${m}.${average ?? 'value'}`;
};

const valueAccessor = (m: string) => {
  return `significantMeasurement.${m}.value`;
};

export const subjectMetrics = (calculations: PresetCalculation[], averageType = 'mean'): RandomizeMetric[] => {
  return calculations.map((m) => ({
    Header: m.name,
    id: m.id,
    accessor: accessor(m.id, averageType) as keyof Animal,
    align: 'right',
    Cell: ({ row: { original } }: { row: { original: RandomizationTableData } }) => {
      const valuePath = accessor(m.id, averageType);
      // This is used for the animal subrow, which uses the value property from PHP
      const value = formatNumber(_get(original, valuePath) ?? _get(original, accessor(m.id, 'value')), true);
      const deviationType = averageType === 'mean' ? 'sd' : 'mad';
      if (Object.prototype.hasOwnProperty.call(original, deviationType)) {
        const getSDValue = _get(original[deviationType], valuePath);
        return (
          <>
            <span className="dib mr2">{value}</span>
            {_notNil(getSDValue) && <span>±{formatNumber(getSDValue, true)}</span>}
          </>
        );
      }

      return value;
    },
  }));
};

export const subjectValueMetrics = (calculations: PresetCalculation[], averageType = 'mean'): RandomizeMetric[] => {
  return calculations.map((m) => ({
    Header: m.name,
    id: m.id,
    accessor: valueAccessor(m.id) as keyof Animal,
    align: 'right',
    Cell: ({ row: { original } }: { row: { original: RandomizationTableData } }) => {
      const valuePath = valueAccessor(m.id);
      // This is used for the animal subrow, which uses the value property from PHP
      const value = formatNumber(_get(original, valuePath) ?? _get(original, valueAccessor(m.id)), true);
      const deviationType = averageType === 'mean' ? 'sd' : 'mad';
      if (Object.prototype.hasOwnProperty.call(original, deviationType)) {
        const getSDValue = _get(original[deviationType], valuePath);
        return (
          <>
            <span className="dib mr2">{value}</span>
            {_notNil(getSDValue) && <span>±{formatNumber(getSDValue, true)}</span>}
          </>
        );
      }

      return value;
    },
  }));
};

export const animalsWithSignificantMeasurement = (
  animals: Animal[],
  measurementsOnDate: RandomizeState['randomizeByDate']['measurementsOnDate'] = null
): RandomizeAnimal[] => {
  return animals.map((animal: Animal) => ({
    ...animal,
    significantMeasurement: _notNil(measurementsOnDate)
      ? (measurementsOnDate?.[animal.api_id ?? ''] ?? null)
      : animal.latestMeasurement,
  }));
};

export const resultsTableTransformer = (
  results: RandomizationResultFlattened[],
  subjects: Animal[] = [],
  selectedAttr: Column<RandomizationTableData>,
  selectedMetrics: RandomizeMetric[] = [],
  sd = 'sd',
  openModal: (modal: any, props: any) => void,
  closeModal: () => void,
  averageType = 'mean'
): ResultsTable => {
  const anovaMetrics: Record<string, AnovaMetricProps> = {};
  const tableData = results.reduce<{ data: RandomizationTableData[] }>(
    (acc, v, i) => {
      if (_isEmpty(v.cohort_subject_ids ?? [])) {
        return acc;
      }
      const groupedSubjects = subjects.filter((s) => v.cohort_subject_ids.includes(s.id as number));
      const group: ResultsTable['data'][number] = {
        group: v,
        name: v.name,
        size: (v.animals_count ?? 0) + v.cohort_subject_ids.length,
        sd: { significantMeasurement: {} },
        mad: { significantMeasurement: {} },
        sex: v.sex ?? {},
        significantMeasurement: {},
        subRows: groupedSubjects.map((s) => ({
          name: s.name,
          sex: s.sex === 'm' ? 'Male' : 'Female',
          dob: s.dob,
          alt_ids: s.alt_ids,
          significantMeasurement: s.significantMeasurement,
        })),
      };

      if (!_isEmpty(selectedAttr)) {
        const allAttrs = group.subRows.map((s) => _get(s, selectedAttr.accessor));
        const counts = allAttrs.reduce(
          (acc, value) => ({
            ...acc,
            [value]: (acc[value] || 0) + 1,
          }),
          {}
        );
        _set(group, selectedAttr.accessor as string, counts);
      }

      if (!_isEmpty(selectedMetrics)) {
        selectedMetrics.forEach((metric) => {
          const groupMetrics = group.subRows.map((s) => Number(_get(s, accessor(metric.id))));
          if (!metric.isExclusion) {
            if (_notNil(anovaMetrics[metric.accessor])) {
              anovaMetrics[metric.accessor].push(groupMetrics);
            } else {
              anovaMetrics[metric.accessor] = [groupMetrics];
            }
          }

          switch (averageType) {
            case 'mean': {
              const sdCalc = standardDeviation(groupMetrics);
              if (!isNaN(sdCalc)) {
                _set(group.sd, metric.accessor, sdCalc);
                if (sd !== 'sd') {
                  _set(group.sd, metric.accessor, standardErrorOfMean(sdCalc, groupMetrics.length));
                }
              }
              const meanValue = groupMetrics.length >= 2 ? roundToTwo(mean(groupMetrics)) : groupMetrics[0];
              _set(group, metric.accessor, meanValue);
              break;
            }
            case 'median': {
              const madCalc = medianAbsoluteDeviation(groupMetrics);
              if (!isNaN(madCalc)) {
                _set(group.mad, metric.accessor, madCalc);
              }
              const medianValue = groupMetrics.length >= 2 ? roundToTwo(median(groupMetrics)) : groupMetrics[0];
              _set(group, metric.accessor, medianValue);
              break;
            }
          }
        });
      }

      acc.data[i] = group;

      return acc;
    },
    {
      data: [],
    }
  );

  const oneWayAnovaResults = Object.entries(anovaMetrics).reduce<Record<string, OneWayAnovaResults>>(
    (acc, [metric, anova]) => {
      acc[metric.split('.')[1]] = calculatePValueAndAnova(anova);
      return acc;
    },
    {}
  );

  return {
    ...tableData,
    oneWayAnovaResults,
    columns: resultTableColumns(selectedAttr, selectedMetrics, oneWayAnovaResults, openModal, closeModal),
  };
};

export const metricPresentAcrossAllAnimals = (animals: RandomizeAnimal[], metricName: string): boolean => {
  return animals.every((a) => a.significantMeasurement?.[metricName]);
};

/** Returns metrics that are only present across all animals */
export const enabledMetricsForRandomize = (
  animals: RandomizeAnimal[],
  metrics: PresetCalculation[]
): PresetCalculation[] => {
  return metrics.filter((m) => metricPresentAcrossAllAnimals(animals, m.id));
};

export const consolidateAttributeValues = (randomize: RandomizeOutput): Array<RandomizationTableData> => {
  const { selectedAttribute, tableData } = randomize;
  const accessor = selectedAttribute.accessor;

  if (!_isNil(accessor)) {
    const distinctValues = new Set<string>();

    tableData?.data.forEach((group) => {
      const groupObject = _get(group, accessor, '');
      const groupValues = Object.keys(groupObject).filter((value) => groupObject[value] !== 0);
      groupValues.forEach((value: string) => distinctValues.add(value));
    });

    const distinctValuesArray = Array.from(distinctValues);

    tableData?.data.forEach((group) => {
      const groupObject = _get(group, accessor, '');
      distinctValuesArray.forEach((key) => {
        if (!(key in groupObject)) {
          groupObject[key] = 0;
        }
      });
    });
  }

  return randomize.tableData?.data ?? [];
};

/**
 * Helper function
 * ———
 * Returns the sum of an array of numbers
 * @param numbers
 */
export const sumArray = (numbers: Array<number>): number => numbers.reduce((a, b) => a + b, 0);

/**
 * Find optimal group for size increase
 * @param groups groups with remaining capacity
 * @returns index of the optimal group
 */
export const findOptimalGroup = (groups: Array<RandomizationResult>): number => {
  let bestIndex = -1;
  let lowestFullness = Infinity;
  let largestOriginalSize = -Infinity;

  for (let i = 0; i < groups.length; i++) {
    const { originalSize, modifiedSize } = groups[i];

    if (modifiedSize >= originalSize) continue;

    const fullness = modifiedSize / originalSize;

    // Update the bestIndex if:
    // 1. The current group has a lower fullness
    // 2. OR the fullness is the same but the group has a larger originalSize
    if (
      bestIndex === -1 ||
      fullness < lowestFullness ||
      (fullness === lowestFullness && originalSize > largestOriginalSize)
    ) {
      bestIndex = i;
      lowestFullness = fullness;
      largestOriginalSize = originalSize;
    }
  }

  return bestIndex;
};
/**
 * Normalize group sizes based on total subjects
 * @param groups groups with original sizes
 * @param totalSubjects total number of subjects
 * @returns groups with normalized sizes
 */
export const normalizeGroupSizes = (
  groups: Readonly<Array<RandomizationResult>>,
  totalSubjects: number
): Array<RandomizationResult> => {
  const groupsCapacity = sumArray(groups.map((g) => g.originalSize));
  const factor = totalSubjects < groupsCapacity ? totalSubjects / groupsCapacity : 1;

  const normalizedGroups = shuffle(
    groups.map((group) => ({
      ...group,
      modifiedSize: Math.floor(group.originalSize * factor),
    }))
  );

  let remaining = totalSubjects - sumArray(normalizedGroups.map((g) => g.modifiedSize));

  while (remaining > 0 && factor < 1) {
    const optimalIndex = findOptimalGroup(normalizedGroups);
    if (optimalIndex === -1) break;

    normalizedGroups[optimalIndex].modifiedSize++;
    remaining--;
  }

  return normalizedGroups;
};

/**
 * Check if there are remaining subjects to be allocated and groups with remaining capacity
 * @param cohorts
 * @param groups
 */
export const hasRemainingAllocation = (groups: Array<RandomizationResult>, cohorts: Array<Array<number>>): boolean => {
  return (
    cohorts.some((cohort) => cohort.length > 0) &&
    groups.some((g) => g.attrCapacities && g.modifiedSize > sumArray(g.attrCapacities.map((attr) => attr.capacity)))
  );
};

/**
 * Find the best group and cohort index to allocate remaining subjects
 * @param groups
 * @param cohorts
 * @param totalSubjects
 * @param originalCohorts
 * @returns best group and cohort index
 */
export const findBestAllocation = (
  groups: Array<RandomizationResult>,
  cohorts: Array<Array<number>>,
  totalSubjects: number,
  originalCohorts: Readonly<Array<Array<number>>>
): AllocationResult => {
  let bestGroupIndex = -1;
  let bestCohortIndex = -1;
  let minRatioDifference = Infinity;

  groups.forEach((group, groupIndex) => {
    const currentTotal = sumArray(group.attrCapacities.map((attr) => attr.capacity));
    if (currentTotal >= group.modifiedSize) return;

    const currentRatios = group.attrCapacities.map((attr) => attr.capacity / group.modifiedSize);

    cohorts.forEach((cohort, cohortIndex) => {
      if (cohort.length === 0) return;

      const newCapacity = group.attrCapacities[cohortIndex].capacity + 1;
      const newRatios = [...currentRatios];
      newRatios[cohortIndex] = newCapacity / group.modifiedSize;

      const ratioDifference = newRatios.reduce((diff, ratio, i) => {
        const initialRatio = originalCohorts[i].length / totalSubjects;
        return diff + Math.abs(ratio - initialRatio);
      }, 0);

      if (ratioDifference < minRatioDifference) {
        minRatioDifference = ratioDifference;
        bestGroupIndex = groupIndex;
        bestCohortIndex = cohortIndex;
      }
    });
  });

  return { bestGroupIndex, bestCohortIndex };
};

/**
 * Allocate remaining subject capacity to groups
 * @param groups
 * @param cohorts
 * @param totalSubjects
 * @param originalCohorts
 */
export const allocateRemainders = (
  groups: Array<RandomizationResult>,
  cohorts: Array<Array<number>>,
  totalSubjects: number,
  originalCohorts: Readonly<Array<Array<number>>>
): Array<RandomizationResult> => {
  const updatedGroups = [...groups];
  const remainingCohorts = [...cohorts];

  while (hasRemainingAllocation(updatedGroups, remainingCohorts)) {
    const { bestGroupIndex, bestCohortIndex } = findBestAllocation(
      updatedGroups,
      remainingCohorts,
      totalSubjects,
      originalCohorts
    );
    if (bestGroupIndex == -1 || bestCohortIndex == -1) break;

    updatedGroups[bestGroupIndex].attrCapacities[bestCohortIndex].capacity++;
    remainingCohorts[bestCohortIndex].shift();
  }

  return updatedGroups.map((group) => ({
    ...group,
    cohort_subject_ids: Array(group.attrCapacities.length)
      .fill(undefined)
      .map(() => []),
  }));
};

/**
 * Allocate initial cohort sizes to groups
 * @param groups
 * @param cohorts
 * @param totalSubjects
 * @returns groups with allocated cohort sizes
 */
export const allocateCohortSizes = (
  groups: Array<RandomizationResult>,
  cohorts: Readonly<Array<Array<number>>>,
  totalSubjects: number
) => {
  return groups.map((group) => {
    const weight = group.modifiedSize / totalSubjects;

    const attrCapacities: AttributeCapacity[] = cohorts.map((cohort, index) => {
      return {
        cohortIndex: index,
        capacity: Math.floor(cohort.length * weight),
      };
    });

    return {
      ...group,
      attrCapacities,
    };
  });
};

/**
 * Remove assigned subjects from cohorts
 * @param groups
 * @param cohorts
 */
export const truncateCohorts = (
  groups: Array<RandomizationResult>,
  cohorts: Readonly<Array<Array<number>>>
): Array<Array<number>> => {
  return cohorts.map((cohort, index) => {
    return cohort.slice(
      sumArray(groups.map((group) => group.attrCapacities?.find((attr) => attr.cohortIndex === index)?.capacity ?? 0))
    );
  });
};

/**
 * Calculate attribute sizes for each group
 * @param groups
 * @param cohorts
 */
export const calculateAttributeSizes = (
  groups: Readonly<Array<RandomizationResult>>,
  cohorts: Readonly<Array<Array<number>>>
) => {
  const totalSubjects = sumArray(cohorts.map((cohort) => cohort.length));

  const adjustedGroupSizes = normalizeGroupSizes(groups, totalSubjects);

  const groupsWithCohorts = allocateCohortSizes(adjustedGroupSizes, cohorts, totalSubjects);

  const remainingCohorts = truncateCohorts(groupsWithCohorts, cohorts);

  return allocateRemainders(groupsWithCohorts, remainingCohorts, totalSubjects, cohorts);
};
