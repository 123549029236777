import { useScheduleContext } from '@/components/Studies/Tasks/Schedule.context.ts';
import { ScheduleSearchBar } from '@/components/Studies/Tasks/ScheduleSearchBar.tsx';
import Button from '@/components/UI/Button';
import { _notNil } from '@/littledash.ts';
import { TaskScheduleDataV1 } from '@/model/Task.model.ts';
import { featuresSelector } from '@/support/Selectors.tsx';
import { createSelector } from '@reduxjs/toolkit';
import { FC } from 'react';
import { RiAlertFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';

const typedTaskFeatureSelector = createSelector([featuresSelector], (features) => features?.typed_tasks ?? false);

export const ScheduleHeader: FC<{
  scheduleData?: TaskScheduleDataV1;
  executeTasks: () => void;
  isWriteUserForStudy: boolean;
}> = ({ scheduleData, executeTasks, isWriteUserForStudy }) => {
  const { state, onSearchChange } = useScheduleContext();
  const typedTasksEnabled = useSelector(typedTaskFeatureSelector);
  return (
    <div className="ph4 pv2 bb b-moon-gray" data-test-component="ScheduleHeader" data-test-element="container">
      <div className="flex flex-row justify-between items-center">
        <ScheduleSearchBar scheduleData={scheduleData} />
        <div className="flex flex-row justify-end items-center">
          {(scheduleData?.counts?.overdue ?? 0) > 0 ? (
            <div
              className="link blue fl"
              data-testid="overdue-tasks-indicator"
              onClick={() => {
                if (
                  _notNil(scheduleData?.earliest_overdue_task_day) &&
                  state.search.start !== scheduleData.earliest_overdue_task_day
                ) {
                  return onSearchChange({ ...state.search, start: scheduleData.earliest_overdue_task_day });
                }
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <RiAlertFill className="red" size={25} />
                <span className="pl2">{scheduleData?.counts?.overdue} overdue tasks</span>
              </div>
            </div>
          ) : null}
          {typedTasksEnabled && isWriteUserForStudy ? (
            <Button
              disabled={state.selectedTasks.size <= 0}
              className="ml4"
              testId="execute-task-button"
              onClick={executeTasks}
            >
              {state.selectedTasks.size > 1 ? <>Execute tasks</> : <>Execute task</>}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
