import Loading from '@/components/Loading';
import Button from '@/components/UI/Button';
import type { DataTableApiId } from '@/components/UI/DataTable/DataTable.model';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers';
import { Dropdown, DropdownMenuDivider, DropdownMenuItem } from '@/components/UI/Dropdown';
import Header from '@/components/UI/Header';
import Link from '@/components/UI/Link';
import APITable from '@/components/UI/Table/Reusable/APITable';
import type { Columns } from '@/components/UI/Table/TableComponent.model';
import { successToast } from '@/helpers';
import { _isNil } from '@/littledash';
import type { DataTableV1 } from '@/model/DataTable.model';
import type { Study, StudyApiId } from '@/model/Study.model';
import * as Auth from '@/support/auth';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { useFetchEntity } from '@/support/Hooks/fetch';
import { web as webRoutes, web as webRoute } from '@/support/route';
import { RouteService } from '@/support/RouteService';
import { useModalAction } from '@/utils/modal';
import { RiMoreFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

export const ListDataTables = () => {
  const { openModal } = useModalAction();
  const { id: studyId } = useParams<{ id: StudyApiId }>();

  const { entity: study, entityLoading: studyLoading } = useFetchEntity<Study>({
    entityType: 'study',
    params: { id: studyId },
    includes: 'users',
  });

  const { invoke: invokeDeleteDataTable } = useApiHook({
    endpoint: 'DELETE /api/v1/studies/{studyApiId}/datatables/{dataTableApiId}',
    path: { studyApiId: studyId, dataTableApiId: 'dtl_unknown' },
    invokeOnInit: false,
  });
  const settings = useSelector<{ ui: { settings: Record<string, any> } }>((state) => state.ui.settings);
  const history = useHistory();
  const navigateToCreateDataTable = () => {
    history.push(webRoute('studies.dataTables.create', { id: studyId }));
  };
  const handleDataTableEdit = (dataTableId: DataTableApiId, fetchTableData: () => Promise<void>): void => {
    openModal('EDIT_DATATABLE', {
      studyId: study?.api_id ?? '',
      dataTableId,
      fetchTableData,
    });
  };
  const handleDataTableDelete = (dataTableId: DataTableApiId, fetchTableData: () => Promise<void>): void => {
    openModal('CONFIRM_DELETE_DATATABLE', {
      onClick: async () => {
        await invokeDeleteDataTable({
          path: { studyApiId: (study?.api_id ?? '') as StudyApiId, dataTableApiId: dataTableId },
        });
        await fetchTableData();
        successToast('Deleted datatable');
      },
    });
  };

  if (studyLoading || _isNil(study)) {
    return <Loading txt="Loading Data tables" />;
  }

  const isStudyWriteUser = Auth.isWriteUserForStudy(study);

  const columns: Columns<DataTableV1> = [
    {
      id: 'name',
      Header: 'Name',
      accessor: 'name',
      sortBy: 'name',
      isVisible: true,
      Cell: ({
        row: {
          original: { name, id },
        },
      }) => (
        <Link to={webRoutes('studies.dataTables.show', { id: studyId, dataTableId: id })} className="link blue">
          {name}
        </Link>
      ),
    },
    {
      id: 'created_at',
      Header: 'Created on',
      accessor: 'created_at',
      sortBy: 'created_at',
      isVisible: true,
      Cell: ({ row: { original } }) => <DateTimeRenderer value={original.created_at} />,
    },
    { id: 'type', Header: 'Type', accessor: 'type', sortBy: 'type', isVisible: true, sortDisabled: true },
    {
      id: 'animal_count',
      Header: 'Animals',
      accessor: 'animal_count',
      sortBy: 'animal_count',
      isVisible: true,
      sortDisabled: true,
    },
    {
      id: 'actions',
      sortDisabled: true,
      isVisible: isStudyWriteUser,
      width: 35,
      Cell: ({
        apiTable: { fetchTableData },
        row: {
          original: { id },
        },
      }) => (
        <Dropdown
          renderMenu={() => (
            <>
              <DropdownMenuItem
                onClick={() => handleDataTableEdit(id as DataTableApiId, fetchTableData as () => Promise<void>)}
              >
                Edit
              </DropdownMenuItem>
              <DropdownMenuDivider />
              <DropdownMenuItem
                onClick={() => handleDataTableDelete(id as DataTableApiId, fetchTableData as () => Promise<void>)}
              >
                <span className="red">Delete</span>
              </DropdownMenuItem>
            </>
          )}
        >
          <RiMoreFill size={22} />
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="h-100">
      <div className="ph4 pb3">
        <Header mainHeaderText="Data tables" />
        <p className="f6">
          Capture and visualize data in a tabular form.
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="dib ml1 link blue"
            href="https://help.benchling.com/hc/en-us/articles/20111486452365-Create-a-new-Data-table"
          >
            Read more
          </a>
        </p>
      </div>
      <div className="ph3 ph4-l mb4">
        <APITable
          apiInfo={{
            type: 'internalApi',
            route: RouteService.api({
              endpoint: 'GET /api/v1/studies/{studyApiId}/datatables',
              path: { studyApiId: study?.api_id },
            }).url,
          }}
          columns={columns}
          defaultSortBy={{ id: 'created_at', desc: false }}
          searchPlaceholderText="Search"
          settings={settings}
          reduxTableName={'dataTables'}
          AsideComponent={() =>
            isStudyWriteUser ? (
              <Button testId="create-new-datatable" onClick={navigateToCreateDataTable}>
                Create new Data table
              </Button>
            ) : null
          }
        />
      </div>
    </div>
  );
};
