import type { CageApiId } from '@/model/Cage.model';
import type { Study } from '@/model/Study.model';
import type { FC } from 'react';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { useModalAction } from '@/utils/modal';
import { TimedDeleteConfirmation } from '@/components/Modals/ConfirmDelete/TimedDeleteConfirmation.tsx';

interface MoveToStudyTableProps {
  cageApiIds: Array<CageApiId>;
  currentStudy: Study;
  selectedStudy: Study;
  animalCount: number;
  handleCallback: (currentStudy: Study, selectedStudy: Study, animalCount: number) => void;
}

export const MoveToStudyConfirmation: FC<MoveToStudyTableProps> = ({
  cageApiIds,
  currentStudy,
  selectedStudy,
  animalCount,
  handleCallback,
}) => {
  const { closeModal } = useModalAction();

  const handleFormSubmit = async () => {
    await onSubmit();
    handleCallback(currentStudy, selectedStudy, animalCount);
  };

  const { invoke: moveAnimalsToStudy } = useApiHook({
    endpoint: 'POST /api/v1/operations/study-cage-move',
    body: { old_study: '', new_study: '', cage_ids: [] },
    invokeOnInit: false,
  });

  const onSubmit = async () => {
    await moveAnimalsToStudy({
      body: { old_study: currentStudy?.api_id, new_study: selectedStudy?.api_id, cage_ids: cageApiIds },
    });
  };

  return (
    <TimedDeleteConfirmation
      title="Are you sure you would like to remove these animals from this study?"
      text="These animals and related data will be removed from this study and added to the selected study. Treatment groups, tracking dates, alerts and dosing will be permanently deleted. This action cannot be undone."
      btnTxt="Confirm"
      onClick={handleFormSubmit}
      onCancel={closeModal}
      onClickClose={false}
    />
  );
};
