import Loading from '@/components/Loading';
import Button from '@/components/UI/Button';
import { components } from '@/generated/internal-api/openapi-types';
import { errorToast, successToast } from '@/helpers.tsx';
import { _isNil, _notNil } from '@/littledash.ts';
import InVivoError from '@/model/InVivoError.ts';
import { useApiHook } from '@/support/Hooks/api/useApiHook.ts';
import { currentUserSelector } from '@/support/Selectors';
import { ExceptionHandler } from '@/utils/ExceptionHandler.ts';
import { ErrorMessage } from '@hookform/error-message';
import { createSelector } from '@reduxjs/toolkit';
import cn from 'classnames';
import { FC } from 'react';
import { Controller, useForm, Validate } from 'react-hook-form@latest';
import { useSelector } from 'react-redux';

type TeamTickatlabDetailsUpdateV1 = components['schemas']['TeamTickatlabDetailsUpdateV1.schema'];
const urlValidator: Validate<TeamTickatlabDetailsUpdateV1['url'], TeamTickatlabDetailsUpdateV1> = (value) => {
  try {
    new URL(value);
    return true;
  } catch (err) {
    return 'Invalid Base URL';
  }
};

const currentUserStateSelector = createSelector([currentUserSelector], (currentUser) => ({
  currentUser: currentUser,
}));

export const TickatlabConfig: FC = () => {
  const { response: detailsResponse, loading: detailsLoading } = useApiHook({
    endpoint: 'GET /api/v1/team/tickatlab-details',
  });

  if (detailsLoading) {
    return <Loading txt="Loading tick@lab Configuration" />;
  }

  return <TickatlabForm url={detailsResponse?.body?.url ?? null} />;
};

const TickatlabForm: FC<{ url: string | null }> = ({ url }) => {
  const { currentUser } = useSelector(currentUserStateSelector);
  const { invoke: updateTickatlab } = useApiHook({
    endpoint: 'PATCH /api/v1/team/tickatlab-details',
    invokeOnInit: false,
    options: { onError: { toast: false, throw: true, capture: false } },
  });
  const formMethods = useForm<TeamTickatlabDetailsUpdateV1>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: { url: url ?? '', header: '' },
  });
  const isUpdate = _notNil(url) || formMethods.formState.isSubmitSuccessful;

  const handleSubmit = async (formData: TeamTickatlabDetailsUpdateV1) => {
    try {
      await updateTickatlab({ body: formData });
      successToast(`tick@lab config ${_isNil(url) ? 'added' : 'updated'}`);
      formMethods.reset(formData);
    } catch (cause) {
      errorToast(`Could not ${_isNil(url) ? 'add' : 'update'} tick@lab config`);
      ExceptionHandler.captureException(
        new InVivoError('Could not save tick@lab details', {
          cause,
          slug: 'tickatlab-details',
          context: { url: formData.url },
        })
      );
    }
  };
  return (
    <div className="ml3" data-test-component="TickatlabForm" data-test-element="container">
      <div className="flex flex-column">
        <div className="flex flex-row w-100 f3 mt4 near-black">
          <h1 className="f3 normal pb2">
            <span>{_isNil(url) ? 'Add ' : 'Update '}</span> tick@lab Config
          </h1>
        </div>
        <div className="flex flex-row w-100 mt2 mb4">
          <p className="f5 dark-gray lh-title basier-reg normal pb1">{currentUser?.name ?? ''}</p>
        </div>
      </div>
      <div className="flex flex-row">
        <div className="ui-card pa4 flex flex-column w-50">
          <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Controller
              control={formMethods.control}
              name="url"
              rules={{ validate: urlValidator }}
              render={({ field, fieldState, formState }) => (
                <div className="mb3" data-test-element="url-container">
                  <label>Base URL</label>
                  <input
                    {...field}
                    type="url"
                    className={cn({ ui__error: fieldState.invalid })}
                    data-test-element="url-input"
                  />
                  <ErrorMessage
                    name="url"
                    errors={formState.errors}
                    render={({ message }) => (
                      <p className="f6 red db" data-test-element="url-error">
                        {message}
                      </p>
                    )}
                  />
                </div>
              )}
            />
            <Controller
              control={formMethods.control}
              name="header"
              rules={{ required: 'Auth Header required' }}
              render={({ field, fieldState, formState }) => (
                <div className="mb3" data-test-element="header-container">
                  <label>Auth Header</label>
                  <input
                    {...field}
                    type="password"
                    placeholder={_isNil(url) ? '' : '✳︎'.repeat(40)}
                    className={cn({ ui__error: fieldState.invalid })}
                    data-test-element="header-input"
                  />
                  <ErrorMessage
                    name="header"
                    errors={formState.errors}
                    render={({ message }) => (
                      <p className="f6 red db" data-test-element="header-error">
                        {message}
                      </p>
                    )}
                  />
                </div>
              )}
            />
            <div className="mb3" data-test-element="submit-container">
              <Button
                submit
                testId="submit-button"
                disabled={!formMethods.formState.isValid || formMethods.formState.isSubmitting}
                loading={formMethods.formState.isSubmitting}
                loadingText={isUpdate ? 'Updating' : 'Adding'}
              >
                {isUpdate ? 'Update' : 'Add'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
