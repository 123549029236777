import { StudyApiId } from '@/model/Study.model.ts';
import { DataTableApiId } from '@/model/DataTable.model.ts';
import { FC } from 'react';
import { ModalActions, ModalContainer, ModalHeader } from '@/utils/modal';
import { useApiHook } from '@/support/Hooks/api/useApiHook.ts';
import ApiErrorBanner from '@/components/ApiErrorBanner';
import { successToast } from '@/helpers.tsx';
import { useForm } from 'react-hook-form@latest';
import { ErrorMessage } from '@hookform/error-message';

export interface CreateTemplateFromDataTableProps {
  studyApiId: StudyApiId;
  dataTableApiId: DataTableApiId;
  closeModal: () => void;
}

interface FormValues {
  name: string;
}

const CreateTemplateFromDataTable: FC<CreateTemplateFromDataTableProps> = ({
  studyApiId,
  dataTableApiId,
  closeModal,
}) => {
  return (
    <ModalContainer size="narrow">
      <ModalHeader title="Save as template" closeModal={closeModal} className="pa3 bb b--moon-gray bg-white" />
      <CreateTemplateFromDataTableForm
        closeModal={closeModal}
        studyApiId={studyApiId}
        dataTableApiId={dataTableApiId}
      />
    </ModalContainer>
  );
};

const CreateTemplateFromDataTableForm: FC<CreateTemplateFromDataTableProps> = ({
  studyApiId,
  dataTableApiId,
  closeModal,
}) => {
  const {
    invoke: postTemplate,
    response: templatePOSTResponse,
    loading: postingTemplate,
  } = useApiHook({
    endpoint: 'POST /api/v1/studies/{studyApiId}/datatables/{dataTableApiId}/template',
    path: { studyApiId, dataTableApiId },
    invokeOnInit: false,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { name: '' },
  });
  const onSubmit = async (formValues: FormValues): Promise<void> => {
    await postTemplate({
      path: { studyApiId, dataTableApiId },
      body: formValues,
    });
    closeModal();
    successToast('Created template');
  };
  return (
    <form>
      {templatePOSTResponse?.type === 'error' && <ApiErrorBanner />}
      <div className="pa3">
        <label>Name</label>
        <input
          type="text"
          id="name"
          data-testid={'template-name-input'}
          style={{ marginBottom: 0 }}
          {...register('name', {
            required: 'Name is required',
            maxLength: { value: 255, message: 'Maximum of 255 characters' },
          })}
          autoFocus
        />
        <ErrorMessage
          errors={errors}
          name="name"
          render={({ message }) => <small className="red db pt2">{message}</small>}
        />
      </div>
      <ModalActions
        submitButtonProps={{ disabled: postingTemplate || templatePOSTResponse?.type === 'error' }}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={closeModal}
        className="pa3 bt b--moon-gray"
      />
    </form>
  );
};

export default CreateTemplateFromDataTable;
