// @ts-nocheck: converted from JS

import Button from '@/components/UI/Button';
import Icon from '@/components/UI/Icon';
import Portal from '@/components/UI/Portal';
import { _isEmpty } from '@/littledash';
import React, { CSSProperties } from 'react';
import './SelectDropDown.scss';

interface SelectDropDownProps {
  children: React.ReactNode;
  title?: string;
  selection?: unknown;
  className?: unknown;
  alignMenu?: unknown;
  iconOnly?: unknown;
  disabled?: unknown;
  asideText?: unknown;
  loading?: unknown;
  loadingText?: unknown;
  portalProps?: unknown;
  tooltip?: string;
  style?: CSSProperties;
  btnProps?: object;
  testId?: string;
}
interface SelectDropDownState {
  isOpen: boolean;
  isSelected: boolean;
}

class SelectDropDown extends React.Component<SelectDropDownProps, SelectDropDownState> {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selected: null,
    };
    this.escFunction = this.escFunction.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, {
      capture: false,
      passive: true,
    });
    document.addEventListener('scroll', this.handleClickOutside, {
      capture: false,
      passive: true,
    });
    document.addEventListener('mousedown', this.handleClickOutside, {
      capture: false,
      passive: true,
    });
    document.addEventListener('touchstart', this.handleClickOutside, {
      capture: false,
      passive: true,
    });
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction);
    document.addEventListener('scroll', this.handleClickOutside, {
      capture: false,
      passive: true,
    });
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('touchstart', this.handleClickOutside);
  }

  render() {
    const {
      title,
      children,
      selection,
      className,
      alignMenu,
      iconOnly,
      disabled,
      asideText,
      loading,
      loadingText,
      portalProps,
      testId,
    } = this.props;
    const { isOpen, isSelected } = this.state;

    return (
      <div
        className={`ui__select-drop-down dib ${asideText ? 'ui__select-drop-down__selection' : ''}`}
        ref={this.setWrapperRef}
        data-testid={testId}
      >
        {asideText && (
          <div className="ui__select-drop-down__attach-btn dib mid-gray bg-light-gray ph3 ba b--moon-gray f6">
            {asideText}
          </div>
        )}

        {iconOnly ? (
          <div
            data-tooltip-id="global-tooltip-id"
            data-tooltip-content={title || 'More actions'}
            className={`${className} ${disabled && 'ui__disabled'}`}
            style={{ cursor: 'pointer' }}
            onClick={() => this.toggleMenu()}
            data-testid="icon-only-dropdown-toggle"
          >
            <Icon icon="options" viewBox="0 0 20 20" width={20} height={20} />
          </div>
        ) : (
          <Button
            tooltip={this.props.tooltip}
            disabled={(typeof selection !== 'undefined' && _isEmpty(selection)) || disabled}
            className={`ui__select-drop-down-btn ${disabled ? 'ui__disabled' : ''} ${className} relative`}
            style={this.props.style}
            onClick={() => this.toggleMenu()}
            {...this.props.btnProps}
            loading={loading}
            loadingText={loadingText}
            testId={`${testId}--button`}
          >
            {isSelected || title}
            <Icon
              icon="down_triangle"
              width="8"
              height="8"
              className="percentCenterY right-1"
              style={{ pointerEvents: 'none' }}
            />
          </Button>
        )}
        <div className={`menu-align-${alignMenu || 'left'} ${iconOnly ? 'icon-only' : ''}`}>
          {isOpen && this.renderChildren(children, portalProps)}
        </div>
      </div>
    );
  }

  toggleMenu = () =>
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));

  closeMenu = () => this.setState({ isOpen: false });

  escFunction(event) {
    if (event.keyCode === 27) {
      this.closeMenu();
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    const { target } = event;
    let insidePortal = false;
    if (typeof target.closest === 'function') {
      insidePortal = target && Boolean(target.closest('.portal-root'));
    }

    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && !insidePortal) {
      this.closeMenu();
    }
  }

  handleChange = (newValue) => {
    this.setState({ selected: newValue });
  };

  renderChildren = (children, portalProps) => {
    const clones = React.cloneElement(children, {
      closeMenu: this.closeMenu,
    });
    const iconWidth = 20;
    if (portalProps) {
      return (
        <Portal
          parentRef={this.wrapperRef}
          offsets={{
            width: portalProps?.width,
            left: this.props.alignMenu ? iconWidth : portalProps?.width - iconWidth,
            align: this.props.alignMenu,
            top: iconWidth,
          }}
        >
          {clones}
        </Portal>
      );
    }
    return clones;
  };
}

SelectDropDown.defaultProps = {
  btnProps: {},
};
export default SelectDropDown;
