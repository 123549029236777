import { _isNotEmpty, _notNil } from '@/littledash';
import { structuredCloneUtil } from '@/utils/browser.utils';
import {
  type DataTableCellIdCoordinate,
  type DataTableCellReference,
  type DataTableCellState,
  type DataTableCellStateChangeEventDetail,
  type DataTableCellStatus,
  type DataTableCellUpsert,
  type DataTableCellUpsertError,
  type DataTableColumnApiId,
  type DataTableRowApiId,
} from '../DataTable.model';
import { DataTableCellStatusType, DataTableEvent } from '../DataTable.model';
import { toCellRef } from '../DataTable.util';

type CellState = Record<DataTableRowApiId, Record<DataTableColumnApiId, DataTableCellStatus>>;

const resetAccumulator = (acc: CellState, cell: DataTableCellUpsert | DataTableCellUpsertError): CellState => {
  if (_notNil(acc[cell.row_id]?.[cell.column_id])) {
    delete acc[cell.row_id][cell.column_id];
    if (Object.keys(acc[cell.row_id]).length === 0) {
      delete acc[cell.row_id];
    }
    return { ...acc };
  }
  return acc;
};

const validationErrorAccumulator = (acc: CellState, cell: DataTableCellUpsertError): CellState => ({
  ...acc,
  [cell.row_id]: {
    ...(acc[cell.row_id] ?? {}),
    [cell.column_id]: { type: DataTableCellStatusType.ValidationError, error: cell.error },
  },
});
const validationWarningAccumulator = (acc: CellState, cell: DataTableCellUpsertError): CellState => ({
  ...acc,
  [cell.row_id]: {
    ...(acc[cell.row_id] ?? {}),
    [cell.column_id]: { type: DataTableCellStatusType.ValidationWarning, error: cell.error },
  },
});
const asyncValidationWarningAccumulator = (acc: CellState, cell: DataTableCellUpsertError): CellState => ({
  ...acc,
  [cell.row_id]: {
    ...(acc[cell.row_id] ?? {}),
    [cell.column_id]: { type: DataTableCellStatusType.AsyncValidationWarning, error: cell.error },
  },
});

const networkErrorAccumulator = (acc: CellState, cell: DataTableCellUpsert): CellState => ({
  ...acc,
  [cell.row_id]: {
    ...(acc[cell.row_id] ?? {}),
    [cell.column_id]: { type: DataTableCellStatusType.NetworkError },
  },
});

export class DataTableCellStateService {
  #cellState: CellState = {};
  #invalidRows: Set<DataTableRowApiId> = new Set();
  #invalidColumns: Set<DataTableColumnApiId> = new Set();

  constructor(private readonly eventEmitter: EventTarget) {}

  reset(cells: Array<DataTableCellUpsert>) {
    this.#handleStateUpdate(cells.reduce(resetAccumulator, this.#cellState));
  }

  validationWarning(invalidCells: Array<DataTableCellUpsertError>, resetCells: Array<DataTableCellUpsert> = []) {
    if ((invalidCells?.length ?? 0) + (resetCells?.length ?? 0) > 0) {
      const partiallyUpdatedState = resetCells.reduce(resetAccumulator, this.#cellState);
      this.#handleStateUpdate(invalidCells.reduce(validationWarningAccumulator, partiallyUpdatedState));
    }
  }

  validationError(invalidCells: Array<DataTableCellUpsertError>, resetCells: Array<DataTableCellUpsert> = []) {
    if ((invalidCells?.length ?? 0) + (resetCells?.length ?? 0) > 0) {
      const partiallyUpdatedState = resetCells.reduce(resetAccumulator, this.#cellState);
      this.#handleStateUpdate(invalidCells.reduce(validationErrorAccumulator, partiallyUpdatedState));
    }
  }

  asyncValidationErrror(invalidCells: Array<DataTableCellUpsertError>, resetCells: Array<DataTableCellUpsert> = []) {
    if ((invalidCells?.length ?? 0) + (resetCells?.length ?? 0) > 0) {
      const partiallyUpdatedState = resetCells.reduce(resetAccumulator, this.#cellState);
      this.#handleStateUpdate(invalidCells.reduce(asyncValidationWarningAccumulator, partiallyUpdatedState));
    }
  }

  networkError(unsavedCells: Array<DataTableCellUpsert>, resetCells: Array<DataTableCellUpsert> = []) {
    if ((unsavedCells?.length ?? 0) + (resetCells?.length ?? 0) > 0) {
      const partiallyUpdatedState = resetCells.reduce(resetAccumulator, this.#cellState);
      this.#handleStateUpdate(unsavedCells.reduce(networkErrorAccumulator, partiallyUpdatedState));
    }
  }

  #handleStateUpdate(updatedCellState: CellState) {
    if (updatedCellState !== this.#cellState) {
      this.#cellState = updatedCellState;

      const { invalidRows, invalidColumns, invalidCells, overview } = (
        Object.entries(this.#cellState) as Array<[DataTableRowApiId, Record<DataTableColumnApiId, DataTableCellStatus>]>
      ).reduce(
        (acc, [rowId, rowData]) => {
          acc.invalidRows.add(rowId);
          (Object.entries(rowData) as Array<[DataTableColumnApiId, DataTableCellStatus]>).forEach(
            ([columnId, cellState]) => {
              acc.invalidColumns.add(columnId);
              acc.invalidCells.add(toCellRef({ column: columnId, row: rowId }));
              acc.overview[cellState.type] += 1;
            }
          );
          return acc;
        },
        {
          invalidRows: new Set<DataTableRowApiId>(),
          invalidColumns: new Set<DataTableColumnApiId>(),
          invalidCells: new Set<DataTableCellReference>(),
          overview: {
            [DataTableCellStatusType.OK]: 0,
            [DataTableCellStatusType.ValidationError]: 0,
            [DataTableCellStatusType.NetworkError]: 0,
            [DataTableCellStatusType.ValidationWarning]: 0,
            [DataTableCellStatusType.AsyncValidationWarning]: 0,
          },
        }
      );
      this.#invalidRows = invalidRows;
      this.#invalidColumns = invalidColumns;
      this.eventEmitter.dispatchEvent(
        new CustomEvent<DataTableCellStateChangeEventDetail>(DataTableEvent.CellStateChange, {
          detail: { invalidRows, invalidColumns, invalidCells, overview },
        })
      );
    }
  }

  destroy() {
    this.#cellState = {};
  }

  rowInvalid(row: DataTableRowApiId | undefined): boolean {
    return _notNil(row) && this.#invalidRows.has(row);
  }

  columnInvalid(column: DataTableColumnApiId | undefined) {
    return _notNil(column) && this.#invalidColumns.has(column);
  }

  hasErrors() {
    return this.#invalidRows.size > 0;
  }

  cellState(cellCoordinate: DataTableCellIdCoordinate, loaded: boolean): DataTableCellState {
    if (loaded) {
      switch (this.#cellState?.[cellCoordinate.row]?.[cellCoordinate.column]?.type) {
        case DataTableCellStatusType.ValidationError:
          return 'invalid';
        case DataTableCellStatusType.ValidationWarning:
        case DataTableCellStatusType.AsyncValidationWarning:
          return 'warning';
        case DataTableCellStatusType.NetworkError:
          return 'notSaved';
        default:
          return 'valid';
      }
    }
    return 'loading';
  }

  cellStatus(cellCoordinate: DataTableCellIdCoordinate): DataTableCellStatus {
    return this.#cellState?.[cellCoordinate.row]?.[cellCoordinate.column] ?? { type: DataTableCellStatusType.OK };
  }

  cellStatusOverview(): Record<DataTableCellStatusType, number> {
    return Object.values(this.#cellState ?? {}).reduce<Record<DataTableCellStatusType, number>>(
      (rowAcc, rowData: Record<DataTableColumnApiId, DataTableCellStatus>) => {
        return Object.values(rowData).reduce((columnAcc, cellStatus: DataTableCellStatus) => {
          columnAcc[cellStatus.type] += 1;
          return columnAcc;
        }, rowAcc);
      },
      {
        [DataTableCellStatusType.OK]: 0,
        [DataTableCellStatusType.ValidationError]: 0,
        [DataTableCellStatusType.NetworkError]: 0,
        [DataTableCellStatusType.ValidationWarning]: 0,
        [DataTableCellStatusType.AsyncValidationWarning]: 0,
      }
    );
  }

  clearColumn(...columnIds: Array<DataTableColumnApiId>) {
    columnIds.some((columnId) => {
      if (this.#invalidColumns.has(columnId)) {
        this.#handleStateUpdate(
          [...this.#invalidRows.values()].reduce((acc, rowId) => {
            delete acc[rowId][columnId];
            if (Object.keys(acc[rowId]).length === 0) {
              delete acc[rowId];
            }
            return acc;
          }, structuredCloneUtil(this.#cellState))
        );
      }
    });
  }

  clearRow(rowId: DataTableRowApiId) {
    if (this.#invalidRows.has(rowId)) {
      const updatedCellState = structuredCloneUtil(this.#cellState);
      delete updatedCellState[rowId];
      this.#handleStateUpdate(updatedCellState);
    }
  }

  clearType(...type: Array<DataTableCellStatusType>) {
    if (_isNotEmpty(type)) {
      const types = new Set(type);
      const updatedCellState = Object.entries(structuredCloneUtil(this.#cellState)).reduce<CellState>(
        (cellState, [rowId, columnState]) => {
          const rowState = Object.entries(columnState).reduce<Record<DataTableColumnApiId, DataTableCellStatus>>(
            (columnAcc, [columnId, cellError]) =>
              types.has(cellError.type) ? columnAcc : { ...columnAcc, [columnId]: cellError },
            {}
          );
          if (Object.values(rowState).length === 0) {
            return cellState;
          }
          return { ...cellState, [rowId]: rowState };
        },
        {}
      );
      this.#handleStateUpdate(updatedCellState);
    }
  }
}
