import { AddTaskProps } from '@/components/Modals/AddTask/AddTask.model.ts';
import { StudyExecutionTimezone } from '@/components/Studies/Settings/Steps/Tasks/StudyExecutionTimezone.tsx';
import { taskTypes } from '@/components/Studies/Tasks/Task';
import Button from '@/components/UI/Button';
import Link from '@/components/UI/Link';
import APITable from '@/components/UI/Table/Reusable/APITable';
import type { UseTableProps } from '@/components/UI/Table/Reusable/APITable/APITable.model';
import type { CellProps, Columns } from '@/components/UI/Table/TableComponent.model';
import UserAvatar from '@/components/UI/UserAvatar';
import { classNames, errorToast, successToast, warningToast } from '@/helpers';
import { _isEmpty } from '@/littledash';
import { GlossaryItem } from '@/model/Glosary.model.ts';
import InVivoError from '@/model/InVivoError.ts';
import type { Study } from '@/model/Study.model';
import type { TaskSpec, TaskSpecApiId, TaskSpecCreate, TaskSpecUpdate } from '@/model/Task.model';
import { Treatment } from '@/model/Treatment.model.ts';
import { ApiService } from '@/support/ApiService';
import { useAbortController } from '@/support/Hooks/fetch/useAbortController';
import useMountedState from '@/support/Hooks/fetch/useMountedState.ts';
import { LegacyApiService } from '@/support/LegacyApiService.ts';
import { RouteService } from '@/support/RouteService';
import { featuresSelector } from '@/support/Selectors.tsx';
import { isClosed } from '@/support/study';
import { DateRenderFormat, DateUtils } from '@/utils/Date.utils';
import { ExceptionHandler } from '@/utils/ExceptionHandler.ts';
import { modalAction } from '@/utils/modal';
import { createSelector } from '@reduxjs/toolkit';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TaskScheduleFrequencyCell } from '../../Settings.utils';

interface TasksV2Props {
  study: Study;
  createTaskEnabled: boolean;
}

export const taskTargetTypes = {
  animal: 'All animals',
  group: 'Groups',
};
const selector = createSelector([featuresSelector], (features) => ({
  studyExecutionTimezoneEnabled: features?.study_execution_timezone ?? false,
}));

type GlossaryData = { samples: Array<GlossaryItem>; observations: Array<GlossaryItem>; treatments: Array<Treatment> };
export const TasksV2: FC<TasksV2Props> = ({ study, createTaskEnabled }) => {
  const { openModal, closeModal } = modalAction(useDispatch());
  const abortController = useAbortController();
  const [listApiVersion, setListApiVersion] = useState<number>(() => Date.now());
  const [glossaryData, updateGlossaryData] = useState<GlossaryData>(() => ({
    samples: [],
    observations: [],
    treatments: [],
  }));
  const studyId = study.api_id;
  const studyInactive = isClosed(study);
  const isMounted = useMountedState();
  const { studyExecutionTimezoneEnabled } = useSelector(selector);

  useEffect(() => {
    const onError = { toast: false, capture: true, throw: false };

    Promise.all([
      LegacyApiService.call({
        method: 'GET',
        apiRoute: 'team-glossary.list',
        query: { group: 'samples' },
        options: { onError: { ...onError, slug: 'samples-glossary-load' } },
      }),
      LegacyApiService.call({
        method: 'GET',
        apiRoute: 'team-glossary.list',
        query: { group: 'observations' },
        options: { onError: { ...onError, slug: 'observations-glossary-load' } },
      }),
      LegacyApiService.call({
        method: 'GET',
        apiRoute: 'studies.treatments',
        path: { studyId: study.id },
        query: { perPage: -1 },
        options: { onError: { ...onError, slug: 'study-treatments-load' } },
      }),
    ])
      .then(([samplesResponse, observationsResponse, treatmentsResponse]) => {
        if (isMounted()) {
          const samples: Array<GlossaryItem> = (samplesResponse.body as { data?: Array<GlossaryItem> })?.data ?? [];
          const observations: Array<GlossaryItem> =
            (observationsResponse.body as { data?: Array<GlossaryItem> })?.data ?? [];
          const treatments: Array<Treatment> = (treatmentsResponse.body as { data?: Array<Treatment> })?.data ?? [];
          updateGlossaryData({ samples, observations, treatments });
        }
      })
      .catch((cause) => {
        ExceptionHandler.captureException(
          new InVivoError('Could not load sample & observation glossary', {
            cause: cause,
            slug: 'tasks-glossary-load',
          })
        );
      });
  }, [isMounted, updateGlossaryData]);

  const handleTaskSpecCreate = async (taskSpec: TaskSpecCreate) => {
    try {
      await ApiService.call({
        endpoint: 'POST /api/v1/studies/{studyId}/task-specs',
        body: [taskSpec],
        path: { studyId },
        signal: abortController.newAbortController().signal,
      });
      closeModal();
      setListApiVersion(new Date().getTime());
      successToast('Successfully created task');
    } catch (e) {
      errorToast('Could not create task');
    }
  };
  const handleTaskSpecUpdate = (taskSpecId: TaskSpecApiId) => {
    return async (taskSpec: TaskSpecUpdate) => {
      try {
        await ApiService.call({
          endpoint: 'PATCH /api/v1/studies/{studyId}/task-specs/{taskSpecId}',
          body: taskSpec,
          path: { studyId, taskSpecId },
          signal: abortController.newAbortController().signal,
          options: { onError: { toast: false } },
        });
        closeModal();
        setListApiVersion(new Date().getTime());
        successToast('Successfully updated task');
      } catch (err) {
        errorToast('Could not update task');
      }
    };
  };

  const handleAddClick = () => {
    const execution: AddTaskProps['execution'] = {
      measurements: (study.settings.calculations ?? [])
        .reduce<Array<{ id: string; title: string }>>(
          (acc, calculation) =>
            (calculation.measurements?.length ?? 0) > 0
              ? [
                  ...acc,
                  {
                    id: calculation.id,
                    title: calculation.name,
                  },
                ]
              : acc,
          []
        )
        .sort((a, b) => a.title.localeCompare(b.title)),
      observations: glossaryData.observations
        .map(({ id, title }) => ({ id: Number(id), title }))
        .sort((a, b) => a.title.localeCompare(b.title)),
      samples: glossaryData.samples
        .map(({ id, title }) => ({ id: Number(id), title }))
        .sort((a, b) => a.title.localeCompare(b.title)),
      treatments: glossaryData.treatments
        .map(({ api_id, display_name }) => ({ id: api_id, title: display_name }))
        .sort((a, b) => a.title.localeCompare(b.title)),
    };

    openModal('ADD_TASK', {
      studyId: study.id,
      studyApiId: study.api_id,
      studyStartedOn: study.started_on,
      execution,
      handleSubmit: handleTaskSpecCreate,
    });
  };

  const handleTaskSpecClick = async (event: MouseEvent<HTMLAnchorElement>, taskSpecId: TaskSpecApiId) => {
    event.preventDefault();
    const response = await ApiService.call({
      endpoint: 'GET /api/v1/studies/{studyId}/task-specs/{taskSpecId}',
      path: {
        studyId,
        taskSpecId,
      },
    });
    if (response.type === 'success') {
      const execution: AddTaskProps['execution'] = {
        measurements: (study.settings.calculations ?? [])
          .reduce<Array<{ id: string; title: string }>>(
            (acc, calculation) =>
              (calculation.measurements?.length ?? 0) > 0
                ? [
                    ...acc,
                    {
                      id: calculation.id,
                      title: calculation.name,
                    },
                  ]
                : acc,
            []
          )
          .sort((a, b) => a.title.localeCompare(b.title)),
        observations: glossaryData.observations
          .map(({ id, title }) => ({ id: Number(id), title }))
          .sort((a, b) => a.title.localeCompare(b.title)),
        samples: glossaryData.samples
          .map(({ id, title }) => ({ id: Number(id), title }))
          .sort((a, b) => a.title.localeCompare(b.title)),
        treatments: glossaryData.treatments
          .map(({ api_id, display_name }) => ({ id: api_id, title: display_name }))
          .sort((a, b) => a.title.localeCompare(b.title)),
      };
      openModal('EDIT_TASK', {
        studyApiId: study.api_id,
        studyId: study.id,
        taskSpec: response.body,
        execution,
        disabledSections: ['target'],
        handleSubmit: handleTaskSpecUpdate(taskSpecId),
      });
    }
  };
  const handleTaskSpecDelete = async (taskSpecs: Array<TaskSpec>) => {
    try {
      const result = await Promise.allSettled(
        taskSpecs.map((taskSpec) =>
          ApiService.call({
            endpoint: 'DELETE /api/v1/studies/{studyId}/task-specs/{taskSpecId}',
            path: { studyId: taskSpec.study_id, taskSpecId: taskSpec.id },
            options: { onError: { toast: false } },
          })
        )
      );
      const totalDeleted = result.reduce((acc, { status }) => (status === 'fulfilled' ? (acc += 1) : acc), 0);
      if (totalDeleted === 0) {
        errorToast('Could not delete tasks');
      } else if (totalDeleted < taskSpecs.length) {
        warningToast(`Deleted [${totalDeleted}/${taskSpecs.length}] tasks`);
      } else {
        successToast(`Deleted [${totalDeleted}/${taskSpecs.length}] tasks`);
      }
    } catch (e) {
      errorToast('Could not delete tasks');
    }
    setListApiVersion(new Date().getTime());
  };

  const toggleDeleteTaskSpecModal = (taskSpecs: Array<TaskSpec>) => {
    openModal('CONFIRM_DELETE_TASK_SPEC', {
      closeModal,
      onClick: () => handleTaskSpecDelete(taskSpecs),
    });
  };

  const toggleShiftDatesModal = (taskSpecs: Array<TaskSpec>) => {
    openModal('SHIFT_TASK_DATES', {
      taskSpecs,
      studyId,
      closeModal,
      refetchTableData: setListApiVersion,
    });
  };

  const columns: Columns<TaskSpec> = [
    {
      id: 1,
      Header: 'Title',
      width: 75,
      isVisible: true,
      Cell: (props: CellProps<TaskSpec>) => (
        <Link
          className={classNames('', { 'link blue': !studyInactive, 'pointer-events-none': studyInactive })}
          onClick={(e) => handleTaskSpecClick(e, props.row.original.id)}
        >
          {props.row.original.title ?? '-'}
        </Link>
      ),
    },
    {
      id: 2,
      Header: 'Description',
      width: 75,
      isVisible: true,
      Cell: (props: CellProps<TaskSpec>) => <p className="truncate">{props.row.original.description ?? '-'}</p>,
    },
    {
      id: 3,
      Header: 'Type',
      width: 75,
      isVisible: true,
      Cell: (props) => taskTypes[props.row.original.type],
    },
    {
      id: 4,
      Header: 'Target',
      width: 75,
      isVisible: true,
      Cell: (props: CellProps<TaskSpec>) => taskTargetTypes[props.row.original.target?.type],
    },
    {
      id: 5,
      Header: 'Frequency',
      width: 75,
      isVisible: true,
      Cell: (props: CellProps<TaskSpec>) => <TaskScheduleFrequencyCell schedule={props.row.original.schedule} />,
    },
    {
      id: 6,
      Header: 'Start',
      width: 75,
      isVisible: true,
      Cell: ({ row: { original } }: CellProps<TaskSpec>) =>
        original.schedule.type === 'conditional'
          ? 'N/A'
          : DateUtils.renderDateTime(original.schedule.duration.start, { format: DateRenderFormat.Date }),
    },
    {
      id: 7,
      Header: 'End',
      width: 75,
      isVisible: true,
      Cell: ({ row: { original } }: CellProps<TaskSpec>) =>
        original.schedule.type === 'conditional'
          ? 'N/A'
          : DateUtils.renderDateTime(original.schedule.duration.end, { format: DateRenderFormat.Date }),
    },
    {
      id: 8,
      Header: 'Timezone',
      width: 100,
      isVisible: false,
      Cell: (props: CellProps<TaskSpec>) =>
        DateUtils.timezoneName(null, props.row.original.schedule.timezone ?? '') ?? '-',
    },
    {
      id: 9,
      Header: 'Assignees',
      width: 75,
      isVisible: true,
      Cell: (props: CellProps<TaskSpec>) => (
        <div>
          {(props.row.original.assignees ?? []).map((assignee) => (
            <UserAvatar
              key={assignee.id}
              user={{ name: assignee.name }}
              style={{ marginRight: '3px' }}
              tooltip={assignee.name}
            />
          ))}
        </div>
      ),
    },
  ];

  const bulkAction = ({ useTableProps: { selectedFlatRows } }: UseTableProps<TaskSpec>): Array<unknown> => {
    const taskSpecs = selectedFlatRows.map(({ original }) => original);
    return [
      {
        name: 'Shift dates',
        key: 'date-shift',
        disabled: selectedFlatRows.some((row) => row.original.schedule.type === 'conditional'),
        tooltip: 'Shift dates can only be performed on recurring and one off tasks',
        action: () => toggleShiftDatesModal(taskSpecs),
      },
      {
        name: 'Delete',
        key: 'delete',
        className: 'red',
        disabled: _isEmpty(selectedFlatRows) || studyInactive,
        action: () => toggleDeleteTaskSpecModal(taskSpecs),
      },
    ];
  };

  return (
    <div>
      <APITable
        columns={columns}
        apiInfo={{
          type: 'internalApi',
          route: RouteService.api({
            endpoint: 'GET /api/v1/studies/{studyId}/task-specs',
            path: { studyId },
          }).url,
        }}
        refetch={listApiVersion}
        bulkActions={bulkAction}
        AsideComponent={
          createTaskEnabled
            ? () => (
                <Button testKey="add-task" onClick={handleAddClick}>
                  Add Task
                </Button>
              )
            : undefined
        }
        noDataMessage="Your tasks will appear here"
      />
      {studyExecutionTimezoneEnabled ? (
        <StudyExecutionTimezone study={study} onTimezoneChange={() => setListApiVersion(Date.now())} />
      ) : null}
    </div>
  );
};
