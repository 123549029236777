import {
  checkCanTransitionTask,
  statusOptions,
  type TaskDetailsProps,
  TaskDetailsTabType,
} from '@/components/Modals/TaskDetails/TaskDetails';
import { useScheduleContext } from '@/components/Studies/Tasks/Schedule.context.ts';
import { TaskAssigneeAvatars } from '@/components/Studies/Tasks/TaskAssigneeAvatars.tsx';
import { TaskTypeIcon } from '@/components/Studies/Tasks/TaskTypeIcon.tsx';
import Button from '@/components/UI/Button';
import { DateTimeRenderer } from '@/components/UI/DateRenderers/DateRenderers.tsx';
import { Dropdown, DropdownMenuItem } from '@/components/UI/Dropdown';
import Checkbox from '@/components/UI/FormElements/Checkbox';
import { _isEmptyString, _isNil, _notNil } from '@/littledash';
import { ISODate } from '@/model/Common.model.ts';
import { State } from '@/model/State.model.ts';
import type { Study } from '@/model/Study.model';
import type { TaskDuration, TaskStatus, TaskType, TaskV1 } from '@/model/Task.model';
import { useApiHook } from '@/support/Hooks/api/useApiHook';
import { featuresSelector } from '@/support/Selectors.tsx';
import { DateRenderFormat, DateTimeRenderFormat, DateUtils } from '@/utils/Date.utils.ts';
import { modalAction } from '@/utils/modal';
import { TZDate } from '@date-fns/tz';
import { createSelector } from '@reduxjs/toolkit';
import cn from 'classnames';
import { format, formatDuration, intervalToDuration, isValid } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { FC, useMemo, useState } from 'react';
import { RiArrowDownSFill, RiTimeLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Schedule.module.scss';
import { TaskTypeData, TaskTypeDetail } from './TaskTypeDetail';

interface TaskProps {
  task: TaskV1;
  study: Study;
  typeData: TaskTypeData;
  onTaskUpdate: (task: TaskV1) => void;
  onTaskExecute: (task: TaskV1) => void;
  isWriteUser: boolean;
}

interface TaskStatusDropdownProps {
  status: TaskStatus;
  disabled: boolean;
  onChange: (status: TaskStatus) => Promise<void>;
}

export const taskTypes: Record<TaskType, string> = {
  measurement: 'Measurement',
  sample: 'Sample',
  observation: 'Observation',
  dosing: 'Dosing',
  other: 'Other',
};

const TaskStatusDropdown: FC<TaskStatusDropdownProps> = ({ status, disabled, onChange }) => {
  const [saving, setSaving] = useState(false);

  const changeStatus = (option: { value: TaskStatus; label: string }) => {
    if (_notNil(option) && option.value !== status && !disabled) {
      setSaving(true);
      onChange(option.value).finally(() => setSaving(false));
    }
  };

  return (
    <Dropdown
      renderMenu={() => (
        <>
          {Object.values(statusOptions).map((option) => (
            <DropdownMenuItem key={option.value} onClick={() => changeStatus(option)}>
              {option.label}
            </DropdownMenuItem>
          ))}
        </>
      )}
      disabled={disabled || saving}
    >
      <Button paleBlue className={cn('ph3 br-pill', { ui__disabled: disabled || saving })}>
        <div className="flex items-center">
          {statusOptions[status].label} {!disabled ? <RiArrowDownSFill className="ml1" /> : null}
        </div>
      </Button>
    </Dropdown>
  );
};

export const TaskOverdue: FC = () => (
  <div className="ph2 pv1 mr2 bg-dark-red-05 br2 mr2">
    <p className="dark-red ttu tracked f6">Overdue</p>
  </div>
);

const TaskTime: FC<{ taskDuration: TaskDuration; timezone: string }> = ({ taskDuration, timezone }) => {
  const userTimezone = useSelector((state: State) => state.user.currentUser.timezone);
  const { isAllDayTask, textDuration, displayTimezone, timezoneData } = useMemo(() => {
    const displayTimezone = timezone !== userTimezone;
    const startDate = new TZDate(taskDuration.start, timezone);
    const endDate = DateUtils.roundDate(new TZDate(taskDuration.end, timezone));
    const timezoneData = {
      id: timezone,
      name: DateUtils.timezoneName(taskDuration.start, timezone, 'long') ?? '',
      abbreviation: DateUtils.timezoneAbbreviation(taskDuration.start, timezone),
    };
    const duration = intervalToDuration({ start: startDate, end: endDate });
    const isAllDayTask = Object.keys(duration).length === 1 && duration.days === 1;
    const textDuration = formatDuration(duration, { zero: false });
    return {
      displayTimezone,
      isAllDayTask,
      textDuration,
      timezoneData,
    };
  }, [userTimezone, taskDuration, timezone]);
  if ((isAllDayTask && displayTimezone) || !isAllDayTask) {
    return (
      <span
        className="bg-near-white b--moon-gray pa1 f7 ba br2 near-black"
        data-tooltip-id="global-tooltip-id"
        data-tooltip-content={
          displayTimezone
            ? `${_isEmptyString(timezoneData.name) ? timezoneData.id : timezoneData.name} • ${timezoneData.abbreviation}`
            : undefined
        }
      >
        <RiTimeLine className="v-mid" />
        <span className="ml2 v-mid">
          <DateTimeRenderer value={taskDuration.start} format={DateTimeRenderFormat.Time} />
        </span>
        {isAllDayTask && !displayTimezone ? <></> : <span className="ml1 v-mid">for {textDuration}</span>}
      </span>
    );
  }
  return null;
};

const TaskTimeSummary: FC<{ taskDuration: TaskDuration; timezone: string }> = ({ taskDuration, timezone }) => {
  return (
    <div className="flex items-center mt2">
      <TaskTime taskDuration={taskDuration} timezone={timezone} />
    </div>
  );
};

const typedTaskFeatureSelector = createSelector([featuresSelector], (features) => features?.typed_tasks ?? false);

export const Task: FC<TaskProps> = ({ task, study, typeData, onTaskUpdate, onTaskExecute, isWriteUser }) => {
  const { openModal, closeModal } = modalAction(useDispatch());
  const { state, dispatch } = useScheduleContext();
  const typedTaskEnabled = useSelector(typedTaskFeatureSelector);

  const { invoke: updateStatus } = useApiHook({
    endpoint: 'PATCH /api/v1/studies/{studyId}/tasks/{taskId}/transition',
    path: { studyId: task.study.api_id, taskId: task.id },
    invokeOnInit: false,
  });

  const handleViewMoreClick = (startingTab?: TaskDetailsTabType) => {
    const props: TaskDetailsProps = {
      taskId: task.id,
      onTaskUpdate,
      onTaskExecute,
      startingTab,
      closeModal,
    };
    openModal('TASK_DETAILS', props);
  };
  const studyInActive = !(_isNil(study?.archived_at) && _isNil(study?.canceled_at));
  const handleTransition = async (status: TaskStatus) => {
    const result = await updateStatus({
      path: { studyId: task.study.api_id, taskId: task.id },
      body: { status },
    });
    if (result?.type === 'success') {
      onTaskUpdate(result.body);
    }
  };

  const taskTransitionDisabled = !checkCanTransitionTask(
    isWriteUser,
    task.assignees ?? [],
    task.study?.active ?? false
  );
  const taskDate = useMemo(() => {
    if (_notNil(task?.duration?.start) && DateUtils.isoDateTimePattern.test(task?.duration?.start)) {
      const zonedDate = toZonedTime(task?.duration?.start, task.timezone);
      if (isValid(zonedDate)) {
        return format(zonedDate, DateRenderFormat.ISODate);
      }
    }
    return null;
  }, [task]);

  const executionState = useMemo<{ disabled: false } | { disabled: true; reason: string }>(() => {
    if (state.selectedTasks.has(task.id)) {
      return { disabled: false };
    }
    if (_notNil(state.selectedDate) && state.selectedDate !== taskDate) {
      return { disabled: true, reason: `Tasks must be on the same day to execute` };
    }
    if (task.type === 'other') {
      return { disabled: true, reason: `Tasks with type "other" cannot be executed` };
    }
    if (task.status === 'done') {
      return { disabled: true, reason: `Tasks with status "done" cannot be executed` };
    }
    if (state.selectedTaskTypes.has(task.type)) {
      return { disabled: true, reason: `Task with type "${task.type}" already selected for execution` };
    }
    return { disabled: false };
  }, [task, taskDate, state]);

  return (
    <div
      className={cn('ui-card', styles.taskCard, {
        [styles.selectedForExecution]: state.selectedTasks.has(task.id),
        [styles.executionDisabled]: executionState.disabled || !isWriteUser,
      })}
      style={{ width: '650px' }}
      data-test-component="Task"
      data-test-element="container"
      data-test-key={task.id}
    >
      <div className="flex pa3 justify-between-l bb b--moon-gray items-start">
        <div className="flex flex-column">
          <div
            className={cn('flex flex-row items-center', {
              [styles.taskExecutionEnabled]: !executionState.disabled && isWriteUser && typedTaskEnabled,
            })}
          >
            <span
              className={cn('mr3', styles.executionContainer)}
              data-tooltip-id="global-tooltip-id"
              data-tooltip-content={
                executionState.disabled && isWriteUser && typedTaskEnabled ? executionState.reason : undefined
              }
              data-tooltip-place="bottom-start"
            >
              <span className={styles.checkboxContainer}>
                <Checkbox
                  disabled={executionState.disabled || !isWriteUser || !typedTaskEnabled}
                  checked={state.selectedTasks.has(task.id)}
                  onChange={() =>
                    dispatch({ type: 'toggle-task', data: { id: task.id, type: task.type, date: taskDate as ISODate } })
                  }
                />
              </span>
              <span className={styles.iconContainer}>
                <TaskTypeIcon type={task.type} />
              </span>
            </span>
            {task.overdue && <TaskOverdue />}
            <p className="fw5 f4 near-black">{task.title}</p>
          </div>
          <TaskTimeSummary taskDuration={task.duration} timezone={task.timezone} />
        </div>
        <TaskStatusDropdown
          status={task.status}
          disabled={studyInActive || taskTransitionDisabled}
          onChange={handleTransition}
        />
      </div>
      <div className="pa3">
        <div className="flex items-center justify-between-l mb3">
          <p className="fw5 near-black">{taskTypes[task.type]}</p>
          <TaskAssigneeAvatars assignees={task.assignees} />
        </div>
        <TaskTypeDetail task={task} typeData={typeData} onClick={() => handleViewMoreClick('execution')} />
        {!_isEmptyString(task.description) && (
          <div className="mb3 bg-near-white pa2" style={{ borderRadius: '0 8px 8px 8px' }}>
            <p className="near-black f6">{task.description}</p>
          </div>
        )}
        <div className="mt3 mb2">
          <span className="link blue f6" style={{ textDecoration: 'underline' }} onClick={() => handleViewMoreClick()}>
            View More →
          </span>
        </div>
      </div>
    </div>
  );
};
