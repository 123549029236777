import { ScheduleContextState, useScheduleContext } from '@/components/Studies/Tasks/Schedule.context.ts';
import DatePickerNative from '@/components/UI/DatePickerNative';
import Icon from '@/components/UI/Icon';
import { _isEmptyString, _isNil, _notNil } from '@/littledash.ts';
import { TaskScheduleDataV1 } from '@/model/Task.model.ts';
import { DateRenderFormat, DateUtils } from '@/utils/Date.utils.ts';
import { tz } from '@date-fns/tz';
import { format } from 'date-fns';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form@latest';

interface ScheduleSearchBarProps {
  scheduleData?: TaskScheduleDataV1;
}

export const ScheduleSearchBar: FC<ScheduleSearchBarProps> = ({ scheduleData }) => {
  const { state, onSearchChange } = useScheduleContext();
  const defaultData = useMemo(
    () => ({
      text: state.search.text,
      start: _notNil(state?.search?.start)
        ? format(new Date(state.search.start), DateRenderFormat.ISODate, { in: tz(DateUtils.timezone()) })
        : DateUtils.dateNow(),
    }),
    [state.search]
  );
  const { minDate, maxDate } = useMemo(
    () =>
      _isNil(scheduleData?.duration?.start) || _isNil(scheduleData?.duration?.end)
        ? { minDate: undefined, maxDate: undefined }
        : {
            minDate: DateUtils.renderDateTime(scheduleData?.duration?.start, {
              defaultResponse: '',
              format: DateRenderFormat.ISODate,
            }),
            maxDate: DateUtils.renderDateTime(scheduleData?.duration?.end, {
              defaultResponse: '',
              format: DateRenderFormat.ISODate,
            }),
          },
    [scheduleData]
  );
  const formMethods = useForm<ScheduleContextState['search']>({
    defaultValues: defaultData,
  });

  useEffect(() => {
    formMethods.reset({ ...defaultData });
  }, [formMethods, defaultData]);
  const handleSubmit = useCallback(() => {
    formMethods.handleSubmit((data) =>
      onSearchChange({ ...data, start: DateUtils.startOfDay(data.start ?? DateUtils.dateNow()) })
    )();
  }, [formMethods.handleSubmit, onSearchChange]);
  const searchText = useWatch({ control: formMethods.control, name: 'text' });

  return (
    <div
      className="flex flex-row justify-start"
      style={{ width: '650px' }}
      data-test-component="ScheduleSearchBar"
      data-test-element="container"
    >
      <div className="relative w-100 pr2">
        <span className="absolute z-5" style={{ top: '13px', left: '10px' }}>
          <Icon icon="search" width="24" height="24" />
        </span>
        <Controller
          control={formMethods.control}
          name="text"
          render={({ field }) => (
            <input
              type="text"
              data-test-element="search-text-input"
              className="ui__keyword-search-bar"
              style={{ marginBottom: 0, maxWidth: 'none' }}
              placeholder="Search by task title"
              autoComplete="off"
              value={field.value}
              onChange={(event) =>
                field.onChange(_isEmptyString(event.target.value ?? '') ? undefined : event.target.value.trim())
              }
              onBlur={field.onBlur}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleSubmit();
                }
              }}
            />
          )}
        />

        {(searchText ?? '').trim().length > 0 ? (
          <span
            className="absolute z-5 pointer"
            data-test-element="clear-search-text"
            style={{ top: '10px', right: '18px' }}
            onClick={() => {
              formMethods.reset({ ...defaultData });
              handleSubmit();
            }}
          >
            <Icon icon="close" width="12" height="12" />
          </span>
        ) : null}
      </div>
      <Controller
        control={formMethods.control}
        name="start"
        render={({ field }) => (
          <DatePickerNative
            style={{ marginBottom: 0 }}
            value={field.value ?? DateUtils.dateNow()}
            min={minDate}
            testId="seatch-date"
            max={maxDate}
            onChange={(value) => {
              if (_isEmptyString(value ?? '')) {
                formMethods.reset({ ...defaultData, text: searchText });
              } else {
                field.onChange(value);
              }
              handleSubmit();
            }}
            onBlur={field.onBlur}
          />
        )}
      />
    </div>
  );
};
